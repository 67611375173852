import React, { DetailedHTMLProps, TdHTMLAttributes } from 'react';
import { SortObjParams } from 'pages/ib/models/tableSortStore';
import { Icon2 } from 'ui/atoms';

import * as S from './styled';


interface Props extends DetailedHTMLProps<TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>{
  children?: React.ReactNode | undefined;
  sortable?: boolean;
  sortDirection?: 'asc' | 'desc',
  name?: string;
  isSortActive?: boolean;
  isEmptySort?: boolean;
  cellId?: string;
  onChangeSort?: (sortObj: SortObjParams) => void;
}

export const TdHead: React.FC<Props> = ({ 
  children, className = '', sortable, onChangeSort, isEmptySort,
  sortDirection = 'desc', name, isSortActive, cellId,  ...rest 
}) => {
  const onChangeSortFn = () => {
    if (!name || !sortable) return '';
    
    onChangeSort?.({
      name: name || '',
      direction: sortDirection === 'desc' ? 'asc' : 'desc',
    });
  }

  return (
    <td
      className={`px-[20px] py-[6px] ${className} ${sortable ? 'cursor-pointer' : ''}`}
      align='left'
      id={cellId}
      {...rest}
    >
      <div className='flex items-center group' onClick={onChangeSortFn}>
        {children}
        {sortable &&
          <S.IconSort
            name={sortDirection === 'desc' ? 'arrowDownOutline' : 'arrowUpOutline'}
            size='small'
            isActive={isEmptySort || isSortActive}
            className={`group-hover:visible`}
          />
        }
      </div>

    </td>
  )
}
