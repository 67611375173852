import type { ContestStatus, ParticipantStatus } from 'services/contests/types'
import type { Design } from 'ui/atoms'

export const contestStatuses: Record<
  ContestStatus,
  { name: string; tagDesign: Design }
> = {
  PACS_INVALID: { name: 'Unknown', tagDesign: 'opal' },
  PACS_REGISTRATION: { name: 'Registration', tagDesign: 'eucalyptus' },
  PACS_ACTIVE: { name: 'Active', tagDesign: 'azure' },
  PACS_RESULTS: { name: 'Ended', tagDesign: 'gray' },
  PACS_ENDED: { name: 'Calculating results', tagDesign: 'azure' },
}

export const participantStatuses: Record<
  ParticipantStatus,
  { name: string; tagDesign: Design }
> = {
  PAPS_ACTIVE: { name: 'Joined', tagDesign: 'sunray' },
  PAPS_DISQUALIFIED: { name: 'Disqualified', tagDesign: 'opal' },
  PAPS_UNREGISTERED: { name: '', tagDesign: 'opal' },
}
