import { useWindowSize } from '@uidotdev/usehooks'
import { useEffect, useState } from 'react'
import { Text, Title } from 'ui/atoms'

import { Modal } from '../modal_draft'
import * as Styled from './styled'

export const LogoutModal = ({
  open,
  onLogout,
  onReset,
  onClose,
}: {
  open: boolean
  onLogout: () => void
  onReset: () => void
  onClose: () => void
}) => {
  const { width } = useWindowSize()
  const isMobileMode = width && width < 600

  const [timeLeft, setTimeLeft] = useState(30)

  useEffect(() => {
    if (!open) {
      setTimeLeft(30)

      return
    }

    if (timeLeft === 0) {
      onLogout()
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prev) => prev - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [open, timeLeft])

  const handleOk = () => {
    onReset()

    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      onCancel={onLogout}
      onOk={handleOk}
      okLabel="Keep working"
      cancelLabel="Logout"
    >
      <Title level={isMobileMode ? 3 : 2} className="mb-[16px] text-center">
        Session timeout
      </Title>
      <Text className="!text-gray.600 text-center">
        Your current session will expire in
      </Text>
      <Styled.Timer>0:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}</Styled.Timer>
    </Modal>
  )
}
