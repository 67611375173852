import { useStore } from 'effector-react'
import { MouseEvent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Input } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'

import { useRename } from './hooks'
import {
  $nameSubmitted,
  setNameSubmitted,
  setNewName,
  submitRename,
} from './model'

type Props = {
  accountID: string
  children: ReactNode
}

export const AccountRename = ({ accountID, children }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { newName, isLoading } = useRename()

  const formattedName = newName.trim().replace(/\s+/g, ' ')

  const isValid = /^(?=.*[A-Za-z])[A-Za-z0-9 ]{3,}$/.test(formattedName)

  const nameSubmitted = useStore($nameSubmitted)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    setIsModalVisible(true)
  }

  useEffect(() => {
    if (nameSubmitted) {
      setIsModalVisible(false)

      setNameSubmitted(false)
    }
  }, [nameSubmitted])

  return (
    <>
      <div
        className="flex items-center cursor-pointer hover:opacity-75 transition-opacity"
        onClick={handleClick}
      >
        {children}
        <EditIcon
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4874 0.512563C12.804 -0.170854 11.696 -0.170854 11.0126 0.512563L10.2411 1.284L12.716 3.75888L13.4874 2.98744C14.1709 2.30402 14.1709 1.19598 13.4874 0.512563Z"
            fill="#B3B3B3"
          />
          <path
            d="M12.0089 4.46598L9.53403 1.99111L1.43347 10.0917C1.02228 10.5029 0.720021 11.01 0.554008 11.5673L0.0208212 13.3573C-0.0315921 13.5332 0.0166393 13.7237 0.146459 13.8535C0.276279 13.9834 0.466803 14.0316 0.642755 13.9792L2.43268 13.446C2.99 13.28 3.49716 12.9777 3.90836 12.5665L12.0089 4.46598Z"
            fill="#B3B3B3"
          />
        </EditIcon>
      </div>
      <Modal
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onCancel={() => {
          setNewName('')
          setIsModalVisible(false)
        }}
        okButtonProps={{ disabled: isLoading || !isValid, loading: isLoading }}
        title="Change account name"
        okLabel="Confirm"
        onOk={() => submitRename({ accountID })}
      >
        <Input
          label="Account name"
          onChange={(event) => setNewName(event.target.value)}
          value={newName}
          disabled={isLoading}
          errorMessage={
            isValid || formattedName.length < 3
              ? ''
              : 'Use digits, spaces, and at least one Latin character.'
          }
        />
      </Modal>
    </>
  )
}

const EditIcon = styled.svg`
  margin-inline-start: 8px;
`
