import { path } from 'libs/path'
import { yup } from 'libs/validators'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { usePostChangeUserPwd } from 'services/auth/mutations/usePutAddress'
import { Block, Field, Form, Text, Title } from 'ui/atoms'
import { Breadcrumbs } from 'ui/molecules'

import * as S from './styles'

const initial = {
  newPassword: '',
  newPasswordRepeat: '',
}

export const ChangePasswordPage = () => {
  const { mutateAsync, isLoading } = usePostChangeUserPwd()
  const navigate = useNavigate()

  const [isSuccessChanged, setIsSuccessChanged] = useState(false)

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    navigate(path.dashboard.entry())
  }

  const goToProfile = () => {
    navigate(path.dashboard.profile.entry())
  }

  const onSubmitForm = async (fields: { newPassword: string }) => {
    try {
      await mutateAsync(fields.newPassword)
      setIsSuccessChanged(true)
    } catch (error) {}
  }

  return (
    <>
      <Breadcrumbs
        className="mb-[2rem]"
        customPaths={[
          { name: 'Profile', pathName: path.dashboard.profile.entry() },
          {
            name: 'Change password',
            pathName: path.dashboard.profile.changePassword(),
          },
        ]}
      />

      <S.Container>
        <Form
          onSubmit={onSubmitForm}
          initial={initial}
          validation={yup.password.create}
          className="!pt-0 !mt-0"
        >
          <Block className="flex flex-col h-[486px] justify-between">
            {!isSuccessChanged && (
              <S.ChangePasswordFormContainer>
                <Title level={2}>Change password</Title>
                <Text className="mt-[0.8rem]">
                  Create a new password for your Headway account. Use it to log
                  into the Personal Area.
                </Text>

                <div className="mt-[2.4rem]">
                  <Field
                    name="newPassword"
                    placeholder="New password"
                    label="New password"
                    type="password"
                  />
                  <Field
                    name="newPasswordRepeat"
                    placeholder="Repeat password"
                    label="Repeat password"
                    type="password"
                    className="mt-[2rem]"
                  />
                </div>
              </S.ChangePasswordFormContainer>
            )}
            {isSuccessChanged && (
              <div className="w-full">
                <Title level={2}>Change password</Title>
                <Text className="mt-[0.8rem]">
                  Create a new password for your Headway account. Use it to log
                  into the Personal Area.
                </Text>
                <div className="flex mt-[2.4rem] flex-col items-center justify-center w-full">
                  <S.Image
                    url={`${process.env.PUBLIC_URL}/img/successImage.png`}
                  />
                  <Text className="mt-[1rem]">
                    Great! You have successfully changed your password.
                  </Text>
                </div>
              </div>
            )}

            <S.ChangePasswordButtonContainer className="flex justify-end w-full gap-[1.6rem]">
              <S.ChangePasswordButton
                name="cancel"
                size="large"
                className="w-[172px]"
                design="secondary"
                onClick={onCancel}
                loading={isLoading}
                disabled={isLoading}
              >
                Go to Dashboard
              </S.ChangePasswordButton>
              <S.ChangePasswordButton
                name="submit"
                size="large"
                type={isSuccessChanged ? undefined : 'submit'}
                onClick={isSuccessChanged ? goToProfile : undefined}
                className="w-[172px]"
                design="primary"
                loading={isLoading}
                disabled={isLoading}
              >
                {isSuccessChanged ? 'Go to Profile' : 'Confirm'}
              </S.ChangePasswordButton>
            </S.ChangePasswordButtonContainer>
          </Block>
        </Form>
      </S.Container>
    </>
  )
}
