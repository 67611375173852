import { createEvent, createStore, restore, sample } from 'effector'
import { toPath } from 'features/router'
import { $userProfile, User } from 'features/user'
import { $isUserEmailConfirmed, setUserInitial } from 'features/user/model'
import { ClientError, createCountdown, mapResponseError } from 'libs'
import { path } from 'libs/path'
import { fx } from 'libs/request'

import { registerByEmail } from '../email/model'
import {
  emailConfirmationRequest,
  resendEmailConfirmationRequest,
} from '../emailConfirmRepeat'
import { CheckEmailConfirmation } from './types'

export const mounted = createEvent()
export const submitForm = createEvent<Pick<CheckEmailConfirmation, 'code'>>()
export const submitRepeat = createEvent()

export const $resendCounter = createStore(0)

export const checkEmailConfirmation = fx<
  Pick<CheckEmailConfirmation, 'code'>,
  User
>({
  method: 'PUT',
  url: '/user/v1/check-email-confirmation',
})

sample({
  source: $userProfile,
  clock: submitForm,
  fn: ({ email }, { code }) => {
    return {
      body: { code, email },
    }
  },
  target: checkEmailConfirmation,
})

// redirect to confirm if success

sample({
  clock: checkEmailConfirmation.done,
  fn: () => {
    localStorage.removeItem('resendDate')
  },
  target: toPath.prepend(() => path.register.email.confirmSuccess()),
})

sample({
  clock: checkEmailConfirmation.done,
  fn: () => true,
  target: $isUserEmailConfirmed,
})

// resend confirm timer

export const startResendTimer = createEvent<number>()
const setTimerInitial = createEvent<number>()
export const $timerInitial = restore(setTimerInitial, 60)

const emailConfirmationTimer = createCountdown({
  name: 'emailResendConfirmationTimer',
  start: startResendTimer,
})

export const $resendTimer = restore<number>(emailConfirmationTimer.tick, 0)

// submit resend

sample({
  source: $userProfile,
  clock: submitRepeat,
  fn: (userProfile) => ({
    body: userProfile,
  }),
  target: resendEmailConfirmationRequest,
})

sample({
  source: $resendCounter,
  clock: submitRepeat,
  fn: (resendCounter) => ++resendCounter,
  target: $resendCounter,
})

sample({
  clock: setUserInitial,
  filter: ({ isEmailConfirmed }) =>
    !isEmailConfirmed &&
    Date.now() / 1000 - 1800 >= Number(localStorage.getItem('resendDate')),
  fn: ({ email }) => {
    return {
      body: {
        email,
      },
    }
  },
  target: resendEmailConfirmationRequest,
})

sample({
  clock: setUserInitial,
  filter: () => Number(localStorage.getItem('resendDate')) > Date.now() / 1000,
  target: startResendTimer.prepend(
    () =>
      Number(localStorage.getItem('resendDate')) -
      Math.floor(Date.now() / 1000),
  ),
})

// start resend timer

sample({
  clock: [registerByEmail.done, emailConfirmationRequest.done],
  fn: () => {
    const initialResendTime = 60

    localStorage.setItem(
      'resendDate',
      `${Math.floor(Date.now() / 1000 + initialResendTime)}`,
    )

    return initialResendTime
  },
  target: setTimerInitial,
})

sample({
  clock: resendEmailConfirmationRequest.doneData,
  fn: (doneData) => {
    localStorage.setItem('resendDate', doneData.body.resendAfter)

    return +doneData.body.resendAfter - Math.floor(Date.now() / 1000)
  },
  target: setTimerInitial,
})

sample({
  clock: resendEmailConfirmationRequest.failData,
  filter: (failData) => Boolean(failData.body.details?.params.resendAfter),
  fn: (failData) => {
    if (failData.body.details) {
      localStorage.setItem(
        'resendDate',
        failData.body.details.params.resendAfter,
      )

      return (
        +failData.body.details?.params.resendAfter -
        Math.floor(Date.now() / 1000)
      )
    }

    return 0
  },
  target: setTimerInitial,
})

sample({
  clock: setTimerInitial,
  target: startResendTimer.prepend(() => $timerInitial.getState()),
})

// handle errors

export const $error = createStore<ClientError<
  Pick<CheckEmailConfirmation, 'code'>
> | null>(null)

$error
  .on(checkEmailConfirmation.failData, (_, failData) =>
    mapResponseError<Pick<CheckEmailConfirmation, 'code'>>(failData.body),
  )
  .reset([mounted, submitForm])
