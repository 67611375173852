import { useWindowSize } from '@uidotdev/usehooks'
import { formatDuration, intervalToDuration } from 'date-fns'
import { useStore } from 'effector-react'
import { path } from 'libs/path'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
// import VerificationInput from 'react-verification-input'
import styled from 'styled-components/macro'
import { Button, Icon2, Input } from 'ui/atoms'

import { AuthLayout, AuthSectionTitle } from '../../layout'
import { useEmailConfirmPage } from './hooks'
import {
  $resendCounter,
  $resendTimer,
  $timerInitial,
  submitForm,
  submitRepeat,
} from './model'
import * as Styled from './styled'
// import { ErrorBoundary } from 'features/errorBoundary'

export const RegisterEmailConfirm = () => {
  const [t] = useTranslation();

  const [otpCode, setOtpCode] = useState('');

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const resendCounter = useStore($resendCounter)

  const [countOfRequestToEmail, setCountOfRequestToEmail] = useState(0)

  const { userProfile, timer, loading, error, resendCodeLoading } =
    useEmailConfirmPage()

  if (
    searchParams.get('code') &&
    userProfile.email &&
    countOfRequestToEmail < 1
  ) {
    submitForm({
      code: searchParams.get('code') || '',
    })
    setCountOfRequestToEmail((prev) => prev + 1)

    return null
  }

  const onChangeCodeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (!val) {
      setOtpCode('');
      return;
    };

    const codeNum = val?.replace(/[^\d]/g, '')
    setOtpCode(codeNum);

    if (codeNum?.length === 6) {
      submitForm({ code: codeNum })
    }
  };

  return (
    <AuthLayout type="register">
      <AuthSectionTitle level={3}>Verify your email</AuthSectionTitle>
      <div className="mt-[8px] text-body.regular leading-body.regular mobile:mt-[4px] mobile:text-body.medium mobile:leading-body.medium">
        <Trans
          i18nKey="The verification code is sent to"
          values={{ email: userProfile.email }}
          components={[<span />, <span className="font-medium" />]}
        />
      </div>
      <div className="mt-[8px] text-body.regular leading-body.regular mobile:mt-[4px] mobile:text-body.medium mobile:leading-body.medium">
        {t(
          'Enter the 6-digit code below or click the link in the email. The code is valid for 30 minutes.',
        )}
      </div>

      <Styled.InputWrapper hasError={!!error?.fields?.code}>
        {/* <ErrorBoundary fallback="Error">
          <VerificationInput
            autoFocus
            placeholder="0"
            validChars="0-9"
            onComplete={(code) => submitForm({ code })}
            inputProps={{ inputMode: 'numeric' }}
          />
        </ErrorBoundary> */}
        <Input
          value={otpCode}
          inputMode='numeric'
          placeholder='Code'
          inputClasses='!h-[40px]'
          maxLength={6}
          state={!!error?.fields?.code ? 'error' : undefined}
          onChange={onChangeCodeInput}
        />
      </Styled.InputWrapper>

      {!!error?.fields?.code && (
        <div className="mt-[10px] text-body.medium leading-body.medium text-opal.600">
          {error.fields.code}
        </div>
      )}

      <ResendTimer isLoading={loading || resendCodeLoading} />

      {timer === 0 && resendCounter > 0 && !resendCodeLoading && (
        <ResendCodeButton
          name="navigate-register-verificate-repeat"
          size="small"
          design="tertiary-brand"
          onClick={() => {
            navigate(path.register.email.confirmRepeat())
          }}
        >
          Didn’t receive the Code?
        </ResendCodeButton>
      )}
    </AuthLayout>
  )
}

const ResendTimer = ({ isLoading }: { isLoading: boolean }) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobile = width && width < 600

  const secondsLeft = useStore($resendTimer)
  const timerInitial = useStore($timerInitial)
  const isRunning = Boolean(secondsLeft) && secondsLeft <= timerInitial

  const formattedDuration = formatDuration(
    intervalToDuration({ start: 0, end: secondsLeft * 1000 }),
    {
      format: ['minutes', 'seconds'],
      zero: true,
      delimiter: ':',
      locale: {
        formatDistance: (_token, count) => String(count).padStart(2, '0'),
      },
    },
  )

  return (
    <Styled.ResendButton
      design={isRunning || isLoading ? 'tertiary' : 'secondary'}
      disabled={isRunning || isLoading}
      size={isMobile ? 'medium' : 'large'}
      prefix={isRunning ? <Icon2 name="clock" /> : null}
      loading={isLoading}
      onClick={
        isRunning
          ? undefined
          : () => {
              submitRepeat()
            }
      }
    >
      {isRunning ? (
        <Trans
          t={t}
          i18nKey="Resend code in"
          values={{ time: formattedDuration }}
          defaults="Resend code in {{ time }}"
        />
      ) : (
        'Resend code'
      )}
    </Styled.ResendButton>
  )
}

const ResendCodeButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`
