import { useStore } from 'effector-react'
import * as React from 'react'
import styled from 'styled-components/macro'
import { Checkbox, Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'

import {
  $swapErrorMessage,
  $swapHasBeenChanged,
  mounted,
  patchSwapFree,
  submit,
} from './model'

type Props = {
  accountID: string
  isSwapFree: boolean
}

export const SwapFree = ({ accountID, isSwapFree }: Props) => {
  const isLoading = useStore(patchSwapFree.pending)
  const swapHasBeenChanged = useStore($swapHasBeenChanged)
  const swapErrorMessage = useStore($swapErrorMessage)

  const [errorVisible, setErrorVisible] = React.useState(false)
  const [warningVisible, setWarningVisible] = React.useState(false)

  React.useEffect(() => {
    mounted()
  }, [])

  React.useEffect(() => {
    if (swapErrorMessage) {
      setWarningVisible(false)
    }

    setErrorVisible(!!swapErrorMessage)
  }, [swapErrorMessage])

  React.useEffect(() => {
    if (swapHasBeenChanged) {
      setWarningVisible(false)
    }
  }, [swapHasBeenChanged])

  return (
    <>
      <Container>
        <Checkbox
          name="swap-free"
          design={'brand'}
          checked={isSwapFree}
          onChange={() => setWarningVisible(true)}
        />
      </Container>
      <Modal
        open={errorVisible}
        onClose={() => setErrorVisible(false)}
        title="Swap-Free change"
        okLabel="Get it"
      >
        <Text className="text-start" withTranslate={false}>
          {swapErrorMessage}
        </Text>
      </Modal>
      <Modal
        open={warningVisible}
        onClose={() => setWarningVisible(false)}
        onOk={() => submit({ isSwapFree: !isSwapFree, accountID })}
        title="Swap-Free change"
        okLabel="Change"
        okButtonProps={{ disabled: isLoading, loading: isLoading }}
      >
        <Text className="text-start">
          Once you edit Swap-Free settings, you can change them again only after
          24 hours.
        </Text>
      </Modal>
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`
