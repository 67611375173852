import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import * as React from 'react'
import { service } from 'services'

import { $error, $resendTimer, mounted } from './model'

export const usePasswordRecoveryConfirmPage = () => {
  React.useEffect(() => {
    mounted()
  }, [])

  const loading = useStore(service.auth.getCheckResetPasswordCode.pending)
  const userProfile = useStore($userProfile)
  const error = useStore($error)
  const timer = useStore($resendTimer)

  return {
    loading,
    error,
    userProfile,
    timer,
  }
}
