import smallMedal_1 from './medal-01-small.webp'
import medal_1 from './medal-01.webp'
import smallMedal_2 from './medal-02-small.webp'
import medal_2 from './medal-02.webp'
import smallMedal_3 from './medal-03-small.webp'
import medal_3 from './medal-03.webp'
import smallMedal_4 from './medal-04-small.webp'
import medal_4 from './medal-04.webp'
import smallMedal_5 from './medal-05-small.webp'
import medal_5 from './medal-05.webp'
import smallMedal_6 from './medal-06-small.webp'
import medal_6 from './medal-06.webp'
import smallMedal_7 from './medal-07-small.webp'
import medal_7 from './medal-07.webp'
import smallMedal_8 from './medal-08-small.webp'
import medal_8 from './medal-08.webp'
import smallMedal_9 from './medal-09-small.webp'
import medal_9 from './medal-09.webp'
import smallMedal_10 from './medal-10-small.webp'
import medal_10 from './medal-10.webp'

export const icons: Record<string, string> = {
  medal_1,
  medal_2,
  medal_3,
  medal_4,
  medal_5,
  medal_6,
  medal_7,
  medal_8,
  medal_9,
  medal_10,
  smallMedal_1,
  smallMedal_2,
  smallMedal_3,
  smallMedal_4,
  smallMedal_5,
  smallMedal_6,
  smallMedal_7,
  smallMedal_8,
  smallMedal_9,
  smallMedal_10,
}
