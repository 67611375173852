import React, { useMemo, useState } from 'react'

import { fromDateToIso, getDateFromUnix } from 'libs/dateUtils'
import { SortObjParams } from 'pages/ib/models/tableSortStore'
import { levelLabelsObj, levelOptions } from 'pages/ib/utils/levels'

import { PartnerLevelType } from 'services/ib/get/types'
import { useGetPartners } from 'services/ib/get/useGetPartners'
import { Icon2, Select, SpinnerWithBlur, Text } from 'ui/atoms'
import { Pagination } from 'ui/atoms/pagination/Pagination'
import { THead, TableWrapper, Td, TdHead } from 'ui/molecules'
import { DateRange } from 'ui/molecules/datePickers'

import * as S from './styled'

const subHead = [
  { title: 'IB ID' },
  { title: 'Joining date', name: 'FIELD_BINDING_DATE', sortable: true },
  { title: 'Level', name: 'FIELD_PARTNER_LEVEL', sortable: true },
  { title: 'Comission', name: 'FIELD_SUM_COMMISSION', sortable: true },
]

export const PartnersTab: React.FC = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [searchValue, setSearchValue] = useState('')
  const [level, setLevel] = useState('')
  const [dateFilter, setDateFilter] = useState<{ from: string; to: string }>()

  const [sort, setSort] = useState<SortObjParams | null>(null)

  const filter = useMemo(
    () => ({
      partnerID: searchValue,
      commissionPeriod: dateFilter,
      partnerLevel: level,
    }),
    [dateFilter, searchValue, level],
  )

  // Data fetch
  const { data, isLoading, isRefetching } = useGetPartners({
    page,
    perPage,
    sort,
    filter,
  })

  const onChangePage = (a: number) => {
    setPage(a)
  }

  const onChangeInput = (val: string) => {
    setSearchValue(val)
  }

  const onChangeLevel = (val: PartnerLevelType) => {
    if (val === 'PAPL_INVALID') {
      setLevel('')
      return
    }

    setLevel(val)
  }

  return (
    <div>
      <div className="flex gap-[10px] flex-col lg:flex-row">
        <S.SearchInput
          placeholder="Search"
          debounceCallbackFn={onChangeInput}
          maxLength={20}
          inputClasses='!h-[40px]'
          className='lg:!w-[271px] !h-[40px]'
          suffix={<Icon2 name={'magnifyingGlassIcon'} />}
          withDebaunce
        />

        <div className="lg:ms-auto flex gap-[10px] lg:flex-row-reverse">
          <DateRange
            setTimeline={setDateFilter}
            customMapperDates={fromDateToIso}
            className="w-[300px] lg:w-auto lg:min-w-[280px] !h-[40px]"
            inputClasses='!h-[40px] lg:!w-[280px]'
            optionsListClasses="lg:!left-auto w-[250px] lg:w-auto"
          />
          <Select
            options={levelOptions}
            placeholder="Level"
            value={level}
            className='!h-[40px]'
            onSelect={onChangeLevel as (val: string) => void}
            inputClasses="!w-[120px] !h-[40px]"
          />
        </div>
      </div>
      <TableWrapper className="mt-[2.4rem] w-full relative">
        <SpinnerWithBlur size="xl" isShow={isLoading || isRefetching} />
        <table className="w-full" border={1}>
          <THead
            trClasses='border-b-[0.75px] border-gray.300 border-solid bg-gray.300'
            heads={[
              { label: data?.totalsForPartners.numPartners },
              { label: '' },
              { label: '' },
              {
                label: data?.totalsForPartners.sumCommission
                  ? `$${data?.totalsForPartners.sumCommission}`
                  : '',
              },
            ]}
          />
          <tbody>
            <tr className="border-b-[0.75px] border-gray.300 border-solid">
              {subHead.map((sub, index) => (
                <TdHead
                  key={index}
                  sortable={sub.sortable}
                  isEmptySort={!sort}
                  name={sub.name}
                  sortDirection={
                    sort?.name === sub.name ? sort?.direction : 'asc'
                  }
                  isSortActive={sort?.name === sub.name}
                  onChangeSort={setSort}
                >
                  <Text className={'!font-medium'} level={2}>
                    {sub.title}
                  </Text>
                </TdHead>
              ))}
            </tr>
            {data?.items?.map((item, index) => (
              <tr
                key={item.partnerID}
                className={`${index === (data?.items.length -1) ? '' : 'border-b-[0.75px]'}  border-gray.300 border-solid`}
              >
                <Td>
                  <Text level={2}>{item.partnerID}</Text>
                </Td>
                <Td>
                  <Text level={2}>
                    {getDateFromUnix(+item.bindingDate, 'dd.MM.yyyy')}
                  </Text>
                </Td>
                <Td>
                  <Text level={2}>{levelLabelsObj[item.partnerLevel]}</Text>
                </Td>
                <Td>
                  <Text level={2}>
                    {item.sumCommission && `$${item.sumCommission}`}
                  </Text>
                </Td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
      {data && Number(data?.totalPages) > 1 && (
        <Pagination
          pageSize={perPage}
          current={page}
          total={Number(data?.totalRecords)}
          onChange={onChangePage}
          onChangePerPage={setPerPage}
          classNames="mt-[2.4rem]"
          showSizeChanger
        />
      )}
    </div>
  )
}
