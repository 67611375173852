import { useStore } from 'effector-react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Icon2, Text } from 'ui/atoms'
import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon'
import { Modal } from 'ui/molecules/modal_draft'

import {
  $passwordHasBeenChanged,
  putAccountPassword,
  submitPasswordChange,
} from './model'

type Props = {
  accountID: string
  password: string
}

export const GeneratePassword = ({ password, accountID }: Props) => {
  const [t] = useTranslation()

  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const isLoading = useStore(putAccountPassword.pending)
  const passwordHasBeenChanged = useStore($passwordHasBeenChanged)

  const hasPassword = Boolean(!!password)

  React.useEffect(() => {
    if (passwordHasBeenChanged) {
      setIsModalVisible(false)
    }
  }, [passwordHasBeenChanged])

  return (
    <>
      <Container
        onClick={(e) => {
          e.stopPropagation()

          if (!hasPassword) {
            setIsModalVisible(true)
          }
        }}
      >
        {hasPassword ? (
          <>
            {password}
            <DetailsCopy iconSize="small" copiedText={`${password}`} />
          </>
        ) : (
          <>
            <GenerateText>{t('Generate password')}</GenerateText>
            <GenerateIcon name="arrowPath" size="small" />
          </>
        )}
      </Container>
      <Modal
        open={isModalVisible}
        title="Password change"
        onClose={() => setIsModalVisible(false)}
        onOk={() => submitPasswordChange(accountID)}
        okButtonProps={{ disabled: isLoading, loading: isLoading }}
        okLabel="Generate"
      >
        <Text className="!text-gray.600 text-left">
          Once you generate a new password, the old one becomes invalid. The new
          password will be displayed only for 15 minutes.
        </Text>
      </Modal>
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`

const DetailsCopy = styled(CopyIcon)`
  cursor: pointer;
  margin-inline-start: 8px;
`

const GenerateIcon = styled(Icon2)`
  cursor: pointer;
  color: var(--color-bronze-500);
`

const GenerateText = styled.div`
  cursor: pointer;
  padding-inline-end: 8px;
  color: var(--color-bronze-500);

  @media (max-width: 380px) {
    text-align: end;
  }
`
