import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { path } from 'libs/path'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Icon2 } from 'ui/atoms'
import { MenuItemProps } from 'ui/molecules';
import { MobileMenu } from 'ui/molecules/mobileMenu'
import {
  Container,
  Content,
  DepositButton,
  DepositButtonIcon,
  HeaderLanguageSwitcher,
  HeaderLogo,
  HeaderUser,
  MenuSwitch,
} from 'ui/organisms/header/styled'
import { useWindowSize } from 'usehooks-ts'

export const GenericHeader = ({
  isLoggedIn = true, menuItems,
}: {
  isLoggedIn?: boolean
  menuItems: MenuItemProps[];
}) => {
  const { width } = useWindowSize()
  const isMobileMode = width < 1050

  const navigate = useNavigate()

  // mobile menu
  const [isMenuActive, setMenuActive] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const { pathname } = useLocation()

  useEffect(() => {
    if (isMobileMode) {
      setMenuActive(false)
    }
  }, [pathname])

  useEffect(() => {
    if (isMobileMode && menuRef.current) {
      const targetElement = menuRef.current

      if (isMenuActive) {
        disableBodyScroll(targetElement)
      } else {
        enableBodyScroll(targetElement)
      }

      return () => {
        enableBodyScroll(targetElement)
      }
    }
  }, [isMenuActive])

  return (
    <>
      <Container isFixed={isMenuActive}>
        <Content>
          {isMobileMode && isLoggedIn && (
            <MenuSwitch
              isActive={isMenuActive}
              onClick={() => setMenuActive(!isMenuActive)}
              on="xMark"
              off="barsBottomLeft"
            />
          )}
          <HeaderLogo size="small" />
          {!isMobileMode && (
            <>
              {isLoggedIn && (
                <DepositButton
                  prefix={
                    <DepositButtonIcon name="arrowDownOnSquare" size="small" />
                  }
                  name="header-deposit"
                  design="primary"
                  size="small"
                  onClick={() => navigate(path.money.entry())}
                >
                  Deposit
                </DepositButton>
              )}
              <HeaderLanguageSwitcher
                className={!isLoggedIn ? '!ms-auto' : ''}
              />
            </>
          )}
          {/* <HeaderNotificationsSwitch
            isActive={isNotificationActive}
            on="bellOutline"
            off="bellSolid"
            onClick={() => {
              setNotification(!isNotificationActive)
            }}
          /> */}
          <div style={{ minWidth: 36, height: 1 }}></div>
          {!isMobileMode && isLoggedIn && <HeaderUser />}
          {!isLoggedIn && (
            <>
              {isMobileMode && (
                <HeaderLanguageSwitcher
                  className={!isLoggedIn ? '!ms-auto' : ''}
                />
              )}
              <Button
                to={path.register.entry()}
                name="signup"
                size="small"
                prefix={<Icon2 name="arrowLeftOnRectangle" size="small" />}
                className="ml-[16px]"
              >
                Sign up
              </Button>
            </>
          )}
        </Content>
      </Container>
      {isMenuActive && isMobileMode && (
        <MobileMenu
          onClose={() => setMenuActive(false)}
          menuItems={ menuItems }
          ref={menuRef}
        />
      )}
    </>
  )
}
