import React, { 
  forwardRef, ForwardRefRenderFunction, 
  useMemo, useState, useRef,
  useLayoutEffect,
  useEffect,
} from 'react';
import { useIsFirstRender } from 'usehooks-ts';

import { getStrFromAmount } from 'libs/parsers/getStrFromAmount';
import { Input, InputProps, InputState } from 'ui/atoms/input';
import { getSliceFloatNumber } from 'libs/parsers/getSliceFloatNumber';


type Props = InputProps & {
  prefix?: string;
  errorMessage?: string;
  isTouchedForm?: boolean;
  floatCount?: number;
  onChange?: (val: string) => void;
}


const Component: ForwardRefRenderFunction<HTMLInputElement, Props> = (props, ref) => {
  const {
    state, isTouchedForm, onClick,
    onChange, floatCount = 2, onBlur, ...rest
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const isFirst = useIsFirstRender();
  const [selection, setSelection] = useState<[number | null, number | null] | null>(null);
  const [insideState, setInsideState] = useState<string>(String(rest?.value) || '');
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const inputState = useMemo((): InputState => {
    if (state === 'error') return 'error';
    if (Boolean(props.errorMessage)) return 'error';

    if (isTouchedForm && !props.errorMessage) return 'valid';

    return 'hint'
  }, [isTouchedForm, props.errorMessage, state]);
  
  const getOutputSum = (val: string) => {
    if (!floatCount) {
      return isNaN(+val) ? '0.00' : val;
    }
    const numberValue = +val;

    const final = getSliceFloatNumber(val, floatCount);
    return isNaN(numberValue) ? '0.00' : final;
  }

  const onBlurtFormat = (e: React.FocusEvent<HTMLInputElement>) => {
    let amountValue = e.target.value;

    if (amountValue[amountValue.length - 1] === '.') {
      amountValue = amountValue.slice(0, -1);
      // onChange?.(amountValue);
    }

    const final = getOutputSum(amountValue);
    
    setInsideState(final);
    onBlur?.(e);
    setIsFocused(false);
  }

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const withOutFloat = floatCount === 0;
    // удаляем все кроме цифир и точки
    let parsedValue = getStrFromAmount(e.target.value, withOutFloat);

    if (parsedValue[0] === '.' && !withOutFloat) {
      parsedValue = '0.';
    }
    
    const partsArray = parsedValue.split('.');
    const [decimalPart, floatPart] = partsArray;

    if (Number(e.target.selectionStart) > decimalPart.length) {
      partsArray[1] = floatPart?.slice(0, floatCount) || '';
    }

    const final = withOutFloat ? decimalPart : partsArray.join('.');
    setSelection([e.target.selectionStart, e.target.selectionEnd]);
    onChange?.(final);
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true)
    rest.onFocus?.(e);
  }

  const onClickInput = (e: any) => {
    const value = getStrFromAmount(e.target.value, floatCount === 0);
    // setSelection([e.target.selectionStart, e.target.selectionEnd]);
    
    // onChange?.(value);
    onClick?.(e);
  }

  useEffect(() => {
    if (isFocused || isFirst) return;
    const finalSum = getOutputSum(rest.value as string);
    
    setInsideState(finalSum);
  }, [rest.value])
  

  useLayoutEffect(() => {
    if (selection && inputRef.current) {
      [inputRef.current.selectionStart, inputRef.current.selectionEnd] = selection;
    }
  }, [selection]);
  
  return (
    <>
      <Input
        {...rest }
        value={isFocused ? rest.value : insideState}
        state={ inputState }
        onBlur={onBlurtFormat}
        onChange={onChangeInput}
        onFocus={ onFocus }
        onClick={onClickInput}
        ref={(e) => {
          // @ts-ignore
          ref?.(e);
          // @ts-ignore
          inputRef.current = e;
        }}
      />
    </>
 
  )
}

export const AmountInput = forwardRef(Component);