import React, { PropsWithChildren, useRef, useEffect, useCallback } from 'react';

import * as S from './styled';

type Props = {
  className?: string;
  disabled?: boolean;
} & PropsWithChildren;

const END_OF_SCROLL_DIFERENCE = 10;
const START_OF_SCROLL_DIFERENCE = 0;

export const TableWrapper: React.FC<Props> = ({ className, disabled, children }) => {
  const tableRef = useRef<HTMLDivElement>(null);

  // Меняются инлайн стили
  // Чтобы на каждое изменение скрол не перерендеривать таблицу целиком
  const onScrollFn = (e: React.UIEvent<HTMLDivElement>) => {
    if (disabled) return;
    const { scrollLeft, classList, scrollWidth, clientWidth  } = e.target as HTMLDivElement;
    
    classList?.remove('initialScrollSides');
    classList?.remove('allScrollSides');
    classList?.remove('endScrollSides');

    // Start scroll
    if (scrollLeft <= START_OF_SCROLL_DIFERENCE) {
      classList?.add('initialScrollSides');
      return;
    }

    const scrollEndPos = Math.ceil(scrollWidth - scrollLeft) - clientWidth;

    // End of right scroll
    if (scrollEndPos >= 0 && scrollEndPos < END_OF_SCROLL_DIFERENCE) {
      classList?.add('endScrollSides');
      return;
    }

    classList?.add('allScrollSides');
  }

  const onEnabledScroll = useCallback(
    () => {
      const elem = tableRef.current;
      if (!elem || disabled) return false;

      const { clientWidth, scrollWidth } = elem;
      const isNoNeedScroll = Number(clientWidth - scrollWidth) >= -10;

      if (clientWidth === scrollWidth || isNoNeedScroll) return false;

      return true;
    },
    [],
  );
  

  // Стартовый скролл есть или нет
  useEffect(() => {
    const elem = tableRef.current;
    if (!elem) return;
    if (disabled) return;

    const { clientWidth, scrollWidth, classList } = elem;
    const isNoNeedScroll = Number(clientWidth - scrollWidth) >= -10;
    console.log(clientWidth, scrollWidth, isNoNeedScroll);
    

    if (clientWidth === scrollWidth || isNoNeedScroll) return;
    
    classList?.add('initialScrollSides')
  }, [])

  if (disabled) return children;

  return (
    <S.TableWrapper
      className={`${className}`}
      hasScroll={onEnabledScroll()}
      onScroll={onScrollFn}
      ref={tableRef}
    >
      {children}
    </S.TableWrapper>
  )
}
