import { Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'

export const CloseOrdersModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal open={isOpen} onClose={onClose} title="Close open orders">
      <Text className='!text-gray.600'>
        You can’t unpublish a strategy if it has open positions. Close all
        orders before unpublishing the strategy.
      </Text>
    </Modal>
  )
}
