import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from 'styled-components/macro'
import { Form } from 'ui/atoms'

export const Container = styled(Form)`
  & > form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`

export const Person = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  & > div {
    margin-bottom: 0 !important;

    &[data-name='middleName'] {
      width: calc(33.3% - 20px);

      @media (max-width: 780px) {
        width: 100%;
      }
    }

    &[data-name='firstName'] {
      width: calc(33.3% - 20px);

      @media (max-width: 780px) {
        width: 100%;
      }
    }

    &[data-name='lastName'] {
      width: calc(33.3%);

      @media (max-width: 780px) {
        width: 100%;
      }
    }

    &[data-name='dateOfBirth'] {
      width: calc(50% - 10px);

      @media (max-width: 780px) {
        width: 100%;
      }
    }

    &[data-name='country'] {
      width: calc(50% - 10px);

      @media (max-width: 780px) {
        width: 100%;
      }
    }

    &[data-name='address'] {
      width: 100%;

      @media (max-width: 780px) {
        width: 100%;
      }
    }

    &[data-name='documentNumber'] {
      width: calc(50% - 10px);

      @media (max-width: 780px) {
        width: 100%;
      }
    }
  }
`

export const Uploads = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const UploadError = styled.div`
  font-size: var(--body-font-size-medium);
  color: var(--color-opal-600);
`

export const UploadsFormat = styled.div`
  font-size: 14px;
  line-height: 21px;
`

export const Mondatory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const MondatoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BenefitsImages = styled.div`
  padding: 0 4px;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 599px) {
    padding: 0;
    gap: 24px 12px;
  }
`

export const BenefitsImage = styled(LazyLoadImage)`
  width: 100%;
  height: auto;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  button {
    margin: 0;
    width: 220px;
  }
`
