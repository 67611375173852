import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

import { Account } from '../types'

export const GET_ACCOUNT_KEY = ['account']

const getAccount = async (accountID?: string) => {
  if (accountID) {
    const res = await axios.get<{ accountInfo: Account }>(
      '/account/v1/account',
      { params: { accountID } },
    )

    return res.data
  }

  return null
}

export const useGetAccount = (accountID?: string, enabled = true) => {
  return useQuery({
    queryKey: [GET_ACCOUNT_KEY, accountID],
    queryFn: () => getAccount(accountID),
    retry: retryFn,
    staleTime: 10000,
    enabled
  })
}
