import { path } from 'libs'
import { useEffect } from 'react'
import styled from 'styled-components'
import { Title } from 'ui/atoms'

import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../../layout'
import bg from './bg-image.png'
import { proceed } from './model'

export const PasswordRecoverySuccess = () => {
  useEffect(() => {
    setTimeout(() => {
      proceed(path.dashboard.entry())
    }, 440)
  }, [])

  return (
    <AuthLayout type="reset">
      <Wrapper>
        <AuthSectionTitle level={2}>Password updated</AuthSectionTitle>
        <AuthSectionText>
          Your password has been changed successfully.
        </AuthSectionText>
        <Title className="mt-[32px]" level={4}>
          Redirecting to Personal Area...
        </Title>
      </Wrapper>
    </AuthLayout>
  )
}

const Wrapper = styled.div`
  padding-top: 160px;
  text-align: center;
  background-image: url(${bg});
  background-size: 128px auto;
  background-repeat: no-repeat;
  background-position: top center;
`
