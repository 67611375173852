import cryptoRandomString from 'crypto-random-string'

export function generatePass() {
  const lowerLetters = 'abcdefghijklmnopqrstuvwxyz'
  const upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numbers = '0123456789'
  const specialCharacters = '!@$%_#()'

  let password = cryptoRandomString({
    length: Math.floor(Math.random() * 9) + 8,
    characters: lowerLetters + upperLetters + numbers + specialCharacters,
  })

  if (!/(?=.*?[a-z])/.test(password)) {
    password += cryptoRandomString({ length: 1, characters: lowerLetters })
  }
  if (!/(?=.*?[A-Z])/.test(password)) {
    password += cryptoRandomString({ length: 1, characters: upperLetters })
  }
  if (!/(?=.*\d)/.test(password)) {
    password += cryptoRandomString({ length: 1, characters: numbers })
  }
  if (!/(?=.*[!@$%_#()])/.test(password)) {
    password += cryptoRandomString({ length: 1, characters: specialCharacters })
  }

  return password
}
