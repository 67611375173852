import styled from "styled-components";

export const Wrapper = styled.div`
  margin-inline-end: 8px;
  min-width: 40px;
  display: flex;
  text-align: center;
  font-size: var(--body-font-size-regular);
  line-height: 20px;
  font-weight: 500;

  &::after {
    content: ':';
    margin-inline-start: 8px;
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
    font-weight: 400;
  }

  &:last-child::after {
    content: none;
  }
`