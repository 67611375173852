import React, { lazy, Suspense } from 'react';

import { Spinner2 } from 'ui/atoms';

const PageLazy = lazy(() => import('./page'));

export const ContestsListPageLazy: React.FC = () => {
  return (
    <Suspense fallback={<Spinner2 size='large'/>}>
      <PageLazy />
    </Suspense>
  )
}
