const knownEmails: string[] = [
  'gmail.com',
  'yahoo.com',
  'ymail.com',
  'mail.com',
]

const isTypo = (original: string, candidate: string): boolean => {
  if (
    Math.abs(original.length - candidate.length) > 2 ||
    knownEmails.some((email) => email === candidate)
  ) {
    return false
  }

  let diff = 0
  let i = 0,
    j = 0

  while (i < original.length && j < candidate.length) {
    if (original[i] !== candidate[j]) {
      diff++
      if (diff > 2) {
        return false
      }
      if (original.length > candidate.length) {
        j--
      } else if (original.length < candidate.length) {
        i--
      }
    }
    i++
    j++
  }

  return diff + (original.length - i) + (candidate.length - j) < 3
}

export const getEmailTip = (email: string): string => {
  let result = ''

  for (const knownEmail of knownEmails) {
    if (isTypo(knownEmail, email)) {
      result = knownEmail

      break
    }
  }

  return result
}
