import axios, { AxiosError } from 'axios'
import { useStore } from 'effector-react'
import { useIsMobile } from 'libs/hooks/useIsMobile'
import { path } from 'libs/path'
import { ResponseError } from 'libs/request'
import { ChangeEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useBlocker, useNavigate } from 'react-router-dom'
import { ZendeskAPI } from 'react-zendesk'
import {
  useCreateStrategy,
  useGetTraderCopyableAccounts,
  useUpdateStrategy,
} from 'services/copytrade'
import { TraderStrategy } from 'services/copytrade/types'
import { SourceItem } from 'services/payments/get/types'
import { Button, Checkbox, Hint, Input, Text, Title } from 'ui/atoms'
import { Alerts } from 'ui/molecules'

import {
  $publishedAccount,
  setStrategyCreationAlertVisible,
  setStrategyUpdateAlertVisible,
} from '../../model'
import { WarningText } from '../../startCopying/styled'
import { ImageUpload } from '../imageUpload'
import { LeavePageModal } from '../leavePageModal'
import { StrategyTextArea } from '../strategyTextArea'
import * as Styled from './styled'
import {
  DEFAULT_ACCOUNT_ID,
  FormMode,
  FormValues,
  formatValues,
  getSourceItem,
} from './utils'

export const StrategyForm = ({
  mode = 'create',
  initialValues,
}: {
  mode?: FormMode
  initialValues?: TraderStrategy
}) => {
  const [t] = useTranslation()

  const navigate = useNavigate()

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      [
        path.copytrade.editStrategy(initialValues?.strategyID || ''),
        path.copytrade.createStrategy(),
      ].some((path) => path === currentLocation.pathname) &&
      nextLocation.pathname !== `${path.copytrade.traderEntry()}/`
    )
  })

  const publishedAccount = useStore($publishedAccount)

  const { data: accounts, isLoading: isAccountsLoading } =
    useGetTraderCopyableAccounts({
      enabled: mode === 'create',
    })
  const { mutateAsync: createStrategy, isLoading: createStrategyLoading } =
    useCreateStrategy()
  const { mutateAsync: updateStrategy, isLoading: updateStrategyLoading } =
    useUpdateStrategy()

  const isMobileMode = useIsMobile()

  const [agreementChecked, setAgreementChecked] = useState(false)
  const [fixedCommissionChecked, setFixedCommissionChecked] = useState(
    initialValues && +initialValues?.commission > 0,
  )
  const [percentCommissionChecked, setPercentCommissionChecked] = useState(
    initialValues && +initialValues?.percentCommission > 0,
  )
  const [values, setValues] = useState<FormValues>({
    copyableAccountID: '',
    commission: initialValues?.commission || '',
    percentCommission: initialValues?.percentCommission || '',
    title: initialValues?.title || '',
    description: initialValues?.description || '',
    imageName: '',
    imageUrl: initialValues?.imageLink || '',
  })
  const [errors, setErrors] = useState<Record<string, string>>({})

  const handleCommissionChange = (
    event: ChangeEvent<HTMLInputElement>,
    name: 'commission' | 'percentCommission',
  ) => {
    const output = event.target.value.replace(/[^.\d]/g, '').split('.')

    setValues({
      ...values,
      [name]:
        output.length > 1 ? output.shift() + '.' + output.join('') : output[0],
    })
  }

  const handleSubmit = async () => {
    try {
      if (mode === 'create') {
        await createStrategy(formatValues(values, mode))

        setStrategyCreationAlertVisible(true)

        setTimeout(() => {
          setStrategyCreationAlertVisible(false)
        }, 10000)
      } else if (initialValues) {
        await updateStrategy({
          ...formatValues(values, mode),
          strategyID: initialValues.strategyID,
        })

        setStrategyUpdateAlertVisible(true)

        setTimeout(() => {
          setStrategyUpdateAlertVisible(false)
        }, 10000)
      }

      navigate(`${path.copytrade.traderEntry()}/`)
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const knownError = error as AxiosError<ResponseError>

        setErrors(
          knownError.response?.data?.details?.validationErrors.items.reduce(
            (acc, error) => ({ ...acc, [error.field]: error.msg }),
            {},
          ) || {},
        )
      }
    }
  }

  const sourcesList: SourceItem[] =
    mode === 'edit'
      ? initialValues
        ? [getSourceItem(initialValues.copyableAccount)]
        : []
      : accounts?.map((account) => getSourceItem(account)) || []

  const isSubmitEnabled =
    ((mode === 'create' && agreementChecked && values.copyableAccountID) ||
      mode === 'edit') &&
    ((+values.percentCommission === 0 && +values.commission >= 1) ||
      (+values.percentCommission >= 1 && +values.percentCommission <= 99)) &&
    values.title

  const resetValues = () => {
    if (!initialValues) {
      return
    }

    setValues({
      ...values,
      commission: initialValues.commission || '',
      percentCommission: initialValues.percentCommission || '',
      title: initialValues.title || '',
      description: initialValues.description || '',
      imageUrl: initialValues.imageLink || '',
    })
    setFixedCommissionChecked(!!+initialValues.commission)
    setPercentCommissionChecked(!!+initialValues.percentCommission)

    setErrors({})
  }

  const handleLeave = () => {
    blocker.proceed?.()

    document.body.style.overflow = 'visible'
  }

  return (
    <Styled.ContentContainer>
      <Styled.TextWrapper>
        <Title level={isMobileMode ? 3 : 2}>
          {mode === 'create' ? 'Create strategy' : 'Edit strategy'}
        </Title>
        <Text level={isMobileMode ? 2 : 1}>
          Use only accounts that don’t copy other strategies and have a balance
          of $100 or more.
        </Text>
      </Styled.TextWrapper>
      <Styled.FieldsWrapper>
        <div>
          <Styled.Select
            onSelect={(value: SourceItem) =>
              setValues({ ...values, copyableAccountID: value.id })
            }
            label="Account"
            placeholder={
              sourcesList.length === 0 && !isAccountsLoading
                ? 'No matching accounts'
                : 'Select an account'
            }
            data={sourcesList}
            defaultValue={
              mode === 'edit'
                ? DEFAULT_ACCOUNT_ID
                : publishedAccount || undefined
            }
            isDisabled={
              mode === 'edit' ||
              (sourcesList.length === 0 && !isAccountsLoading)
            }
          />
          {sourcesList.length === 0 && !isAccountsLoading && (
            <WarningText>
              Deposit $100 to a real account to create a strategy
            </WarningText>
          )}
          {sourcesList.length > 0 && (
            <div className="mt-[10px] text-body.medium leading-body.medium">
              <Trans i18nKey="Can’t find account? Contact our Customer Care">
                Can’t find account? Contact our{' '}
                <span
                  onClick={() => ZendeskAPI('messenger', 'open')}
                  className="!cursor-pointer !text-bronze.500"
                >
                  Customer Care
                </span>
              </Trans>
            </div>
          )}
        </div>
        {mode === 'create' && (
          <Alerts
            type="info"
            size="medium"
            className="mt-[32px] mobile:mt-[24px]"
          >
            <Text level={2}>
              Set up one or both commission types — investors copying your
              strategy will pay only for one.
            </Text>
          </Alerts>
        )}
        <Styled.CommissionWrapper>
          <div>
            <Styled.CommissionCheckbox
              onClick={() => {
                setFixedCommissionChecked(!fixedCommissionChecked)
                setValues({ ...values, commission: '' })
              }}
            >
              <Checkbox checked={fixedCommissionChecked} design="brand" />
              <Hint
                textContent={t(
                  'Monthly commission in USD paid from investors to your USD Wallet',
                )}
              >
                {t('Fixed commission')}
              </Hint>
            </Styled.CommissionCheckbox>
            <Input
              placeholder="From $1"
              value={values.commission ? `$${values.commission}` : ''}
              onChange={(event) => handleCommissionChange(event, 'commission')}
              errorMessage={
                errors.commission ||
                (values.commission && +values.commission < 1)
                  ? 'From $1'
                  : ''
              }
              disabled={!fixedCommissionChecked}
            />
          </div>
          <div>
            <Styled.CommissionCheckbox
              onClick={() => {
                setPercentCommissionChecked(!percentCommissionChecked)
                setValues({ ...values, percentCommission: '' })
              }}
            >
              <Checkbox checked={percentCommissionChecked} design="brand" />
              <Hint
                textContent={t(
                  'Commission paid as a % of investors’ daily profit. It’s calculated based on equity and paid daily to your USD Wallet',
                )}
              >
                {t('% of profit')}
              </Hint>
            </Styled.CommissionCheckbox>
            <Input
              placeholder="From 1% to 99%"
              value={
                values.percentCommission ? `${values.percentCommission}%` : ''
              }
              onChange={(event) =>
                handleCommissionChange(event, 'percentCommission')
              }
              errorMessage={
                errors.commission ||
                (values.percentCommission &&
                  (+values.percentCommission < 1 ||
                    +values.percentCommission > 99))
                  ? 'From 1% to 99%'
                  : ''
              }
              disabled={!percentCommissionChecked}
            />
          </div>
        </Styled.CommissionWrapper>
        {mode === 'edit' && (
          <Alerts type="info" className="mt-[16px]" size="medium">
            <Text level={2}>
              If you update the commission value, it will apply only to new
              investors.
            </Text>
          </Alerts>
        )}
        <Styled.BottomWrapper>
          <ImageUpload
            imageUrl={values.imageUrl || initialValues?.imageLink}
            onChange={({ imageUrl, fileName }) =>
              setValues({
                ...values,
                imageUrl,
                imageName: fileName,
              })
            }
          />
          <div className="grid gap-[16px] w-full">
            <Input
              label="Strategy name"
              placeholder="Enter a unique name"
              value={values.title}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setValues({ ...values, title: event.target.value })
              }
              errorMessage={errors.title}
              maxLength={20}
            />
            <StrategyTextArea
              onChange={(value) => setValues({ ...values, description: value })}
              value={values.description}
              error={errors.description}
            />
          </div>
        </Styled.BottomWrapper>
      </Styled.FieldsWrapper>
      {mode === 'create' && (
        <Styled.CheckboxWrapper
          checked={agreementChecked}
          onChange={setAgreementChecked}
        />
      )}
      <Styled.ButtonsWrapper>
        {mode === 'create' ? (
          <Button
            type="submit"
            name="create-strategy"
            className="min-w-[216px]"
            disabled={!isSubmitEnabled || createStrategyLoading}
            loading={createStrategyLoading}
            onClick={handleSubmit}
          >
            Create strategy
          </Button>
        ) : (
          <>
            <Button
              type="reset"
              name="discard-changes"
              className="min-w-[216px]"
              design="secondary"
              onClick={resetValues}
            >
              Discard changes
            </Button>
            <Button
              type="submit"
              name="edit-strategy"
              className="min-w-[170px]"
              disabled={!isSubmitEnabled || updateStrategyLoading}
              loading={updateStrategyLoading}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </>
        )}
      </Styled.ButtonsWrapper>

      <LeavePageModal
        isOpen={blocker.state === 'blocked'}
        onOk={handleLeave}
        onCancel={() => blocker.reset?.()}
      />
    </Styled.ContentContainer>
  )
}
