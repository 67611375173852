import { Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'

export const LeavePageModal = ({
  isOpen,
  onOk,
  onCancel,
}: {
  isOpen: boolean
  onOk: () => void
  onCancel: () => void
}) => {
  return (
    <Modal
      open={isOpen}
      cancelLabel="Cancel"
      onClose={onCancel}
      okButtonProps={{ design: 'dangerous' }}
      okLabel="Leave"
      onOk={onOk}
      title="Leave page?"
    >
      <Text className="!text-gray.600">
        Changes that you made may not be saved.
      </Text>
    </Modal>
  )
}
