import React from 'react';

import { Filter } from './Filter';
import { Table } from './Table';

export const FinancialHistoryTab = () => {
  return (
    <div className='min-h-[150px]'>
      <Filter />
      <Table />
    </div>
  )
}
