import styled from 'styled-components/macro'
import { Back, Button } from 'ui/atoms'

export const EmailHint = styled.div`
  margin-top: -10px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);

  &::before {
    content: '';
    margin-inline-end: 6px;
    width: 20px;
    height: 20px;
    background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 7.5V10.625M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM10 13.125H10.0063V13.1313H10V13.125Z" stroke="%23D0AB50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
`

export const RegisterButton = styled(Button)`
  width: 100%;
  margin-top: 40px;

  @media (max-width: 600px) {
    margin-top: 32px;
  }
`

export const BackButton = styled(Back)`
  margin-top: 16px;
  width: 100%;
`

export const EmailError = styled.div`
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  color: var(--color-opal-600);
  margin-top: 6px;
`