import { useStore } from 'effector-react'
import { $account } from 'pages/accounts/model'

export const useGeneratePassword = () => {
  // todo: т.к. логика герерации пароля реализована через эффектор,
  // новый пароль берём отсюда
  const activeAccount = useStore($account)

  return { password: activeAccount?.password }
}
