import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';

import { useIsMobile } from 'libs/hooks/useIsMobile';
import { Icon2, Icons } from 'ui/atoms/icon2';
import { Text } from 'ui/atoms';

import * as S from './styled';

export interface Props {
  iconName: Icons;
  name?: string;
  options: {label: string, value: string}[];
  isActive?: boolean;
  value?: string;
  onClickSortCell?: () => void;
  onChangeSort: (val: string) => void;
}

export const SortSelect: React.FC<Props> = ({ iconName, isActive, options, onChangeSort, value, onClickSortCell }) => {
  const tableSelectElem = document.getElementById('table-sort-select');
  
  const showOptions = true;
  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const offsetRef = useRef(0);

  const isMobile = useIsMobile();

  const [t] = useTranslation();

  const [isDropdownVisible, setDropdownVisibility] = useState(false);

  useOnClickOutside(listRef, () => {
    setDropdownVisibility(false);
  });

  const onClick = () => {
    if (isMobile) {
      onClickSortCell?.();
      return;
    }
    
    setDropdownVisibility(true);
  }

  useEffect(() => {
    const pos = ref?.current?.getBoundingClientRect();
    const posPortal = tableSelectElem?.getBoundingClientRect();
    
    if (pos && posPortal) {
      offsetRef.current = pos.x - posPortal.x - 40;
      // offsetRef.current = pos.x - 50;
    }
  }, [tableSelectElem]);

  const optionsContent = (
    <S.OptionList style={{ maxHeight: 260 }}>
    {showOptions &&
      options.map(({ value: optValue, label }, key) => (
        <S.Option
          key={key}
          onClick={(e: React.MouseEvent) => {
            // offsetRef.current = 0;
            onChangeSort?.(optValue);
            setDropdownVisibility(false);
            e.stopPropagation();
          }}
          className={`${value === optValue ? '!text-bronze.500' : ''}`}
        >
          <Text level={2}>{t(label)}</Text>
          <div className='ml-[12px] w-[20px] h-[20px]'>
            {value === optValue && <Icon2 name='check' size='base' className='!text-bronze.500' />}
          </div>
        </S.Option>
      ))}
    </S.OptionList>
  )

  return (
    <div ref={ref} onClick={onClick}>
      <S.Icon
        name={iconName}
        size='custom'
        isActive={ isActive || isDropdownVisible }
      />

      { tableSelectElem && !isMobile &&
        createPortal(
          <S.OptionContainer
            data-visible={isDropdownVisible}
            style={{
              left: offsetRef.current
            }}
            ref={listRef}
          >
            {optionsContent}
          </S.OptionContainer>,
          tableSelectElem
        )
      }
    </div>
  )
}
