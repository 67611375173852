import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { GenericHeader } from 'ui/organisms'

import { useGetSidebar } from 'features/sidebar/useGetSidebar'

import { DisclaimerTitle, DisclaimerWrapper } from '../generic'
import * as Styled from './styled'

export const PublicTemplate = () => {
  const [t] = useTranslation()

  const menuItems = useGetSidebar();

  return (
    <Styled.Wrapper>
      <GenericHeader menuItems={menuItems} isLoggedIn={false} />
      <Styled.ContentContainer>
        <Outlet />
        <DisclaimerWrapper>
          <DisclaimerTitle>{t('Risk disclaimer')}</DisclaimerTitle>
          <div>
            {t(
              'Trading financial instruments with leverage carries a high risk to your capital.',
            )}
          </div>
          <div>
            {t(
              'Headway Inc. does not offer its services to residents of countries that were, are, or will be blacklisted by the FATF.',
            )}
          </div>
          <div>
            {t(
              'This website is not directed to the residents of countries where such financial services may violate the laws or regulations of the land. The information on this website is not aimed at solicitation or invitation.',
            )}
          </div>
          <div>
            {t(
              'Anyone who accesses this website must therefore follow the laws and regulations applicable in their country of residence.',
            )}
          </div>
        </DisclaimerWrapper>
      </Styled.ContentContainer>
    </Styled.Wrapper>
  )
}
