import { createEvent, restore, sample } from 'effector'

import { fx } from '../../libs'
import { service } from '../../services'
import { putAccountPassword } from './generatePassword/model'
import { patchSwapFree } from './swapFree/model'
import { Account, AccountResponse } from './types'

export const getAccount = fx<void, AccountResponse>({
  method: 'GET',
  url: `/account/v1/account`,
})

export const $account = restore<Account>(
  getAccount.doneData.map((a) => a.body.accountInfo),
  null,
)

// export const $account = createStore(getAccount.doneData.map((a) => a.body.accountInfo), null,)

export const onChangeAccount = createEvent<Account>()

$account.on(onChangeAccount, (state, data: Account) => data)

$account.on(
  putAccountPassword.doneData,
  (_, payload) => payload.body.accountInfo,
)

export const $accounts = restore<Account[]>(
  service.accounts.getAccounts.doneData.map((a) => a.body.items),
  [],
)

export const onUpdateAccountInList = createEvent<{
  id: string
  account: Account
}>()
$accounts.on(onUpdateAccountInList, (state, data) => {
  const { id, account: newAccount } = data
  return state?.map((oldAcc) => (oldAcc.accountID === id ? newAccount : oldAcc))
})

sample({
  clock: [putAccountPassword.done, patchSwapFree.done],
  fn: (params) => {
    return {
      query: {
        accountID: params.params.body?.accountID || '',
      },
    }
  },
  target: [getAccount],
})

sample({
  clock: [putAccountPassword.done, patchSwapFree.done],
  fn: () => {return {
    query: {
      excludeArchived: 'true',
    },
  }},
  target: [service.accounts.getAccounts],
})
