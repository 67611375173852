import { useStoreMap } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Gateway, PaymentMethod } from 'services/money/get/types'
import { Icon2, Tabs, Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'
import { useDebounce } from 'usehooks-ts'

import * as Styled from './styled'

const paymentMethods: Record<PaymentMethod, string> = {
  PMT_CREDIT_CARD: 'Bank cards',
  PMT_ONLINE_BANK: 'Banks',
  PMT_E_WALLET: 'Electronic payments',
  PMT_CRYPTO: 'Crypto',
  PMT_EXCHANGER: 'Exchanger',
}

export const GatewaysGrid = ({
  data,
  disabled = false,
  filtersVisible = true,
  onChoose,
}: {
  data: Gateway[]
  disabled?: boolean
  filtersVisible?: boolean
  onChoose: (gateway: Gateway) => void
}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const userIsVerified = useStoreMap($userProfile, (user) => user?.isVerified)

  const [activeTab, setActiveTab] = useState<string>('all')
  const [searchVisible, setSearchVisible] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 400)
  const [filteredGateways, setFilteredGateways] = useState<Gateway[]>(data)
  const [verificationErrorVisible, setVerificationErrorVisible] =
    useState(false)

  const tabs = useMemo(() => {
    const tabs = [{ value: 'all', label: t('All') }]

    new Set(data.map((gateway) => gateway.paymentMethodType)).forEach(
      (paymentMethod) =>
        tabs.push({
          value: paymentMethod,
          label: t(paymentMethods[paymentMethod]),
        }),
    )

    return tabs
  }, [data])

  const handleGatewayChoose = (gateway: Gateway) => {
    if (gateway.verificationRequired && !userIsVerified) {
      setVerificationErrorVisible(true)
    } else {
      onChoose(gateway)
    }
  }

  const onResetInput = () => {
    setSearchValue('')
    setSearchVisible(!searchVisible)
  }

  useEffect(() => {
    setFilteredGateways(
      data
        .filter((gateway) =>
          debouncedSearchValue
            ? gateway.name.toLowerCase().includes(debouncedSearchValue)
            : gateway,
        )
        .filter((filteredGateway) =>
          activeTab === 'all'
            ? filteredGateway
            : filteredGateway.paymentMethodType === activeTab,
        ),
    )
  }, [activeTab, debouncedSearchValue, data])

  return (
    <>
      {filtersVisible && (
        <Styled.Filters>
          <Styled.TabsWrapper>
            <Tabs
              items={tabs}
              onChange={setActiveTab}
              defaultActiveValue={activeTab}
            />
          </Styled.TabsWrapper>
          <Styled.SearchWrapper searchVisible={searchVisible}>
            {searchVisible && (
              <Styled.SearchInput
                value={searchValue}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSearchValue(event.target.value.toLowerCase())
                }
                placeholder="Search"
              />
            )}
            <Styled.SearchButton
              name="search"
              size="medium"
              design="tertiary"
              prefix={
                <Icon2
                  name={searchVisible ? 'xMarkIcon' : 'magnifyingGlassIcon'}
                />
              }
              onClick={onResetInput}
            />
          </Styled.SearchWrapper>
        </Styled.Filters>
      )}
      <Styled.Wrapper disabled={disabled}>
        {filteredGateways.map((gateway) => (
          <Styled.GatewayContainer
            key={gateway.gatewayID}
            onClick={() => handleGatewayChoose(gateway)}
          >
            <Styled.GatewayHeader logo={gateway.logo}>
              <div>
                {gateway.name}
                {!userIsVerified && gateway.verificationRequired ? (
                  <Styled.Verification>
                    <Icon2
                      name="exclamationCircleSolind"
                      size="small"
                      className="text-orange.500"
                    />
                    {t('Verification required')}
                  </Styled.Verification>
                ) : null}
              </div>
            </Styled.GatewayHeader>
            <Styled.Limits>
              {t('Limits')} <span>{gateway.limits}</span>
            </Styled.Limits>
          </Styled.GatewayContainer>
        ))}
      </Styled.Wrapper>
      <Modal
        open={verificationErrorVisible}
        okLabel="Verify now"
        onClose={() => setVerificationErrorVisible(false)}
        onOk={() => {
          setVerificationErrorVisible(false)

          navigate(path.dashboard.verification.entry())
        }}
        title="Verification required"
        icon="verification"
        cancelLabel="Back"
      >
        <Text className="text-center !text-gray.600">
          Please verify your profile to use this payment method.
        </Text>
      </Modal>
    </>
  )
}
