import { useStore } from 'effector-react'
import React, { useState } from 'react'
import { useAddBalanceDemo } from 'services/accounts/mutation/useAddBalanceDemo'
import { Icon2, Text } from 'ui/atoms'
import { FormInput } from 'ui/molecules'
import { Modal } from 'ui/molecules/modal_draft'
import { useWindowSize } from 'usehooks-ts'

import { Button } from '../transactions/molecules/notFound/styled'
import { Account } from '../types'
import { $balanceModalId, setBalanceModalId } from './model'

interface Props {
  id: string
  asLink?: boolean
  onSetNewBalance?: (id: string, newAccountData: Account) => void
  isLarge?: boolean
}

export const DemoAddBalance: React.FC<Props> = ({
  id,
  asLink,
  onSetNewBalance,
  isLarge,
}) => {
  const { width } = useWindowSize()
  const isMobile = width < 471

  const balanceModalId = useStore($balanceModalId)

  const { mutateAsync, isLoading } = useAddBalanceDemo()

  const [amount, setAmount] = useState<string>('')

  const addBalance = async () => {
    const resp = await mutateAsync({
      amount,
      accountID: id,
    })

    onSetNewBalance?.(id, resp.accountInfo)
    setBalanceModalId(null)
    setAmount('')
  }

  const onShowModal = (e?: React.MouseEvent) => {
    if (asLink) {
      e?.stopPropagation()
    }

    setBalanceModalId(id)
  }

  const onCloseModal = (e?: React.MouseEvent) => {
    if (asLink) {
      e?.stopPropagation()
    }

    setBalanceModalId(null)
  }

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(/[^\d]/g, '')

    setAmount(val)
  }

  return (
    <>
      {asLink ? (
        <Text
          onClick={onShowModal}
          weight="medium"
          className="!text-bronze.500 cursor-pointer whitespace-nowrap !leading-none"
          level={isLarge && !isMobile ? 1 : 2}
        >
          Add balance
        </Text>
      ) : (
        <Button
          size="small"
          name="details-deposit"
          onClick={onShowModal}
          design="primary"
          prefix={<Icon2 name="arrowDownOnSquare" size="small" />}
        >
          Add balance
        </Button>
      )}

      <Modal
        open={balanceModalId === id}
        onClose={onCloseModal}
        onOk={addBalance}
        okLabel="Add balance"
        title={`Add balance to demo account ${id}`}
        okButtonProps={{ disabled: isLoading || !+amount, loading: isLoading }}
        width={400}
      >
        <FormInput
          value={amount}
          label="Amount"
          maxLength={10}
          autoComplete="off"
          onChange={onChangeAmount}
        />
      </Modal>
    </>
  )
}
