import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BankCardStatus, BankCardsList } from 'services/payments/get/types';
import { useGetCardsList } from 'services/payments/get/useGetCardsList';
import { Title, Text } from 'ui/atoms';
import { SelectWithModal } from 'ui/atoms/select/SelectWithModal';

import { CardStatusItem } from './CardStatusItem';
import * as S from './styled';


const options = [
  { label: 'All statuses', value: 'all' },
  { label: 'Pending', value: 'pending' },
  { label: 'Verified', value: 'verified' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Expired', value: 'expired' },
  { label: 'Unverified', value: 'unverified' },
];


export const StatusesList = () => {
  const mapperCardListRes = (list: BankCardsList) => {
    if (!list?.bankCardInfo?.length) return undefined;

    return {
      bankCardInfo: list.bankCardInfo.filter(card => card.status !== 'unverified')
    }
  }
  const { data } = useGetCardsList(mapperCardListRes as any);
  const [t] = useTranslation();

  const [filter, setFilter] = useState<BankCardStatus | 'all'>('all');

  const statusesCards = useMemo(() => {
    if (!data?.bankCardInfo.length) return [];
    if (!filter || filter === 'all') return data.bankCardInfo;

    return data.bankCardInfo.filter(card => card.status === filter);
  }, [filter, data]
  );

  const onSelect = (val: string) => {
    setFilter(val as BankCardStatus);
  }

  if (!data?.bankCardInfo.length) return null;

  return (
    <>
        <S.Wrapper className='mt-[4rem]'>
          <div className='flex items-center justify-between'>
            <Title level={2} className='mb-[24px]'>Status</Title>
            <SelectWithModal
              // value={chooseCard?.id}
                modalTitle={t('Status')}
                name="wallets-select"
                value={filter}
                autoComplete="off"
                maxHeight={410}
                inputClasses='!h-[40px] !w-[150px]'
                options={options}
                onSelect={onSelect}
              />
          </div>
          
          {
            statusesCards.map(card => (
              <CardStatusItem card={card} key={`${card.id}_${card.currency}`} />
            ))
          }

          {
            statusesCards.length === 0 && (
              <Text withTranslate={ false }>
                No cards with {filter} status were found
              </Text>
            )
          }
        </S.Wrapper>
    </>
  )
}
