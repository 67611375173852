import styled from 'styled-components/macro'

export const MetaTradersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 599px) {
    gap: 16px;
  }
`
export const MetaTradersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const MetaTradersItem = styled.div<{ url: string; defaultUrl: string }>`
  padding-top: 16px;
  padding-bottom: 16px;
  padding-inline-end: 0;
  padding-inline-start: 56px;
  display: flex;
  align-items: center;
  background-image: ${(props) => `url(${props.defaultUrl})`};
  background-image: ${(props) =>
    `image-set(url(${props.url}), url(${props.defaultUrl}))`};
  justify-content: space-between;
  background-size: 32px auto;
  background-repeat: no-repeat;
  background-position: ${({ theme }) =>
    theme.dir === 'rtl' ? 'center right 12px' : 'center left 12px'};

  @media (max-width: 599px) {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-inline-start: 44px;
    background-position: ${({ theme }) =>
      theme.dir === 'rtl' ? 'center right' : 'center left'};
  }
`

export const MetaTradersSettingsList = styled.div`
  margin-top: 12px;
  border: 1px solid var(--color-gray-300);
  padding: 4px 12px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`

export const MetaTradersSettingsItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 400;

  @media (max-width: 599px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const MetaTradersSettingsItemValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
`
