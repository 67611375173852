import { onShowUserDataChangeModal } from 'pages/dashboard/profile/model'
import React from 'react'
import {
  ProfileRequestStatus,
  ProfileStatus,
  VerificationProfileBlock,
} from 'services/profile/getProfile'
import { Avatar, Title } from 'ui/atoms'

import {
  editableStatusesFree,
  editableStatusesWithModal,
} from '../../utils/EditableStatuses'
import { ChangeNameDialog } from './ChangeNameDialog'
import * as S from './styles'
import { VerificationStatus } from './VerificationStatus'

interface Props {
  firstName: string
  lastName: string
  middleName?: string
  avatarUrl?: string
  verification: VerificationProfileBlock
  profileStatus?: ProfileRequestStatus
  updatingDocumentRequest?: boolean
}

export const AvatarBlock: React.FC<Props> = ({
  firstName,
  middleName,
  lastName,
  updatingDocumentRequest,
  avatarUrl,
  verification,
  profileStatus,
}) => {
  return (
    <div className="flex">
      <Avatar size="md" classes="me-4" url={avatarUrl} />

      <div>
        <div className="flex mb-[0.8rem] items-center">
          <Title level={2}>{`${firstName} ${middleName} ${lastName}`}</Title>
          <span className="ms-[8px]">
            {profileStatus !== 'IN_PROGRESS' &&
              !updatingDocumentRequest &&
              editableStatusesFree.includes(verification.status) && (
                <ChangeNameDialog lastName={lastName} firstName={firstName} />
              )}
            {profileStatus !== 'IN_PROGRESS' &&
              !updatingDocumentRequest &&
              editableStatusesWithModal.includes(verification.status) && (
                <S.EditIcon
                  size="base"
                  name="pencilSolid"
                  onClick={() => onShowUserDataChangeModal()}
                />
              )}
          </span>
        </div>

        <VerificationStatus
          verification={verification}
          updatingDocumentRequest={updatingDocumentRequest}
        />
      </div>
    </div>
  )
}
