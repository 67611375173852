import styled, { css } from 'styled-components/macro'
import { Button, Input as UiInput } from 'ui/atoms'
import { InputField } from 'ui/atoms/input/styled'
import { CopyButton } from 'ui/molecules/CopyButton'

export const PasswordGenerator = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 4px;
`

export const Input = styled(UiInput)`
  ${InputField} {
    padding-inline-end: 56px;
  }
`

export const PasswordCopyButton = styled(CopyButton)`
  position: relative;
  margin-inline-start: 8px;
  width: 52px;
  height: 52px !important;
  color: var(--color-gray-600);

  &:hover {
    color: var(--color-gray-700);
  }
`

export const RuleWrapper = styled.div<{ isValid: boolean }>`
  display: inline-flex;
  align-items: center;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);

  @media (max-width: 600px) {
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }

  &::before {
    content: '';
    margin-inline-end: 4px;
    width: 20px;
    height: 20px;
    background-image: url('data:image/svg+xml,<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.7045 4.65347C17.034 4.9045 17.0976 5.37509 16.8466 5.70457L8.84657 16.2046C8.71541 16.3767 8.51627 16.4838 8.30033 16.4983C8.08439 16.5129 7.87271 16.4334 7.71967 16.2804L3.21967 11.7804C2.92678 11.4875 2.92678 11.0126 3.21967 10.7197C3.51256 10.4268 3.98744 10.4268 4.28033 10.7197L8.17351 14.6129L15.6534 4.79551C15.9045 4.46603 16.3751 4.40243 16.7045 4.65347Z" fill="%2345B892"/></svg>');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    ${({ isValid }) =>
      !isValid &&
      css`
        background-image: url('data:image/svg+xml,<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.28033 5.71967C5.98744 5.42678 5.51256 5.42678 5.21967 5.71967C4.92678 6.01256 4.92678 6.48744 5.21967 6.78033L8.93934 10.5L5.21967 14.2197C4.92678 14.5126 4.92678 14.9874 5.21967 15.2803C5.51256 15.5732 5.98744 15.5732 6.28033 15.2803L10 11.5607L13.7197 15.2803C14.0126 15.5732 14.4874 15.5732 14.7803 15.2803C15.0732 14.9874 15.0732 14.5126 14.7803 14.2197L11.0607 10.5L14.7803 6.78033C15.0732 6.48744 15.0732 6.01256 14.7803 5.71967C14.4874 5.42678 14.0126 5.42678 13.7197 5.71967L10 9.43934L6.28033 5.71967Z" fill="%23D7504B"/></svg>');
      `}
  }
`

export const ProceedButton = styled(Button)`
  margin-top: 32px;
  width: 100%;

  @media (max-width: 600px) {
    margin-top: 16px;
  }
`
