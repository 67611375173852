import { createEvent, createStore, sample } from 'effector'
import { Session, setAccessToken, setRefreshToken } from 'features/session'
import { getUserAfterLoginByEmail } from 'features/user/model'
import i18next from 'i18next'
import { fx } from 'libs'

import { LoginByEmail } from './types'

export const mounted = createEvent()

export const submitForm = createEvent<LoginByEmail>()

export const loginByEmail = fx<LoginByEmail, Session>({
  url: '/auth/v1/login-by-email',
})

sample({
  clock: submitForm,
  fn: ({ email, password }) => {
    const crendentials = btoa(
      encodeURIComponent(`${email.trim()}:${password}`).replace(
        /%([0-9A-F]{2})/g,
        function (_match, p1) {
          return String.fromCharCode(parseInt(p1, 16))
        },
      ),
    )
    return {
      headers: {
        Authorization: `Basic ${crendentials}`,
      },
    }
  },
  target: loginByEmail,
})

sample({
  clock: loginByEmail.doneData,
  fn: (doneData) => doneData.body.identificationResult.accessToken,
  target: setAccessToken,
})

sample({
  clock: loginByEmail.doneData,
  fn: (doneData) => doneData.body.identificationResult.refreshToken,
  target: setRefreshToken,
})

// if login by email done redirect to dashboard

sample({
  clock: loginByEmail.done,
  fn: () => ({}),
  target: getUserAfterLoginByEmail,
})

sample({
  clock: getUserAfterLoginByEmail.doneData,
  fn: (doneData) => {
    const { user, userRoles } = doneData.body

    // @ts-expect-error
    window.dataLayer.push({
      user: {
        auth_status: 1,
        country: user.country,
        language:
          i18next.language.slice(0, 2) ||
          localStorage.i18nextLng.slice(0, 2) ||
          'en',
        id: user.userID,
        fname: user.firstName,
        lname: user.lastName,
        email: user.email,
        verifyemail: user.isEmailConfirmed ? 1 : 0,
        person_type: {
          trader: !!userRoles?.isTrader,
          partner: !!userRoles?.isPartner,
          nova: !!userRoles?.isNova,
          investor: !!userRoles?.isInvestor,
          strategist: !!userRoles?.isStrategist,
        },
      },
    })
  },
})

// handle errors

export const $error = createStore<string>('')

$error
  .on(
    loginByEmail.failData,
    (_, failData) =>
      failData.body.details?.msg ||
      failData.body.details?.validationErrors?.items?.[0]?.msg ||
      failData.body.msg,
  )
  .reset([mounted, submitForm])
