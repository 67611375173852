import { createEvent, createStore, restore, sample } from 'effector'
import { toPath } from 'features/router'
import { $userProfile, UserProfileType as UserProfile } from 'features/user'
import { setUserProfile } from 'features/user'
import { ClientError, fx, mapResponseError, path } from 'libs'

import { setTimerInitial } from '../confirm/model'
import { EmailConfirmationRequest } from './types'

export const setTempEmail = createEvent<string>()
export const $tempEmail = restore(setTempEmail, null)

export const submitPasswordRecoveryRepeatForm = createEvent<{ email: string }>()

export const resendCode = fx<
  Pick<UserProfile, 'email'>,
  EmailConfirmationRequest
>({
  method: 'POST',
  url: '/auth/v1/reset-password',
})

sample({
  clock: submitPasswordRecoveryRepeatForm,
  fn: ({ email }) => {
    setTempEmail(email.trim())

    return {
      body: {
        email: email.trim(),
      },
    }
  },
  target: resendCode,
})

// redirect to confirm in case of success

sample({
  source: [$userProfile, $tempEmail],
  clock: resendCode.doneData,
  fn: ([userProfile, tempEmail], doneData) => {
    setUserProfile({
      ...(userProfile as UserProfile),
      email: tempEmail as string,
    })
    localStorage.setItem('userEmail', tempEmail as string)
    localStorage.setItem('resendDate', doneData.body.resendAfter)
    setTempEmail('')

    setTimerInitial(+doneData.body.resendAfter - Math.floor(Date.now() / 1000))

    return path.register.password.recovery.confirm()
  },
  target: [toPath],
})

// handle errors

export const $error = createStore<ClientError<{ email: string }> | null>(null)

$error
  .on(resendCode.failData, (_, failData) =>
    mapResponseError<{ email: string }>(failData.body),
  )
  .reset(submitPasswordRecoveryRepeatForm)
