import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { DefaultUploadPlace } from '../component';
import { changeIsLoadingFiles } from '../models/loadingFileStore';

type Options = {
  defaultUploadPlace?: DefaultUploadPlace[];
  uploadFilesLength?: number;
}

export const useLoadingPosition = ({ defaultUploadPlace = [], uploadFilesLength}: Options ) => {
  // Массив загруженных позиций
  const [loadedPlaces, setLoadedPlaces] = useState<string[]>([]);
  const [loadingArray, setLoadingArray] = useState<string[]>([]);
  
  const addLoadedPlaces = useCallback((id?: string) => {
    if (!id) return;

    setLoadedPlaces(prev => {
      if (prev.includes(id)) return prev;
      return [...prev, id];
    });
  }, []);

  const addLoading = (id: string) => {
    if (!id) return;
    
    setLoadingArray(prev => {
      if (prev.includes(id)) return prev;
      return [...prev, id];
    });
  }

  const removeLoading = useCallback((place?: string) => {
    if (!place) return;
    setLoadingArray(prev => prev.filter(item => item !== place));
  }, []);

  const removeLoadedPlaces = useCallback((place?: string) => {
    if (!place) return;
    setLoadedPlaces(prev => prev.filter(item => item !== place));
  }, []);

  const defaultUploadArray = useMemo(() => {
    if (!loadedPlaces?.length) return defaultUploadPlace;

    if (!defaultUploadPlace?.length) return [];
    if (typeof uploadFilesLength === 'number' && uploadFilesLength >= defaultUploadPlace?.length) return[]
    
    return defaultUploadPlace.filter(item => !loadedPlaces.includes(item.id));
  }, [defaultUploadPlace, loadedPlaces]);

  useEffect(() => {
    return () => {
      changeIsLoadingFiles(false);
    }
  }, []);

  useEffect(() => {
    if (!loadingArray?.length) {
      changeIsLoadingFiles(false);
      return;
    }

    changeIsLoadingFiles(true);
  }, [loadingArray]);

  return ({ addLoadedPlaces, removeLoadedPlaces, addLoading, removeLoading, loadedPlaces, defaultUploadArray, loadingArray })
}
