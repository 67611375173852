import React, {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce, useIsFirstRender } from 'usehooks-ts'

import * as S from './styled'

export type InputState = 'hint' | 'error' | 'valid'

export type InputVariant = 'outline' | 'inline'

interface Props {
  className?: string
  type?: 'text' | 'email' | 'password' | 'number'
  variant?: InputVariant
  value?: string | number
  inputClasses?: string
  placeholder?: string
  autofocus?: boolean
  state?: InputState
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  label?: React.ReactNode
  errorMessage?: string
  name?: string
  size?: 'small' | 'medium' | 'large'
  suffixType?: 'button' | 'icon'
  replacePattern?: RegExp
  showPassword?: boolean
  disabled?: boolean
  optional?: boolean
  readOnly?: boolean
  withDebaunce?: boolean
  debounceCallbackFn?: (val: string) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onClick?: React.MouseEventHandler<HTMLInputElement>
  onShowPasswordChange?: () => void
}

export type InputProps = InputHTMLAttributes<HTMLInputElement> & Props

const Component: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    className,
    errorMessage,
    type = 'text',
    value,
    autofocus,
    state = 'hint',
    suffix,
    suffixType = 'icon',
    prefix,
    placeholder = '',
    label,
    name,
    variant = 'inline',
    showPassword: controlledShowPassword,
    onShowPasswordChange,
    disabled,
    inputClasses = '',
    replacePattern,
    optional,
    withDebaunce,
    debounceCallbackFn,
    readOnly,
    size = 'medium',
    onChange = () => {},
    onClick,
    ...rest
  },
  ref,
) => {
  const [inputValue, setInputValue] = useState(String(value))
  const [localShowPassword, setLocalShowPassword] = useState(false)

  const showPassword =
    controlledShowPassword !== undefined
      ? controlledShowPassword
      : localShowPassword

  const inputType = type === 'password' && showPassword ? 'text' : type

  const [t] = useTranslation()

  const debouncedInputValue = useDebounce(inputValue, 400)
  const isFirst = useIsFirstRender()

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (replacePattern) {
      e.target.value = e.target.value.replace(replacePattern, '')
    }

    onChange?.(e)
    if (withDebaunce) {
      setInputValue(e.target.value)
    }
  }

  const toggleShowPassword = () => {
    if (onShowPasswordChange) {
      onShowPasswordChange()
    } else {
      setLocalShowPassword((prev) => !prev)
    }
  }

  useEffect(() => {
    if (debounceCallbackFn && !isFirst) {
      debounceCallbackFn(debouncedInputValue)
    }
  }, [debouncedInputValue])

  return (
    <S.InputWrap className={className}>
      {label && (
        <S.InputLabel htmlFor={name}>
          {typeof label === 'string' ? t(`${label}`) : label}{' '}
          {optional && <S.Optional>({t('optional')})</S.Optional>}
        </S.InputLabel>
      )}
      <S.InputWrap>
        {prefix && <S.InputPrefix>{prefix}</S.InputPrefix>}
        <S.InputField
          onClick={onClick}
          id={name}
          variant={variant}
          name={name}
          type={inputType}
          onChange={onChangeInput}
          value={value}
          hasError={!!errorMessage}
          autoFocus={autofocus}
          readOnly={readOnly}
          className={inputClasses}
          placeholder={t(`${placeholder}`) || ''}
          data-state={state}
          data-test-id={`input-${name}`}
          disabled={disabled}
          data-prefix={Boolean(prefix)}
          data-size={size}
          ref={ref}
          {...rest}
        />
        {type === 'password' && (
          <S.ShowPassword onClick={toggleShowPassword}>
            <S.ShowPasswordIcon name={showPassword ? 'eye' : 'eyeSlash'} />
          </S.ShowPassword>
        )}
        {suffix && (
          <S.InputSuffix data-interactive={suffixType === 'button'}>
            {suffix}
          </S.InputSuffix>
        )}
      </S.InputWrap>
      {errorMessage && (
        <S.FieldState state={state}>{t(errorMessage)}</S.FieldState>
      )}
    </S.InputWrap>
  )
}

export const Input = forwardRef(Component)
