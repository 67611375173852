import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'
import { retryFn } from 'services/helpers/retryFn'

import { AccountsList } from '../types'

export const GET_ACCOUNTS_LIST_KEY = ['accounts-list']

type ReqParams = {
  excludeArchived?: boolean;
}

const getAccounts = async ({ excludeArchived = true }: ReqParams) => {
  const res = await axios.get<AccountsList>('/account/v1/accounts', {
    params: {
      excludeArchived,
    }
  });

  return res.data
}

type Options = {
  params?: ReqParams;
  enabled?: boolean;
};

export const useGetAccounts = ({ params = {}, enabled }: Options) => {
  return useQuery({
    queryKey: [GET_ACCOUNTS_LIST_KEY],
    queryFn: () => getAccounts(params),
    retry: retryFn,
    staleTime: 300,
    enabled,
  })
}
