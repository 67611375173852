import styled from 'styled-components/macro'
import { Input as UiInput } from 'ui/atoms'
import { InputSuffix } from 'ui/atoms/input/styled'

export const Input = styled(UiInput)`
  margin-top: 12px;

  ${InputSuffix} {
    pointer-events: initial;
    top: 1px;
    inset-inline-end: 13px;
    padding-inline-start: 8px;
    height: calc(100% - 2px);
    transition: background-color (--transition);
    background-color: var(--color-gray-100);
  }

  &:hover {
    ${InputSuffix} {
      background-color: var(--color-gray-200);

      &:hover {
        background-color: var(--color-gray-100);
      }
    }
  }
`
