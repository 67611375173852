import { AccountTariffName } from 'pages/accounts/create/types'
import { Account } from 'services/accounts/types'
import { SourceItem } from 'services/payments/get/types'

export type FormMode = 'create' | 'edit'

export type FormValues = {
  copyableAccountID?: string
  commission: string
  percentCommission: string
  title: string
  description: string
  imageName: string
  imageUrl?: string
}

export const DEFAULT_ACCOUNT_ID = 'DEFAULT_ACCOUNT_ID'

export const getSourceItem = (account: Account): SourceItem => ({
  id: account.accountID || DEFAULT_ACCOUNT_ID,
  ...account,
  balance: `${account.balance}`,
  type: 'OT_ACCOUNT',
  accountPlatform: account.platform,
  accountType: account.tariffName as AccountTariffName,
})

export const formatValues = (formValues: FormValues, mode: FormMode) => {
  const values = {
    ...formValues,
    percentCommission: +formValues.percentCommission,
  }

  delete values.imageUrl

  if (mode === 'edit') {
    delete values.copyableAccountID
  }

  return values
}
