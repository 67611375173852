import { path } from 'libs/path'
import { yup } from 'libs/validators'
import styled from 'styled-components/macro'
import { Back, Button, Field, Form, Link } from 'ui/atoms'

import { useLoginByEmailPage } from './hooks'
import { submitForm } from './model'

const intial = { email: '', password: '' }

export const LoginEmail = ({ onBack }: { onBack?: () => void }) => {
  const { form, handleEmailChange } = useLoginByEmailPage()
  const { loading, error } = form

  const isLoading = loading

  return (
    <Form
      onSubmit={submitForm}
      initial={intial}
      validation={yup.login.email}
      error={error}
    >
      <Field
        name="email"
        label="Email"
        placeholder="Type your Email"
        error={error && ' '}
        autofocus
        onChange={handleEmailChange}
      />
      <Field
        name="password"
        label="Password"
        type="password"
        error={error && ' '}
        placeholder="Password"
      />
      <ForggotPasswordLink to={path.register.password.recovery.entry()}>
        Forgot password?
      </ForggotPasswordLink>
      <RegisterButton
        name="login"
        size="large"
        design="primary"
        type="submit"
        loading={isLoading}
        disabled={isLoading}
      >
        Sign in
      </RegisterButton>
      <Back onClick={onBack} />
    </Form>
  )
}

const RegisterButton = styled(Button)`
  width: 100%;
  margin-top: 40px;
`

const ForggotPasswordLink = styled(Link)`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  color: var(--color-gray-700);
  font-weight: 500;
  margin-top: 12px;

  &:hover {
    color: var(--color-gray-500);
  }
`
