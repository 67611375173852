import styled from 'styled-components'
import { Title } from 'ui/atoms'

export const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 56px;
  padding: 40px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  background-color: var(--color-gray-200);
  border-radius: 8px;

  @media (max-width: 767px) {
    margin-top: 16px;
    padding: 24px 20px;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px 16px;
    text-align: left;
  }

  @media (max-width: 599px) {
    margin-bottom: 40px;
  }
`

export const Value = styled(Title)`
  margin-top: 12px;
  word-break: break-word;
`
