import { useWindowSize } from '@uidotdev/usehooks'
import { getEmailTip } from 'libs/emailChecker'
import { path } from 'libs/path'
import { yup } from 'libs/validators'
import { useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Field, Form } from 'ui/atoms'

import { AuthLayout } from '../../layout'
import { useEmailRegisterPage } from './hooks'
import { submitForm } from './model'
import * as Styled from './styled'
import { RegisterByEmail } from './types'

export const RegisterEmail = () => {
  const { width } = useWindowSize()
  const isMobile = width && width < 600

  const inputRef = useRef<HTMLInputElement>(null)

  const { form } = useEmailRegisterPage()
  const { error, loading: isLoading, isEmailInUseError } = form

  // todo: костыль из-за корявого формика 👺
  const [email, setEmail] = useState('')

  const emailTip = getEmailTip(email.split('@')[1] || '')

  return (
    <AuthLayout type="register">
      <Form
        initial={{ email, firstName: '', lastName: '' }}
        onSubmit={(values: RegisterByEmail) =>
          submitForm({
            ...values,
            email: values.email.trim(),
            partnerKey: localStorage.getItem('partnerId') as string,
          })
        }
        validation={yup.email.validate}
        error={error?.form}
      >
        <Field
          name="email"
          label="Email"
          ref={inputRef}
          value={email}
          placeholder="Type your Email"
          autofocus
          error={isEmailInUseError ? '' : error?.fields?.email}
          userError={
            isEmailInUseError ? (
              <Styled.EmailError>
                <Trans i18nKey="This email is already in use. Please, try to sign in or reset the password">
                  This email is already in use. Please, try to{' '}
                  <Link
                    to={path.login.entry()}
                    className="font-medium text-azure.500"
                  >
                    sign in
                  </Link>{' '}
                  or{' '}
                  <Link
                    to={path.register.password.recovery.entry()}
                    className="font-medium text-azure.500"
                  >
                    reset
                  </Link>{' '}
                  the password
                </Trans>
              </Styled.EmailError>
            ) : undefined
          }
          onChange={(event) => setEmail(event.target.value)}
        />
        {emailTip && (
          <Styled.EmailHint>
            <Trans
              i18nKey="Maybe it is @gmail.com"
              values={{ email: `@${emailTip}` }}
              components={[
                <span />,
                <span
                  className="text-bronze.500 ms-[4px] cursor-pointer"
                  onClick={() => {
                    setEmail(`${email.split('@')[0]}@${emailTip}`)

                    inputRef.current?.focus()
                    setTimeout(() => {
                      inputRef.current?.blur() // Затем фокусируем обратно
                    }, 0)
                  }}
                />,
              ]}
            />
          </Styled.EmailHint>
        )}
        <Field
          name="firstName"
          label="First name"
          placeholder="Type your First Name"
          error={error?.fields?.firstName}
        />
        <Field
          name="lastName"
          label="Last name"
          placeholder="Type your Last Name"
          error={error?.fields?.lastName}
        />
        <Styled.RegisterButton
          name="register"
          size={isMobile ? 'medium' : 'large'}
          design="primary"
          type="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          Register
        </Styled.RegisterButton>
      </Form>
      <Styled.BackButton
        to={path.register.entry()}
        size={isMobile ? 'medium' : 'large'}
      />
    </AuthLayout>
  )
}
