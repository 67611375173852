import { toPath } from 'features/router'
import { path } from 'libs/path'
import { Activity as DemoContestActivity } from 'pages/contests/molecules/activity'
import {
  Activity as CopytradeActivity,
  FeatureActivity as CopytradeFeatureActivity,
} from 'pages/copytrade/molecules/activity'
import { GiftshopActivity } from 'pages/giftshop/molecules/activity'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useGetActivities } from 'services/activities/get/useGetActivities'
import { useHideActivity } from 'services/activities/mutation/useHideActivity'
import { ProfileStatus, useGetProfile } from 'services/profile/getProfile'
import styled, { css } from 'styled-components/macro'
import { Icon, Icons, Title } from 'ui/atoms'
import { NovaActivity } from 'ui/molecules'
import {
  EActiviesIcons,
  EActiviesNames,
  EActiviesOnClick,
  EActivitiesVerificationIcons,
  EActivitiesVerificationNames,
} from 'ui/organisms/activities/constants'

export type ActivityProps = {
  icon: Icons
  label: string
  status: 'active' | 'success' | 'error'
  verificationStatus?: ProfileStatus
  isVerificationActivity?: boolean
  closable?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  onClose?: () => void
}

export const Activities = () => {
  const [t] = useTranslation()

  const { data, refetch } = useGetActivities()

  const { data: profileData } = useGetProfile()

  const { mutateAsync } = useHideActivity()

  if (!data || !profileData) {
    return null
  }

  const hideActivity = async (name: string) => {
    await mutateAsync({ name })
    refetch()
  }

  const customActivities = [
    'promoGiftShop',
    'novaWelcome',
    'copytrade',
    'copytradePercentCommission',
    'anniversaryContestUnregistered',
    'anniversaryContestRegistered',
    'anniversaryContestResults',
    'demoContestDemocracy',
    'demoContestTrade2025',
    'profileIsVerified', // всегда идёт первая, если есть
    'profileIsNotVerified', // далее ориентируемся на profileData.verification.status, но по-хорошему переделать
  ]

  const activitiesItems: ActivityProps[] = data.activities
    .filter(
      (activity) =>
        !customActivities.some(
          (customActivity) =>
            activity.name === customActivity ||
            activity.name.toLowerCase().includes('verif'),
        ) && !activity.name.includes('demoContest'),
    )
    .map((item) => {
      return {
        label: t(EActiviesNames[item.name as keyof typeof EActiviesNames]),
        icon: EActiviesIcons[item.name as keyof typeof EActiviesIcons],
        status: 'active',
        closable: item.canBeClosed,
        onClick: EActiviesOnClick[item.name as keyof typeof EActiviesOnClick],
        onClose: () => hideActivity(item.name),
      }
    })

  const hasClosableVerificationActivity = !!data.activities.find(
    (activity) => activity.name === 'profileIsVerified',
  )

  return activitiesItems.length > 0 ? (
    <ActivitiesContainer>
      <Title level={4}>Activities</Title>
      <ActivitiesList>
        {data.activities.find(
          (activity) => activity.name === 'copytradePercentCommission',
        ) && (
          <CopytradeFeatureActivity
            onClose={() => hideActivity('copytradePercentCommission')}
          />
        )}
        {data.activities.find(
          (activity) => activity.name === 'demoContestTrade2025',
        ) && (
          <DemoContestActivity
            onClose={() => hideActivity('demoContestTrade2025')}
          />
        )}
        {data.activities.find((activity) => activity.name === 'copytrade') && (
          <CopytradeActivity onClose={() => hideActivity('copytrade')} />
        )}
        {data.activities.find(
          (activity) => activity.name === 'promoGiftShop',
        ) && <GiftshopActivity onClose={() => hideActivity('promoGiftShop')} />}
        {data.activities.find(
          (activity) => activity.name === 'novaWelcome',
        ) && <NovaActivity onClose={() => hideActivity('novaWelcome')} />}
        {(profileData.verification.status !== 'VERIFIED' ||
          hasClosableVerificationActivity) && (
          <Activity
            label={t(
              EActivitiesVerificationNames[
                (profileData.updatingDocumentRequest
                  ?.status as keyof typeof EActivitiesVerificationNames) ||
                  (profileData.verification
                    .status as keyof typeof EActivitiesVerificationNames)
              ],
            )}
            icon={
              EActivitiesVerificationIcons[
                (profileData.updatingDocumentRequest
                  ?.status as keyof typeof EActivitiesVerificationIcons) ||
                  (profileData.verification
                    .status as keyof typeof EActivitiesVerificationIcons)
              ]
            }
            closable={hasClosableVerificationActivity}
            status="active"
            onClick={() =>
              profileData.verification.status === 'IN_PROGRESS' ||
              profileData.updatingDocumentRequest?.status === 'IN_PROGRESS'
                ? toPath(path.dashboard.verification.progress())
                : profileData.verification.status ===
                    'EXPIRATION_DATE_COMING_UP' ||
                  profileData.verification.status === 'EXPIRED'
                ? toPath(path.dashboard.verification.updateDocs())
                : toPath(path.dashboard.verification.entry())
            }
            onClose={() => hideActivity('profileIsVerified')}
            verificationStatus={profileData.verification.status}
            isVerificationActivity
          />
        )}
        {activitiesItems.map(
          ({ label, icon, status, closable, onClick, onClose }, key) => {
            return (
              <Activity
                key={key}
                label={label}
                icon={icon}
                closable={closable}
                status={status}
                onClick={onClick}
                onClose={onClose}
              />
            )
          },
        )}
      </ActivitiesList>
    </ActivitiesContainer>
  ) : null
}

export const Activity = (props: ActivityProps) => {
  const {
    icon,
    status,
    label,
    closable,
    onClick,
    onClose,
    verificationStatus,
    isVerificationActivity,
  } = props

  return (
    <ActivityContainer onClick={onClick}>
      {closable && (
        <ActivityClose
          onClick={(e) => {
            e.stopPropagation()
            if (onClose) {
              onClose()
            }
          }}
        />
      )}
      <ActivityIconBackground
        data-status={isVerificationActivity ? verificationStatus : status}
      >
        <ActivityIcon name={icon} />
      </ActivityIconBackground>
      <ActivityLabel>{label}</ActivityLabel>
    </ActivityContainer>
  )
}

const ActivitiesContainer = styled.div``

const ActivitiesList = styled.div`
  margin-top: 12px;
  margin-bottom: -20px;
  margin-inline-end: -20px;
  padding-inline-end: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 16px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1175px) {
    width: calc(100vw - 300px);
  }

  @media (max-width: 1050px) {
    margin-inline-start: -20px;
    padding-inline-start: 20px;
    width: 100vw;
  }

  @media (max-width: 599px) {
    margin-inline-start: -8px;
    padding-inline-start: 8px;
  }
`

const ActivityContainer = styled.div`
  position: relative;
  background-color: var(--color-gray-100);
  box-shadow: var(--box-shadow-4);
  border-radius: 8px;
  width: 128px;
  min-height: 128px;
  min-width: 128px;
  padding: 16px;
  transition: var(--transition);

  ${(p) =>
    p.onClick &&
    css`
      cursor: pointer;

      &:hover {
        box-shadow: var(--box-shadow-6);
      }
    `}
`

const ActivityIconBackground = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &[data-status='success'] {
    background-color: var(--color-eucalyptus-100);
  }

  &[data-status='active'] {
    background-color: var(--color-sunray-100);
  }

  &[data-status='error'] {
    background-color: var(--color-opal-100);
  }

  &[data-status='NOT_VERIFIED'] {
    background-color: var(--color-sunray-100);
  }

  &[data-status='IN_PROGRESS'] {
    background-color: var(--color-azure-100);
  }

  &[data-status='VERIFIED'] {
    background-color: var(--color-eucalyptus-100);
  }

  &[data-status='EXPIRED'] {
    background-color: var(--color-sunray-100);
  }

  &[data-status='REJECTED'] {
    background-color: var(--color-opal-100);
  }

  &[data-status='BLOCKED'] {
    background-color: var(--color-opal-100);
  }

  &[data-status='EXPIRATION_DATE_COMING_UP'] {
    background-color: var(--color-sunray-100);
  }
`

const ActivityIcon = styled(Icon)``

const ActivityLabel = styled.div`
  margin-top: 10px;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  font-weight: 500;
`

const ActivityClose = styled.div`
  position: absolute;
  top: 6px;
  inset-inline-end: 6px;
  background-color: var(--color-gray-300);
  width: 24px;
  height: 24px;
  border-radius: 50px;

  &:before {
    content: '✕';
    width: 24px;
    height: 24px;
    text-align: center;
    display: block;
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 500;
    cursor: pointer;
  }
`
