import { useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { DepositForm } from './DepositFormTypes';

export const GET_RECOMMENDED_DEPOSIT_KEY = 'GET_RECOMMENDED_DEPOSIT_KEY';

export interface RecommendedDeposits {
  currencyChips: {
    chip: Record<string, string>;
  }[]
}



const getRecomendedDeposit = async (gatewayID: string) => {
  const res = await axios.get<RecommendedDeposits>(`/recommended-deposit/v1/chips`, {
    params: {
      gatewayID,
    },
  });

  return res.data;
};

export const useGetRecomendedDeposit = (gatewayID: string) => {
  return useQuery({
    queryKey: [GET_RECOMMENDED_DEPOSIT_KEY, gatewayID],
    queryFn: () => getRecomendedDeposit(gatewayID),
    retry: retryFn,
    staleTime: 2000
  })
};
