import { useEffect, useState } from 'react'
import { useCancelCopying } from 'services/copytrade'
import { Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'
import { useWindowSize } from 'usehooks-ts'

import { setStopCopyingAlertVisible } from '../../model'

export const StopCopyingModals = ({
  onClose,
  subscriptionID,
}: {
  onClose: () => void
  subscriptionID: string
}) => {
  const [visibleModalNumber, setVisibleModalNumber] = useState<1 | 2>(1)

  const { mutateAsync, isSuccess, isLoading } = useCancelCopying()

  const handleCancel = (isCloseOpenedPos: boolean) => {
    mutateAsync({ subscriptionID, isCloseOpenedPos })
  }

  useEffect(() => {
    if (isSuccess) {
      setStopCopyingAlertVisible(true)

      setTimeout(() => {
        setStopCopyingAlertVisible(false)
      }, 10000)

      onClose()
    }
  }, [isSuccess])

  return (
    <>
      <Modal
        open={visibleModalNumber === 1}
        okLabel="Stop"
        onOk={() => setVisibleModalNumber(2)}
        okButtonProps={{ design: 'dangerous' }}
        cancelLabel="Cancel"
        onClose={onClose}
        title="Stop copying"
      >
        <Text className="!text-gray.600">
          Are you sure you want to stop copying this strategy?
        </Text>
      </Modal>
      <Modal
        open={visibleModalNumber === 2}
        okLabel="Leave open"
        onOk={() => handleCancel(false)}
        cancelLabel="Close automatically"
        okButtonProps={{ disabled: isLoading }}
        cancelButtonProps={{ design: 'primary', disabled: isLoading }}
        onClose={() => {}}
        onCancel={() => handleCancel(true)}
        closable={false}
        title="Opened orders"
      >
        <Text>What do you want to do with the opened orders?</Text>
      </Modal>
    </>
  )
}
