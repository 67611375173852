import { getStrFromAmount } from "./getStrFromAmount";

export const getSliceFloatNumber = (val: string | number, floatCount = 2) => {
  const withOutFloat = floatCount === 0;

  const valutStr = String(val);
  let parsedValue = getStrFromAmount(valutStr, withOutFloat);

  if (parsedValue[0] === '.' && !withOutFloat) {
    parsedValue = '0.';
  }
  
  const partsArray = parsedValue.split('.');
  const [decimalPart, floatPart] = partsArray;
  if (!floatPart) return decimalPart;

  partsArray[1] = floatPart?.slice(0, floatCount) || '';

  return withOutFloat ? decimalPart : partsArray.join('.');
}