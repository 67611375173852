import React, { useEffect } from 'react';
import { useStore } from 'effector-react';

import { 
  $modalFTDOpen, $modalVerificationOpen, resetModalsEvent, 
  changeFtdModal, changeVerificationModal
} from './modals/modalState';
import { $tableFilterStore, changeFtdEvent, changeVerifyEvent } from './model';
import { TableSheetModal } from './modals/Modal';


const optionsFTD = [
  { label: 'All', value: 'FTD_ALL' },
  { label: 'Yes', value: 'FTD_YES' },
  { label: 'No', value: 'FTD_NO' }
];

const optionsVerification = [
  { label: 'All status', value: 'VERIFICATION_ALL' },
  { label: 'Not verified', value: 'VERIFICATION_NOT_VERIFIED' },
  { label: 'Verified', value: 'VERIFICATION_VERIFIED' }
]

export const TableModals: React.FC = () => {
  const filter = useStore($tableFilterStore);
  const isFtdModal = useStore($modalFTDOpen);
  const isVerifyModal = useStore($modalVerificationOpen);

  useEffect(() => {
    return () => {
      resetModalsEvent();
    }
  }, []);
  

  return (
    <div>
      <TableSheetModal
        onChangeActive={changeFtdModal}
        options={optionsFTD}
        title='FTD'
        onChangeSort={changeFtdEvent}
        isActive={isFtdModal}
        value={filter.ftd}
      />

    <TableSheetModal
        onChangeActive={changeVerificationModal}
        options={optionsVerification}
        title='Verification'
        onChangeSort={changeVerifyEvent}
        isActive={isVerifyModal}
        value={filter.verification}
      />
    </div>
  )
}
