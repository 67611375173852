import { format } from 'date-fns'
import { useStore, useUnit } from 'effector-react'
import { $wallets } from 'pages/dashboard/widgets/wallets/model'
import React, { useMemo } from 'react'
import { Operation } from 'services/transaction-history/types'

import { $accounts } from '../model'
import {
  $page,
  $perPage,
  resetFilters,
  setPage,
  setPerPage,
  setStatuses,
  setTimeline,
  setTransactionsType,
  setAccountIDs,
  setWalletIDs,
  transactionsMounted,
  $transactionsType,
  $timeline,
  $statuses,
  $walletIDs,
  $accountIDs,
} from './model'
import { useGetMoneyHistory } from 'services/money/get'
import { SortHistoryForDateType, sortHistoryForDate } from 'pages/money/transactionsNew/utils/sortHistoryForDate'
import { accountsListMounted } from '../list/model'

export const useTransactions = () => {
  const accounts = useStore($accounts)
  const wallets = useStore($wallets);

  const transactionsType = useUnit($transactionsType);
  const timeline = useUnit($timeline);
  const statuses = useUnit($statuses);
  const walletIDs = useUnit($walletIDs);
  const accountIDs = useUnit($accountIDs);

  const page = useStore($page)
  const perPage = useStore($perPage)

  const filterMemo = useMemo(() => ({ 
    transactionType: transactionsType,
    timeline,
    statuses: statuses,
    walletIDs,
    accountIDs,
    isDefaultFilter: false,
  }), [transactionsType, statuses, timeline, walletIDs, accountIDs]);

  const { data } = useGetMoneyHistory<SortHistoryForDateType>({
    // @ts-ignore
    filter: filterMemo, 
    page, perPage, onSelect: sortHistoryForDate
  });

  const handlePaginationChange = (page: number, perPage: number) => {
    setPage(page)
    setPerPage(perPage)
  }

  const handleChangeStatus = (status: string) => {
    if (status === 'all') {
      setStatuses([
        'OS_PENDING',
        'OS_MISSING_INFORMATION',
        'OS_SUCCESS',
        'OS_REJECTED',
      ])
    } else {
      setStatuses([status])
    }
  }

  React.useEffect(() => {
    transactionsMounted()
    // accountsListMounted()
  }, [])

  return {
    accounts,
    wallets,
    transactions: data?.items || [],
    setTransactionsType,
    setTimeline,
    setStatus: handleChangeStatus,
    setAccounts: setAccountIDs,
    setWallets: setWalletIDs,
    page,
    perPage,
    total: Number(data?.totalRecords),
    handlePaginationChange,
    resetFilters,
  }
}
