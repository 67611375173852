import { createEvent, restore } from 'effector'

export const transactionsMounted = createEvent()

export const resetFilters = createEvent()

export const setTransactionsType = createEvent<string>()
export const $transactionsType = restore(setTransactionsType, 'all').reset(
  resetFilters,
)

export const setTimeline = createEvent<{ from: string; to: string }>()
export const $timeline = restore(setTimeline, {
  from: '0',
  to: new Date().getTime().toString(),
}).reset(resetFilters)

export const setStatuses = createEvent<string[]>()
export const $statuses = restore(setStatuses, []).reset(resetFilters)

export const setAccountIDs = createEvent<string[]>()
export const $accountIDs = restore(setAccountIDs, []).reset(resetFilters)

export const setWalletIDs = createEvent<string[]>()
export const $walletIDs = restore(setWalletIDs, []).reset(resetFilters)

export const setPage = createEvent<number>()
export const $page = restore<number>(setPage, 1)

export const setPerPage = createEvent<number>()
export const $perPage = restore<number>(setPerPage, 20)

