import styled from 'styled-components/macro'

export const Icon = styled.svg`
  cursor: pointer;
  
  path {
    stroke: var(--color-gray-500);
    transition: stroke var(--transition);
  }

  &:hover path {
    stroke: var(--color-gray-700);
  }
`
