import * as React from 'react'

type Props = {
  value: number
  currency?: string
  type?: 'symbol'
  minimumFractionDigits?: number
}

export const Money = ({
  value,
  currency,
  type,
  minimumFractionDigits = 2,
}: Props) => {
  const formatedValue = toCurrency(
    value,
    currency || 'USD',
    minimumFractionDigits,
  )

  if (type === 'symbol') return <>{formatedValue.replace(/[0-9, .]/g, '')}</>
  return <>{formatedValue}</>
}

export function toCurrency(
  value: number = 0,
  currency: string,
  minimumFractionDigits?: number,
) {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits,
    }).format(value)
  } catch (error) {
    return `${currency} ${value}`
  }
}
