import { AccountPlatformName, AccountType, AccountTypeClient } from './types'

export const mapAccountType: Record<AccountTypeClient, AccountType> = {
  real: 'PAAT_REAL',
  demo: 'PAAT_DEMO',
  invalid: 'PAAT_INVALID',
}

export const mapAccountPlatform: Record<AccountPlatformName, string> = {
  PAAP_MT5: 'MT5',
  PAAP_MT4: 'MT4',
  PAAP_INVALID: '',
}
