import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'

import { InternalTransferProps } from '../types'

const postInternalTransfer = async (params: InternalTransferProps) => {
  const res = await axios.post<{ pendingApprove?: boolean }>(
    '/internal-transfers/v1/internal-transfer',
    params,
  )

  return res.data
}

export const usePostInternalTransfer = () => {
  return useMutation({
    mutationFn: postInternalTransfer,
  })
}
