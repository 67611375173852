import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useUnit } from 'effector-react';

import { $isLoadingFiles } from 'ui/molecules/UploadFiles';
import { useGetCardsList } from 'services/payments/get/useGetCardsList';
import { usePostVerifyCard } from 'services/payments/mutations/usePostVerifyCard';
import { WithdrawalCardItem } from 'services/payments/get/types';
import { Button, Title } from 'ui/atoms';
import { ServerFile } from 'ui/molecules';
import { isEmpty } from 'libs/isEmpty';

import { AllVerifiedCards } from './AllVerifiedCards';
import { CardWrapper } from './CardWrapper';
import { UploadFieldsWrapp } from './UploadFieldsWrapp';
import { StatusesList } from './StatusesList';

import * as S from './styled';


interface FormModel {
  cardItem: WithdrawalCardItem;
  fileNames: ServerFile[];
}

export const VerifyCardsTab = () => {
  const { data, isLoading } = useGetCardsList();

  const { mutateAsync } = usePostVerifyCard();
  const isLoadingFiles = useUnit($isLoadingFiles);

  const [isPendingVerify, setIsPendingVerify] = useState(false);

  const methods = useForm<FormModel>({
    defaultValues: {
      cardItem: {},
      fileNames: [],
    },
    mode: 'onTouched',
    shouldUnregister: false,
  });
  
  const formValWatch =  methods.watch();

  const unverifiedCard = useMemo(() => 
    data?.bankCardInfo.filter(card => card.status === 'unverified' || card.status === 'rejected') || [],
    [data]
  );

  const isAllVerified = (data?.bankCardInfo?.length || 0) > 0 && unverifiedCard.length === 0;

  const isButtonDisabled = isEmpty(formValWatch.cardItem) || (!formValWatch.fileNames?.length || formValWatch.fileNames.length < 2) || isLoadingFiles;

  const sbmVerifyCard = async (fields: FormModel ) => {
    try {
      await mutateAsync({
        cardID: fields.cardItem?.id as string,
        fileNames: fields.fileNames?.map(file => file.fileName),
      });
      setIsPendingVerify(true);
    } catch (error) {
      
    }
  }

  if (isPendingVerify) return (<S.Wrapper><AllVerifiedCards status='pending' /></S.Wrapper>);

  return (
    <div className='h-fit'>
      <S.Wrapper>
        {isAllVerified 
          ? <AllVerifiedCards status='success' /> 
          : (
          <FormProvider {...methods}>
            <Title level={2} className='mb-[24px]'>Bank card verification</Title>
            <CardWrapper cardsLIst={unverifiedCard} />
            
            <UploadFieldsWrapp control={methods.control}/>

            <Button
              design='primary'
              name='verifyCardSbm'
              className='!w-[24rem] mt-[64px]'
              disabled={ isButtonDisabled }
              onClick={methods.handleSubmit(sbmVerifyCard)}
            >
              Verify
            </Button>
          </FormProvider>
        )}
      </S.Wrapper>

      <StatusesList/>
    </div>
  )
}
