import { useWindowSize } from '@uidotdev/usehooks'
import { generatePass } from 'libs/generatePass'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../layout'
import { usePasswordPage } from './hooks'
import { setUserPassword, submitForm } from './model'
import * as Styled from './styled'

export const RegisterPassword = () => {
  const { width } = useWindowSize()
  const isMobile = width && width < 600

  const [t] = useTranslation()

  const [isGenerating, setIsGenerating] = useState(false)
  const [isGeneratedPassword, setIsGeneratedPassword] = useState(false)
  const [isPasswordVisible, setIsPasswordVisible] = useState(true)

  const { userPassword, form } = usePasswordPage()
  const croppedPassword = userPassword.trim()

  const handleType = (event: ChangeEvent<HTMLInputElement>) => {
    setIsGeneratedPassword(false)

    setUserPassword(event.target.value)
  }

  const handleGenerate = (oldPassword: string) => {
    setIsGeneratedPassword(true)
    setIsPasswordVisible(true)

    const generatedPassword = generatePass()

    // todo: если понадобится, вынести это добро в хук 👀
    const maxLength = Math.max(generatedPassword.length, userPassword.length)

    let step = 0
    setIsGenerating(true)

    const intervalId = setInterval(() => {
      if (step <= maxLength) {
        let newPassword = ''

        for (let i = 0; i < maxLength; i++) {
          if (i < step) {
            newPassword +=
              i < generatedPassword.length ? generatedPassword[i] : ''
          } else if (i < oldPassword.length) {
            newPassword += oldPassword[i]
          } else {
            newPassword += ' '
          }
        }

        setUserPassword(newPassword)

        step++
      } else {
        clearInterval(intervalId)
        setIsGenerating(false)
      }
    }, 24)
  }

  const hasOnlyLatin = /^[\x00-\x7F]*$/.test(croppedPassword)
  const isLengthValid = croppedPassword.length >= 8
  const hasUpperAndLower = /(?=.*?[A-Z])(?=.*?[a-z])/.test(userPassword)
  const hasNumbers = /(?=.*\d)/.test(userPassword)
  const areSymbolsValid = /^[a-zA-Z0-9!@$%_#()]*$/.test(croppedPassword)

  const isPasswordValid =
    areSymbolsValid &&
    hasOnlyLatin &&
    isLengthValid &&
    hasUpperAndLower &&
    hasNumbers

  return (
    <AuthLayout type="register">
      <AuthSectionTitle level={3}>Create password</AuthSectionTitle>
      <AuthSectionText level={2}>
        Create a password or use our password generator to protect your account
      </AuthSectionText>
      <Styled.PasswordGenerator>
        <Styled.Input
          placeholder="New password"
          type="password"
          showPassword={isPasswordVisible}
          onShowPasswordChange={() => setIsPasswordVisible(!isPasswordVisible)}
          value={userPassword}
          onChange={handleType}
          maxLength={32}
          errorMessage={
            form.error?.fields?.newPassword ||
            (!areSymbolsValid && !isGenerating
              ? 'Password must contain only Latin letters, numbers and ! @ $ % _ # ( )'
              : '')
          }
        />
        <Styled.PasswordCopyButton
          design="secondary"
          disabled={isGenerating}
          copiedText={userPassword}
        />
      </Styled.PasswordGenerator>
      <div
        className="cursor-pointer inline-block py-[10px] text-body.medium leading-[16px] font-medium text-bronze.500"
        onClick={() => handleGenerate(userPassword)}
      >
        {t('Generate random password')}
      </div>
      {userPassword.length > 0 && !isGeneratedPassword && (
        <div className="mt-[8px] grid gap-[4px]">
          <Styled.RuleWrapper isValid={hasOnlyLatin}>
            {t('Only latin characters')}
          </Styled.RuleWrapper>
          <Styled.RuleWrapper isValid={isLengthValid}>
            {t('At least 8 characters')}
          </Styled.RuleWrapper>
          <Styled.RuleWrapper isValid={hasUpperAndLower}>
            {t('At least 1 lowercase & uppercase letter')}
          </Styled.RuleWrapper>
          <Styled.RuleWrapper isValid={hasNumbers}>
            {t('At least 1 number (0-9)')}
          </Styled.RuleWrapper>
        </div>
      )}
      <Styled.ProceedButton
        loading={form.loading}
        disabled={form.loading || !isPasswordValid || isGenerating}
        onClick={() => submitForm()}
        size={isMobile ? 'medium' : 'large'}
      >
        Proceed
      </Styled.ProceedButton>
    </AuthLayout>
  )
}
