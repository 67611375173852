import { validateNumberMax } from 'libs/validators'
import React, { useState } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useWithdrawalPartner } from 'services/ib/mutation'
import { Text } from 'ui/atoms'
import { FormInput } from 'ui/molecules'
import { Modal } from 'ui/molecules/modal_draft'

interface Props {
  maxAmount: number
  isOpen: boolean
  onClose: () => void
}

type FormModelType = {
  amount: string
}

export const WithdrawalModal: React.FC<Props> = ({
  isOpen,
  onClose,
  maxAmount,
}) => {
  const { mutateAsync, isLoading, isError } = useWithdrawalPartner()

  const [isSuccess, setIsSuccess] = useState(false)

  const { handleSubmit, control, reset, setError } = useForm<FormModelType>({
    defaultValues: {
      amount: '',
    },
    mode: 'onTouched',
  })

  const { field: amountField, fieldState: amountFieldState } = useController({
    name: 'amount',
    defaultValue: '',
    rules: {
      required: 'Required field',
      validate: (val) =>
        validateNumberMax(val, maxAmount) ||
        'Limit exceeded. Please enter the maximum amount.',
    },
    control,
  })

  const onCloseModal = () => {
    if (isLoading) return
    reset()
    onClose()
  }

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(/[^.\d]/g, '')
    amountField.onChange(val)
  }

  const setMaxAmount = () => {
    amountField.onChange(maxAmount.toString())
  }

  const onSbm = async (fields: FormModelType) => {
    try {
      await mutateAsync(fields.amount)
      setIsSuccess(true)
    } catch (error) {
      setError('amount', {
        message: 'Tech error. Try again later',
        type: 'techError',
      })
    }
  }

  const onOkayFn = () => {
    onCloseModal()
    setIsSuccess(false)
  }

  if (isSuccess) {
    return (
      <Modal
        open={isOpen}
        onClose={onOkayFn}
        title="Transfer complete"
        icon="success"
      >
        <Text className="!text-gray.600 text-center">
          You have successfully transferred money to your USD wallet.
        </Text>
      </Modal>
    )
  }

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      onOk={handleSubmit(onSbm)}
      title="Withdraw to USD wallet"
      okButtonProps={{
        disabled: isLoading || !amountField.value,
        loading: isLoading,
      }}
      okLabel="Withdraw"
    >
      <FormInput
        className="!max-w-[46rem] mt-[2rem] text-[16px]"
        value={amountField.value}
        label="Amount"
        placeholder="Amount"
        disabled={isLoading}
        autoComplete="off"
        onChange={onChangeAmount}
        suffixType="button"
        suffix={
          <span
            className="text-sunray.500 cursor-pointer"
            onClick={setMaxAmount}
          >
            Max
          </span>
        }
        ref={amountField.ref}
        errorMessage={amountFieldState.error?.message}
      />
    </Modal>
  )
}
