import { createStore, createEvent } from "effector";

export interface FilterStore {
  timeframePeriod?: {
    from: string;
    to: string;
  }
  verification?: string;
  ftd?: string;
  login?: string | undefined;
}

export const changePeriodEvent = createEvent<FilterStore['timeframePeriod']>();
export const changeLoginEvent = createEvent<string>();
export const changeFtdEvent = createEvent<string>();
export const changeVerifyEvent = createEvent<string>();
export const filterResetEvent = createEvent();

export const $tableFilterStore = createStore<FilterStore>({
  timeframePeriod: {
    from: '',
    to: '',
  },
  ftd: '',
  verification: '',
  login: '',
});

$tableFilterStore.on(changePeriodEvent, (state, period) => ({
  ...state,
  timeframePeriod: period,
}));

$tableFilterStore.on(changeFtdEvent, (state, ftd) => ({
  ...state,
  ftd,
}));

$tableFilterStore.on(changeVerifyEvent, (state, verification) => ({
  ...state,
  verification,
}));

$tableFilterStore.on(changeLoginEvent, (state, login) => ({
  ...state,
  login,
}));

$tableFilterStore.reset(filterResetEvent);

export interface SortObjParams {
  name: string;
  direction: 'asc' | 'desc';
}

export const changeSortEvent = createEvent<SortObjParams>();
export const sortResetEvent = createEvent();

export const $tableSortStore = createStore<SortObjParams | null>(null);

$tableSortStore.on(changeSortEvent, (state, newSort) => newSort);
$tableSortStore.reset(sortResetEvent);