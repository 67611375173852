import styled, { css } from 'styled-components/macro'
import { Button } from 'ui/atoms'

export const ContentContainer = styled.div`
  display: grid;
  gap: 24px;

  @media (max-width: 599px) {
    gap: 20px;
  }
`

export const BlockWrapper = styled.div`
  border: 1px solid var(--color-gray-300);
  padding: 20px;
  border-radius: 8px;

  @media (max-width: 599px) {
    padding: 12px;
  }
`

export const TopInfoWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  @media (max-width: 1190px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  @media (max-width: 599px) {
    gap: 20px;
  }
`

export const BottomInfoWrapper = styled.div`
  margin-top: 8px;
  padding-top: 16px;
  padding-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 64px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1190px) {
    padding-bottom: 0;
    display: grid;
    justify-items: start;
    gap: 32px;
  }

  @media (max-width: 599px) {
    margin-top: 16px;
    padding-top: 0;
    justify-content: initial;
    gap: 20px;
  }
`

export const TopValuesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 48px;
  align-items: center;

  @media (max-width: 599px) {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    justify-items: start;
  }
`

export const BottomValuesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 54px;
  align-items: start;

  @media (max-width: 1190px) {
    grid-template-columns: repeat(3, auto);
    gap: 16px 24px;
  }
`

export const TopInfoTitle = styled.div`
  margin-bottom: 4px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-small);
  font-weight: 500;
  color: var(--color-gray-600);

  @media (max-width: 599px) {
    font-size: var(--body-font-size-small);
  }
`

export const TopInfoValue = styled.div<{
  isLarge?: boolean
  isLong?: boolean
  color?: string
  icon?: string
}>`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
  font-weight: 500;
  color: ${({ color }) => color || 'var(--color-neutral)'};

  @media (max-width: 599px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }

  ${({ isLarge }) =>
    isLarge &&
    css`
      font-size: 20px;
      line-height: 25px;

      @media (max-width: 599px) {
        font-size: var(--body-font-size-medium);
        line-height: var(--body-line-height-small);
      }
    `}

  ${({ isLong }) =>
    isLong &&
    css`
      @media (max-width: 599px) {
        font-size: var(--body-font-size-small);
        line-height: var(--body-line-height-small);
      }
    `}

  ${({ icon, theme }) =>
    icon &&
    css`
      padding-inline-start: 25px;
      padding-inline-end: 0;
      background-image: url(${icon});
      background-repeat: no-repeat;
      background-size: 20px auto;
      background-position: ${theme.dir === 'rtl'
        ? 'right center'
        : 'left center'};

      @media (max-width: 599px) {
        padding-inline-start: 20px;
        background-size: 16px auto;
      }
    `}
`

export const StartCopyingButton = styled(Button)`
  min-width: 208px;

  @media (max-width: 599px) {
    width: 100%;
  }
`

export const StopCopyingButton = styled(Button)`
  min-width: 208px;

  @media (max-width: 1199px) {
    margin-top: 24px;
  }

  @media (max-width: 599px) {
    margin-top: 16px;
    width: 100%;
  }
`

export const PlatformTag = styled.div`
  padding: 0 4px;
  display: inline-block;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  font-weight: 500;
  border-radius: 4px;
  background-color: var(--color-gray-300);
`

export const CopyingGrid = styled.div`
  margin-top: 32px;
  display: grid;
  gap: 24px;

  @media (max-width: 599px) {
    margin-top: 20px;
  }
`

export const CopyingInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

export const CopyingInfoValues = styled.div`
  display: grid;
  grid-template-columns: 248px 72px auto auto;
  gap: 64px;

  @media (max-width: 900px) {
    width: 100%;
    grid-template-columns: 208px auto;
    gap: 16px 24px;
  }
`

export const EditProportionWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const EditButton = styled.button`
  flex-shrink: 0;
  margin-inline-start: 12px;
  font: inherit;
  font-size: var(--body-font-size-medium);
  line-height: 16px;
  font-weight: 500;
  color: var(--color-bronze-500);
`

export const AccountName = styled.div`
  margin-inline-end: 8px;
  font-size: var(--body-font-size-regular);
  line-height: 20px;

  @media (max-width: 599px) {
    font-size: var(--body-font-size-medium);
    line-height: var(--body-line-height-medium);
  }
`

export const AccountTariff = styled.div`
  margin-inline-start: 8px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
`
