import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'effector-react';

import { Alerts, ServerFile, UploadFiles } from 'ui/molecules';
import { DefaultUploadPlace } from 'ui/molecules/UploadFiles/upload/component';

import { Title, Text } from 'ui/atoms';
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { $uploadFiles, deletUploadFile, setUploadFile } from '../../atoms/upload/model';
import { $userProfile } from 'features/user';
import { $verification } from '../../model';
import { $country } from './model';

export const UploadFilesWrapp: React.FC = () => {
  const [t] = useTranslation();
  const isMobile = useIsMobile();

  const files = useStore($uploadFiles);
  const user = useStore($userProfile)
  const verification = useStore($verification)
  const currentCountry = useStore($country);

  const isHaveAllert = (currentCountry || (verification?.country || user.country)) === 'NGA';

  const onChangeFiles = (files: ServerFile) => {
    setUploadFile(files);
  }

  const onDeleteFile = (_: number, id: string) => {
    deletUploadFile(id);
  }

  const defaultUploadPlace = useMemo((): DefaultUploadPlace[] => ([
    { title: t('Upload a document') as string, icon: 'cloudArrowUp' as const, id: '1_doc_req' }, 
    { title: t('Upload a document') as string, icon: 'cloudArrowUp' as const, id: '2_doc_req' }
  ]), [t]);
    
  return (
    <>
      <UploadFiles
        uploadUrl='/user-files/v1/verification'
        title={(
          <>
            <Title level={isMobile ? 4 : 3}>
              Upload a document
            </Title>
            { isHaveAllert && 
            <Alerts type='info' className='lg:max-w-[472px] w-full my-[4px]'>
              {t('verification.additionalDoc.alert', {documentName: 'NIN'})}
            </Alerts>
          }
          </>
          )
        }
        onChangeFiles={onChangeFiles}
        onDeleteFile={onDeleteFile}
        maxSize='10'
        defaultUploadPlace={defaultUploadPlace}
        limit={5}
        files={files}
        subTitle={
          <Text level={isMobile ? 2 : 1}>
            National ID (front and back side) or Local Passport (two-page opening with a photo)
          </Text>
        }
      />
    </>
  )
}
