import { createEvent, restore, sample } from 'effector'

import { fx } from '../../../libs'
import { PatchAccountNameParams } from '../list'
import { getAccount } from '../model'
import { Account, AccountResponse } from '../types'

export const mounted = createEvent()
export const unmounted = createEvent()

export const setNameSubmitted = createEvent<boolean>()
export const $nameSubmitted = restore(setNameSubmitted, false)

export const setNewName = createEvent<string>()

export const $newName = restore<string>(setNewName, '')
export const $newNameSliced = $newName.map((v) => v.slice(0, 16))

// submit rename

export const submitRename = createEvent<Pick<Account, 'accountID'>>()

export const patchAccountName = fx<PatchAccountNameParams, AccountResponse>({
  method: 'PATCH',
  url: `/account/v1/account/name`,
})

sample({
  clock: submitRename,
  source: $newNameSliced,
  filter: (newAccountName) => {
    return Boolean(newAccountName.length)
  },
  fn: (newAccountName, { accountID }) => {
    return {
      body: {
        accountID,
        accountName: newAccountName,
      },
    }
  },
  target: patchAccountName,
})

// clear field on success

sample({
  clock: patchAccountName.done,
  fn: (params) => {
    return {
      query: {
        accountID: params.params.body?.accountID || '',
      },
    }
  },
  target: [getAccount, setNewName.prepend(() => '')],
})

sample({
  clock: patchAccountName.done,
  fn: () => true,
  target: setNameSubmitted,
})

// clear field on mount/unmount

sample({
  clock: [mounted, unmounted],
  fn: () => '',
  target: $newName,
})
