import { useMutation } from '@tanstack/react-query'
import qs from 'qs'
import { axios } from 'services/axios'

const register = async (contestName: string, team: string) => {
  await axios.post<{}>(
    '/demo-contests/v1/contests/register',
    {
      contestName,
      team,
    },
    {
      paramsSerializer: {
        serialize: (params) =>
          qs.stringify(params, {
            encode: false,
            skipNulls: true,
            filter: (_, value) => (value === '' ? undefined : value),
          }),
      },
    },
  )
}

export const useRegister = (contestName: string) =>
  useMutation({
    mutationFn: (team: string) => register(contestName, team),
  })
