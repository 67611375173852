import React, { useState } from 'react';
import { useStore } from 'effector-react';
import { useDebounce } from 'usehooks-ts';

import {
  $amount,
  $destination,
  $destinations,
  $exchangeError,
  $exchangeValues,
  $source,
  $sources,
  destinationSelected,
  getHolderType,
  resetTransfer,
  setAmount,
  sourceSelected,
  transfersMounted
} from './model'
import { usePostInternalTransfer } from 'services/internal-transfers/mutations/usePostInternalTransfer';

export const useTransferWidget = () => {
  const [amountValue, setAmountValue] = React.useState('')
  const debouncedAmount = useDebounce(amountValue, 400)

  const [isTransferCompleted, setIsTransferCompleted] = useState(false);

  const sources = useStore($sources)
  const destinations = useStore($destinations)

  const source = useStore($source)
  const destination = useStore($destination)
  const amount = useStore($amount)
  const calculatedAmount = useStore($exchangeValues)?.calculatedAmount
  const exchangeRate = useStore($exchangeValues)?.exchangeRate
  const exchangeError = useStore($exchangeError)

  // const isTransferCompleted = useStore($isTransferCompleted);

  const { mutateAsync: postInternalTransfer, isLoading, data } = usePostInternalTransfer();

  const onSbm = async () => {
    if (!source?.id || !destination?.id) return;

    try {
      const resp = await postInternalTransfer({
        srcID: source.id,
        srcIDType: getHolderType(source.type),
        dstID: destination.id,
        dstIDType: getHolderType(destination.type),
        amount,
      });

      setIsTransferCompleted(true);
    } catch (error) {
      
    }
  }

  const onResetTransfer = () => {
    setIsTransferCompleted(false);
    resetTransfer();
  }

  React.useEffect(() => {
    transfersMounted();
    return () => {
      resetTransfer();
    }
  }, []);

  React.useEffect(() => {
    setAmount(debouncedAmount)
  }, [debouncedAmount])

  React.useEffect(() => {
    if (!amount) {
      setAmountValue('')
    }
  }, [amount])

  return {
    amount: amountValue,
    setAmount: setAmountValue,
    calculatedAmount,
    exchangeRate,
    submit: onSbm,
    source,
    destination,
    sources,
    destinations,
    setSource: sourceSelected,
    setDestination: destinationSelected,
    resetTransfer: onResetTransfer,
    isTransferCompleted,
    isLoading,
    pendingApprove: data?.pendingApprove,
    errors: {
      amount: exchangeError,
    },
  }
}
