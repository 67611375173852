import styled, { css } from 'styled-components/macro'
import { Title } from 'ui/atoms'

import cross from './cross-icon.svg'

export const ContentWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
  padding: 20px 16px 32px;
  transform: translateY(100%);
  transition: transform 0.3s;
  border-radius: 8px 8px 0 0;
  background-color: var(--color-gray-100);
  box-shadow: 0px -8px 12px 0px rgba(38, 33, 25, 0.16),
    0px 0px 3px 0px rgba(38, 33, 25, 0.16);
`

export const ListWrap = styled.div<{ hideScroll?: boolean }>`
  ${p => {
    if (!p.hideScroll) return css `overflow-y: scroll;`;
    return;
  }}
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.25s;
  background-color: rgba(38, 33, 25, 0.25);
  backdrop-filter: blur(1px);
`

export const FullContainer = styled.div<{ isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-1000%);
  width: 100%;
  height: 100%;
  z-index: 1001;

  ${({ isVisible }) =>
    isVisible &&
    css`
      transform: translateX(0);

      ${Background} {
        opacity: 1;
      }

      ${ContentWrapper} {
        transform: translateY(0);
      }
    `}
`

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-image: url(${cross});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`

export const ContentTitle = styled(Title)`
  margin-top: 16px;
  margin-bottom: 24px;
  text-align: center;
`
