import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useOnClickOutside } from 'usehooks-ts'

import { Icon2 } from '../icon2'
import { Input, InputProps, InputState } from '../input'
import { useIsMobile } from 'libs/hooks/useIsMobile'
import { BottomSheet } from 'ui/molecules'

export type SelectProps = {
  className?: string
  state?: InputState
  value?: string
  size?: 'small' | 'medium' | 'large' | 'base';
  options?: {
    value: string
    label: string
    hidden?: boolean
  }[]
  onSelect?: (value: string) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  customContent?: React.ReactNode
  showOptions?: boolean
  errorMessage?: string;
  inputClasses?: string;
  modalTitle: string;
  maxHeight?: number
  listClassName?: string;
  optionsWidth?: number
} & Pick<InputProps, 'name' | 'label' | 'placeholder' | 'disabled' | 'autoComplete' | 'suffix' | 'prefix' | 'inputMode'>

export const SelectWithModal = ({
  name = 'default',
  className,
  options = [],
  modalTitle,
  value,
  label,
  state = 'hint',
  placeholder,
  disabled,
  size = 'medium',
  onSelect,
  onBlur,
  errorMessage,
  customContent,
  listClassName,
  showOptions = true,
  maxHeight = 260,
  optionsWidth,
  suffix,
  inputClasses,
  ...rest
}: SelectProps) => {
  const [t] = useTranslation()

  const ref = React.useRef(null);

  React.useEffect(() => {
    setActiveValue(value)
    setDropdownVisibility(false)
  }, [value]);

  const isMobile = useIsMobile();

  const [activeValue, setActiveValue] = React.useState(value)
  const [isDropdownVisible, setDropdownVisibility] = React.useState(false)

  useOnClickOutside(ref, () => {
    if (isMobile) return;
    setDropdownVisibility(false)
  });
  
  const activeLabel =
    options.find((v) => {
      return v.value === activeValue
    })?.label || '';

  return (
    <>
    <SelectContainer className={className} ref={ref}>
      <SearchInput
        state={state}
        onClick={() => setDropdownVisibility(!isDropdownVisible)}
        onBlur={onBlur}
        errorMessage={ errorMessage }
        inputMode='none'
        readOnly
        name={name}
        label={label}
        value={activeLabel}
        placeholder={placeholder}
        disabled={disabled}
        inputClasses={`${inputClasses} !pe-[36px]`}
        suffix={
          <>
            {suffix}
            <Shevron data-disabled={disabled} className='ms-3'>
              {isDropdownVisible ? (
                <Icon2 size={size} name="chevronUp" />
              ) : (
                <Icon2 size={size} name="chevronDown" />
              )}
            </Shevron>
          </>
        }
        {...rest}
      />

      <OptionContainer data-visible={!isMobile && (isDropdownVisible)}>
        <OptionList style={{ maxHeight, width: optionsWidth }} className={ listClassName }>
          {showOptions &&
            options.map(({ value, label, hidden }, key) => (
              <Option
                key={key}
                onClick={() => {
                  setActiveValue(value)
                  setDropdownVisibility(false)
                  onSelect && onSelect(value)
                }}
                hidden={hidden}
              >
                {t(label)}
              </Option>
            ))}
          {customContent}
        </OptionList>
      </OptionContainer>

    </SelectContainer>

    <BottomSheet
      isOpen={isMobile && isDropdownVisible}
      title={modalTitle}
      contentClasses='!max-h-[85vh]'
      onClose={() => {
        setDropdownVisibility(false);
      }}
      >
        { 
          showOptions &&
            options.map(({ value, label, hidden }, key) => (
              <Option
                key={key}
                onClick={() => {
                  setActiveValue(value)
                  setDropdownVisibility(false)
                  onSelect && onSelect(value)
                }}
                hidden={hidden}
              >
                {t(label)}
              </Option>
            ))
         }
        { customContent }
      </BottomSheet>
    </>
  )
}

const SearchInput = styled(Input)`
  input {
    cursor: pointer;
  }
`

const SelectContainer = styled.div``

const OptionContainer = styled.div`
  position: relative;
  top: 8px;
  transition: var(--transition);

  &[data-visible='false'] {
    opacity: 0;
    z-index: -1;
  }

  &[data-visible='true'] {
    opacity: 1;
    z-index: 100;
  }
`

const OptionList = styled.div`
  padding: 8px 0;
  left: 0;
  right: 0;
  position: absolute;
  background-color: #fff;
  box-shadow: var(--box-shadow-5);
  border-radius: 8px;
  min-height: 52px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Option = styled.div`
  padding: 9px 16px;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-200);
  }
`

const Shevron = styled.div`
  &[data-disabled='true'] {
    opacity: 0.25;
  }
`
