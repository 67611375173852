import React from 'react';

import { TransactionList } from './components/List';
import { Filters } from './components/filters';
import { useResetStore } from './utils/useResetStore';

import { Title } from 'ui/atoms';

import * as S from './styled';

export const MoneyHistoryList = () => {
  useResetStore();
  
  return (
    <S.OuterWrapper>
    <Title level={2} className='mb-[8px]'>
      Transaction History
    </Title>
    <Filters />
    <TransactionList />
    
  </S.OuterWrapper>
  )
}
