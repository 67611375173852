import { useEffect, useMemo, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ZendeskAPI } from 'react-zendesk';
import { useStore, useStoreMap } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import {
  $accountDeposit,
  accountChooseReset,
} from 'pages/money/depositChoice/model'
import { AccountSelect } from 'pages/money/molecules/newAccountSelect'
import { TransferFeatures } from 'pages/money/molecules/transferFeatures'
import { SourceItem } from 'services/payments/get/types'
import { useGetSourceList } from 'services/payments/get/useGetSourceList'
import { Text, Title } from 'ui/atoms'
import { Alerts } from 'ui/molecules'
import { $withdrawalPageStore } from '../../model';


interface Props {
  onSelectSource: (gateway: SourceItem) => void
}

export const GatewayListHead: React.FC<Props> = ({ onSelectSource }) => {
  const { data: destinationsData, isLoading } = useGetSourceList('withdrawal')

  const [t] = useTranslation();

  let [searchParams, setSearchParams] = useSearchParams();
  const withdrawalPageStore = useStore($withdrawalPageStore);

  const navigate = useNavigate()
  const isInitalValueAcc = useRef(true)
  const accountDepositStore = useStore($accountDeposit)
  const isVerified = useStoreMap($userProfile, (user) => user?.isVerified)

  const isHaveValidSources = useMemo(() => {
    if (!destinationsData?.length || destinationsData.length === 0) return false

    return destinationsData?.some((dst) => {
      if (dst.balance !== '0') {
        return true
      }

      return false
    })
  }, [destinationsData])

  const onHandleContactSupport = () => {
    ZendeskAPI('messenger', 'open')
  };

  // сетинг дефолтного значения
  useEffect(() => {
    if (!destinationsData || !accountDepositStore) return
    if (!isInitalValueAcc.current) return

    const finded = destinationsData.find(
      (item) => item.id === accountDepositStore,
    )

    if (finded) {
      onSelectSource(finded);
    }
    
    return;
  }, [destinationsData])  

  if (isLoading) return null

  if (!isVerified) return (
    <div className="mb-[2rem] max-w-[48rem]">
      <Alerts type="warning" title="">
        <Text className="inline">
          Verify your account now to withdraw funds.
        </Text>
        <Text
          className="!cursor-pointer !text-orange-500 !font-medium ms-[4px] inline"
          onClick={() => navigate(path.dashboard.verification.entry())}
        >
          Verify now
        </Text>
      </Alerts>
    </div>
  )
  
  if (!isHaveValidSources && destinationsData) return (
    <div className='mb-[2rem] w-fit'>
      <Alerts
        type='warning'
        title=''
      >
        <Text withTranslate={false}>
          {t('Funds in your account/wallet are insufficient for withdrawal.')}&nbsp;
          <span
            className='!cursor-pointer !text-orange-500 !font-medium'
            onClick={() => navigate(path.money.depositTab())}
          >
              {t('Please make a deposit')}&nbsp;
          </span>
          or&nbsp;
          <span 
            className='!cursor-pointer !text-orange-500 !font-medium'
            onClick={onHandleContactSupport}
          >
            {t('contact Customer Care')}
          </span>
        </Text>
      </Alerts>
    </div>
  )

  return (
    <div>
      {isVerified && (
        <>
          <Title level={2}>Select the account/wallet to withdraw money</Title>
          <AccountSelect
            onSelect={onSelectSource}
            data={destinationsData}
            defaultValue={accountDepositStore as string || withdrawalPageStore?.source?.id}
          />
          <TransferFeatures
            data={[
              { icon: 'checkCircle', label: '0% Commission' },
              { icon: 'clockSolid', label: 'Processing time 15 to 30 minutes' },
            ]}
          />
        </>
      )}
    </div>
  )
}
