import { useQuery } from '@tanstack/react-query';
import { omitBy } from 'lodash';

import { axios } from 'services/axios';
import { PartnerClientsList } from './types/partnerClientsTypes';
import { SortObjParams } from 'pages/ib/models/tableSortStore';
import { FilterStore } from 'pages/ib/molecules/clientsReport/model';


export const PARTNERS_CLIENTS_LIST_KEY = 'PARTNERS_CLIENTS_LIST_KEY';

const getClients = async ({ filter = {}, sort,  ...params}: Options) => {
  const sortDirection = sort?.direction && (sort?.direction === 'asc' ? 'PASD_ASC' : 'PASD_DESC');
  
  const paramsObj = {
    page: params.page,
    perPage: params.perPage,
    'filter.name': filter?.login,
    'filter.timeframePeriod.from': filter?.timeframePeriod?.from,
    'filter.timeframePeriod.to': filter?.timeframePeriod?.to,
    'filter.ftd': filter?.ftd,
    'filter.verification': filter?.verification,
    'sorting.field': sort?.name,
    'sorting.direction': sortDirection,
  };

  const res = await axios.get<PartnerClientsList>(`/ib/v1/partner/clients`, {
    params: omitBy(paramsObj, (val) => !val)
  });
  return res.data;
};

interface Options {
  page: number;
  perPage: number;
  sort?: SortObjParams | null;
  filter: FilterStore;
}

export const useGetPartnerClients = ({ page = 1, perPage = 10, filter, sort }: Options) => {
  return useQuery({
    queryKey: [PARTNERS_CLIENTS_LIST_KEY, page, perPage, filter, sort],
    queryFn: () => getClients({ page, perPage, filter, sort }),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    // staleTime: 1000,
  })
};
