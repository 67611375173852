import { useWindowSize } from '@uidotdev/usehooks'
import { useStore } from 'effector-react'
import { logout } from 'features/session'
import { $userProfile } from 'features/user'
import { getEmailTip } from 'libs/emailChecker'
import { path } from 'libs/path'
import { yup } from 'libs/validators'
import { useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ZendeskAPI } from 'react-zendesk'
import styled from 'styled-components/macro'
import { Back, Button, Field, Form } from 'ui/atoms'

import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../layout'
import { EmailHint } from '../email/styled'
import { useEmailConfirmRepeatPage } from './hooks'
import {
  emailConfirmationRequest,
  resendEmailConfirmationRequest,
  submitEmailVerifyRepeatForm,
} from './model'

export const RegisterEmailConfirmRepeat = () => {
  const { width } = useWindowSize()
  const isMobile = width && width < 600

  const [t] = useTranslation()

  const navigate = useNavigate()

  const inputRef = useRef<HTMLInputElement>(null)

  const { email: userEmail } = useStore($userProfile)
  const loading = useStore(
    emailConfirmationRequest.pending || resendEmailConfirmationRequest.pending,
  )

  const { error } = useEmailConfirmRepeatPage()

  // todo: костыль из-за корявого формика 👺
  const [email, setEmail] = useState(userEmail)

  const emailTip = getEmailTip(email.split('@')[1] || '')

  return (
    <AuthLayout type="register">
      <AuthSectionTitle level={isMobile ? 3 : 2}>
        Didn’t receive the Code?
      </AuthSectionTitle>
      <AuthSectionText>
        Check your spam folder and make sure you have entered a valid email
        address. You can also submit a new one.
      </AuthSectionText>
      <Form
        initial={{ email }}
        onSubmit={submitEmailVerifyRepeatForm}
        validation={yup.email.verify}
        error={error?.form || ''}
      >
        <EmailConfirmRepeatField
          name="email"
          validate
          autofocus
          ref={inputRef}
          value={email}
          error={error?.fields?.email || ''}
          onChange={(event) => setEmail(event.target.value)}
        />
        {emailTip && (
          <EmailHint>
            <Trans
              i18nKey="Maybe it is @gmail.com"
              values={{ email: `@${emailTip}` }}
              components={[
                <span />,
                <span
                  className="text-bronze.500 ms-[4px] cursor-pointer"
                  onClick={() => {
                    setEmail(`${email.split('@')[0]}@${emailTip}`)

                    inputRef.current?.focus()
                    setTimeout(() => {
                      inputRef.current?.blur()
                    }, 0)
                  }}
                />,
              ]}
            />
          </EmailHint>
        )}
        <EmailConfirmRepeatButton
          name="repeat-code"
          size={isMobile ? 'medium' : 'large'}
          design="primary"
          type="submit"
          disabled={loading}
          loading={loading}
        >
          Resend code
        </EmailConfirmRepeatButton>
      </Form>
      <BackButton
        to={path.register.email.confirm()}
        size={isMobile ? 'medium' : 'large'}
      />
      <HintsContainer>
        <span
          className="cursor-pointer text-bronze.500"
          onClick={() => ZendeskAPI('messenger', 'open')}
        >
          {t('Contact us in LiveChat')}
        </span>{' '}
        {t('or')}{' '}
        <span
          onClick={() => {
            logout()

            navigate(path.register.entry())
          }}
          className="cursor-pointer text-bronze.500"
        >
          {t('choose another way to register')}
        </span>
      </HintsContainer>
    </AuthLayout>
  )
}

const EmailConfirmRepeatButton = styled(Button)`
  background-color: var(--color-gray-200);
  width: 100%;
  margin-top: 24px;
`

const EmailConfirmRepeatField = styled(Field)`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`

const BackButton = styled(Back)`
  width: 100%;
`

const HintsContainer = styled.div`
  margin-top: 24px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  font-weight: 500;
  text-align: center;

  @media (max-width: 600px) {
    padding-top: 11px;
    display: grid;
  }
`
