import { useStore } from 'effector-react'

import { service } from 'services'
import { $error, $userPassword } from './model'

export const usePasswordPage = () => {
  const userPassword = useStore($userPassword)
  const changePasswordLoading = useStore(
    service.auth.postChangePassword.pending,
  )
  const error = useStore($error)

  return {
    userPassword,

    form: {
      error,
      loading: changePasswordLoading,
    },
  }
}
