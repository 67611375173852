import { createEvent, createStore, restore, sample } from 'effector'
import { toPath } from 'features/router'
import { $userProfile, setUserProfile } from 'features/user'
import { ClientError, createCountdown, mapResponseError } from 'libs'
import { path } from 'libs/path'
import { CheckResetPasswordCodeProps, service } from 'services'

export const mounted = createEvent()

export const submitForm =
  createEvent<Pick<CheckResetPasswordCodeProps, 'code'>>()

export const submitRepeat = createEvent()

export const $resendCounter = createStore(0)

// submit form

sample({
  source: $userProfile,
  clock: submitForm,
  fn: ({ email }, { code }) => {
    return {
      query: { email: email.trim(), code },
    }
  },
  target: [service.auth.getCheckResetPasswordCode],
})

// redirect to confirm if success

sample({
  clock: service.auth.getCheckResetPasswordCode.done,
  fn: () => {
    localStorage.removeItem('userEmail')
    localStorage.removeItem('resendDate')
  },
  target: toPath.prepend(() => path.register.password.recovery.change()),
})

// resend confirm timer

export const startResendTimer = createEvent<number>()
export const setTimerInitial = createEvent<number>()
export const $timerInitial = restore(setTimerInitial, 60)

const emailConfirmationTimer = createCountdown({
  name: 'checkResetPasswordCodeTimer',
  start: startResendTimer,
})

export const $resendTimer = restore<number>(emailConfirmationTimer.tick, 0)

// submit resend

sample({
  source: $userProfile,
  clock: submitRepeat,
  fn: ({ email }) => ({
    body: { email },
  }),
  target: service.auth.postResetPassword,
})

sample({
  source: $resendCounter,
  clock: submitRepeat,
  fn: (resendCounter) => ++resendCounter,
  target: $resendCounter,
})

// start resend timer

sample({
  source: $userProfile,
  clock: service.auth.postResetPassword.doneData,
  fn: (userProfile, doneData) => {
    localStorage.setItem('userEmail', userProfile.email)
    localStorage.setItem('resendDate', doneData.body.resendAfter)

    return +doneData.body.resendAfter - Math.floor(Date.now() / 1000)
  },
  target: setTimerInitial,
})

sample({
  clock: setTimerInitial,
  target: startResendTimer.prepend(() => $timerInitial.getState()),
})

sample({
  source: $userProfile,
  clock: mounted,
  fn: (userProfile) => {
    const email = localStorage.getItem('userEmail') || userProfile.email
    const resendDate = localStorage.getItem('resendDate')

    if (resendDate) {
      const remainingTime = +resendDate - Math.floor(Date.now() / 1000)

      if (remainingTime > 0) {
        setTimerInitial(remainingTime) // start timer
      }
    }

    return {
      ...userProfile,
      email,
    }
  },
  filter: () => !!localStorage.getItem('userEmail'),
  target: setUserProfile,
})

// handle errors§

export const $error = createStore<ClientError<
  Pick<CheckResetPasswordCodeProps, 'code'>
> | null>(null)

$error
  .on(service.auth.getCheckResetPasswordCode.failData, (_, failData) => {
    const err = mapResponseError<
      Pick<CheckResetPasswordCodeProps, 'code' | 'email'>
    >(failData.body)

    return err
  })
  .reset([mounted, submitForm, service.auth.postResetPassword.done])
