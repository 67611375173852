import { useStore } from 'effector-react'
import { path } from 'libs/path'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'

import {
  $userDataChangeModal,
  onHideUserDataChangeModal,
} from '../../model/profileModalsStore'

export const ChangeUserDataModal = () => {
  const navigate = useNavigate()

  const isShowModal = useStore($userDataChangeModal)

  const onAgreeModal = () => {
    onHideUserDataChangeModal()

    navigate(path.dashboard.verification.editEntry())
  }

  return (
    <Modal
      title="Update verified information"
      open={isShowModal}
      onClose={() => onHideUserDataChangeModal()}
      onOk={onAgreeModal}
      okLabel="I agree"
    >
      <Text className="!text-gray.600">
        If you change the verified information, you must verify your profile
        again.
      </Text>
    </Modal>
  )
}
