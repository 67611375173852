import { useStore } from 'effector-react'
import { toPath } from 'features/router'
import { splitArray } from 'libs/array'
import { path } from 'libs/path'
import { accountChoosed } from 'pages/money/depositChoice/model'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useGetArchivedAccounts } from 'services/accounts/get/useGetArchivedAccounts'
import { useRestoreAccount } from 'services/accounts/mutation/useRestoreAccount'
import styled, { css } from 'styled-components/macro'
import { SwiperSlide } from 'swiper/react'
import {
  Button,
  Hint,
  Icon,
  Icon2,
  Money,
  RadioList,
  Tag,
  Text,
  Title,
} from 'ui/atoms'
import {
  Alerts,
  Dialog,
  DropdownList,
  TradeModal,
  WidgetSlider,
} from 'ui/molecules'
import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon'
import { Modal } from 'ui/molecules/modal_draft'

import { DemoAddBalance } from '../details/DemoAddBalance'
import { $balanceModalId } from '../details/model'
import { GeneratePassword } from '../generatePassword'
import { onUpdateAccountInList } from '../model'
import { AccountRename } from '../rename'
import { Account, AccountPlatform, AccountStatus, AccountType } from '../types'
import { useAccauntLeverage, useAccountsList } from './hooks'
import {
  $activeAccountId,
  accountRestored,
  resetMSG,
  setActiveAccountId,
  setNewLeverage,
  sumbmitPatchLeverage,
} from './model'

type AccountTypes = AccountType | AccountStatus

export const accountTypesName: Record<AccountTypes, string> = {
  PAAS_ACTIVE: 'All',
  PAAT_REAL: 'Real',
  PAAT_DEMO: 'Demo',
  PAAS_ARCHIVED: 'Archived',
  PAAT_INVALID: '',
  PAAS_INVALID: '',
}

export const accountPlatformName: Record<AccountPlatform, string> = {
  PAAP_INVALID: '',
  PAAP_MT4: 'MT4',
  PAAP_MT5: 'MT5',
}

export const accountTypes: AccountTypes[] = [
  'PAAS_ACTIVE',
  'PAAT_REAL',
  'PAAT_DEMO',
  'PAAS_ARCHIVED',
]

type Props = {
  withDetails?: boolean
}

export const AccountsList = ({ withDetails = false }: Props) => {
  const [t] = useTranslation()

  const { accounts, archiveAlertVisible, closeArchiveAlert } = useAccountsList()

  const { data: archivedAccounts } = useGetArchivedAccounts()

  const [activeFilter, setActiveFilter] =
    React.useState<AccountTypes>('PAAS_ACTIVE')

  const currentAccounts =
    activeFilter === 'PAAS_ARCHIVED'
      ? archivedAccounts?.map((account) => ({
          ...account,
          status: 'PAAS_ARCHIVED' as AccountStatus,
        }))
      : accounts.filter((details) =>
          [details.type, details.status].includes(activeFilter),
        )

  return (
    <AccountsListContainer>
      <AccountsHeader>
        <AccountsListFilters>
          {accountTypes.map((type, key) => (
            <AccountsListFilterLabel
              key={key}
              onClick={() => setActiveFilter(type)}
              data-active={type === activeFilter}
            >
              {t(accountTypesName[type])}
            </AccountsListFilterLabel>
          ))}
        </AccountsListFilters>
        <AccountsExtra>
          <DropdownList
            items={[
              {
                label: 'Real account',
                onClick: () => {
                  toPath(path.accounts.create.tariff('real'))
                },
              },
              {
                label: 'Demo account',
                onClick: () => {
                  toPath(path.accounts.create.tariff('demo'))
                },
              },
            ]}
          >
            <CreateButton
              prefix={<Icon name="plus" size="small" />}
              name="create-account"
              design="secondary"
              size="small"
            >
              Create Account
            </CreateButton>
          </DropdownList>
        </AccountsExtra>
      </AccountsHeader>
      {withDetails &&
        activeFilter === 'PAAS_ARCHIVED' &&
        archiveAlertVisible && (
          <ArchiveAlert type="info" onClose={closeArchiveAlert}>
            <Text>
              Accounts are archived after 90 days of inactivity. You can restore
              accounts with a balance over 0.
            </Text>
          </ArchiveAlert>
        )}
      {currentAccounts && currentAccounts.length > 0 && (
        <AccountsListContent>
          {withDetails || currentAccounts.length <= 3 ? (
            currentAccounts.map((details, key) => (
              <AccountDetails
                key={key}
                withDetails={withDetails}
                details={details}
              />
            ))
          ) : (
            <WidgetSlider>
              {splitArray(currentAccounts, 3).map((detailsSet, key) => (
                <SwiperSlide key={key}>
                  {detailsSet.map((details, key) => (
                    <AccountDetails key={key} details={details} />
                  ))}
                </SwiperSlide>
              ))}
            </WidgetSlider>
          )}
        </AccountsListContent>
      )}
    </AccountsListContainer>
  )
}

const AccountsListContainer = styled.div``

const AccountsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  @media (max-width: 1280px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 24px;
  }
`

const ArchiveAlert = styled(Alerts)`
  margin: 24px 0;

  @media (max-width: 767px) {
    margin: 16px 0;
  }
`

const AccountsExtra = styled.div``

const AccountsListContent = styled.div``

const AccountsListFilters = styled.div`
  display: flex;
  gap: 8px;
`

const AccountsListFilterLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 500;
  border-radius: 4px;
  background-color: var(--color-gray-300);
  transition: var(--transition);
  text-transform: capitalize;
  cursor: pointer;

  &[data-active='true'] {
    background-color: var(--color-gray-400);
  }

  &:hover {
    background-color: var(--color-gray-400);
  }
`

//

type AccountDetailsProps = {
  details: Account
} & Pick<Props, 'withDetails'>

export const AccountDetails = ({
  details,
  withDetails,
}: AccountDetailsProps) => {
  const [t] = useTranslation()
  const activeAccountId = useStore($activeAccountId)
  const {
    accountID,
    name,
    type,
    platform,
    password,
    balance,
    currency,
    mtServerInfo,
    tariffName,
    isFixRate,
    login,
    status,
    isRestoreDisabled,
  } = details
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)

  const balanceModalId = useStore($balanceModalId)

  const { mutateAsync, isSuccess } = useRestoreAccount(accountID)

  React.useEffect(() => {
    if (isSuccess) {
      accountRestored()
    }
  }, [isSuccess])

  const onClickTransaction = (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    accountChoosed(accountID)
    navigate(path.money.entry())
  }

  const onClickWithdrawal = () => {
    accountChoosed(accountID)
    navigate(path.money.withdrawalTab())
  }

  const onUpdateAccList = (id: string, newAcc: Account) => {
    onUpdateAccountInList({
      id,
      account: newAcc,
    })
  }

  const isVisible = accountID === activeAccountId

  const depositBlock =
    type === 'PAAT_DEMO' ? (
      <DemoAddBalance
        id={accountID}
        onSetNewBalance={onUpdateAccList}
        asLink
        isLarge={!withDetails}
      />
    ) : (
      <DetailsActionLink onClick={onClickTransaction}>
        {t('Deposit')}
      </DetailsActionLink>
    )

  const getBalance = React.useMemo(() => {
    if (!balance) return ''

    const [decimal, float] = String(balance).split('.')

    if (!float) return decimal

    return `${decimal}.${float.slice(0, 2)}`
  }, [balance])

  // const status = 'PAAS_ARCHIVED'

  return (
    <DetailsContainer>
      <DetailsHeader
        onClick={() => {
          if (!balanceModalId && status !== 'PAAS_ARCHIVED') {
            if (withDetails) {
              if (isVisible) {
                setActiveAccountId('')
              } else {
                setActiveAccountId(accountID)
              }
            } else {
              toPath(path.accounts.details(accountID))
            }
          }
        }}
        interactive={status !== 'PAAS_ARCHIVED'}
      >
        <DetailsBalanceBlock>
          <DetailsColumn>
            <DetailsTitleHeader>
              {withDetails && isVisible ? (
                <AccountRename accountID={accountID}>
                  <DetailsTitle level={4}>{name}</DetailsTitle>
                </AccountRename>
              ) : (
                <DetailsTitle level={withDetails ? 4 : 3}>{name}</DetailsTitle>
              )}
            </DetailsTitleHeader>
            <DetailsTags>
              {isFixRate && <Tag design="eucalyptus">Fixed rate</Tag>}
              <Tag design={type === 'PAAT_DEMO' ? 'azure' : 'sunray'}>
                {accountTypesName[type]}
              </Tag>
              <Tag design="gray">{accountPlatformName[platform]}</Tag>
              {tariffName && (
                <Tag design="ghost">{tariffName.replace('demo', '')}</Tag>
              )}
            </DetailsTags>
          </DetailsColumn>
          <DetailsColumn isGrid={!withDetails}>
            <DetailsBalance>
              <Money value={balance} currency={currency} type="symbol" />
              {getBalance}
            </DetailsBalance>
            {!withDetails && status !== 'PAAS_ARCHIVED' && depositBlock}
          </DetailsColumn>
        </DetailsBalanceBlock>
        {withDetails && status !== 'PAAS_ARCHIVED' && (
          <DetailsControlBlock>
            <ActionBlock>{depositBlock}</ActionBlock>
            <DetailsColumn>
              <DetailsExpand>
                <Icon2 name={isVisible ? 'chevronUp' : 'chevronDown'} />
              </DetailsExpand>
            </DetailsColumn>
          </DetailsControlBlock>
        )}
        {withDetails && status === 'PAAS_ARCHIVED' && (
          <RestoreWrapper>
            {!isRestoreDisabled && (
              <RestoreButton
                name="restore-account"
                size="small"
                design="secondary"
                onClick={() => mutateAsync(accountID)}
              >
                Restore
              </RestoreButton>
            )}
          </RestoreWrapper>
        )}
      </DetailsHeader>
      {withDetails && status !== 'PAAS_ARCHIVED' && (
        <DetailsContent data-visible={isVisible}>
          <DetailsRow>
            <Hint
              textContent={t(
                'A login for your trading account on the MetaTrader platform.',
              )}
            >
              <span className="font-normal">{t('Login')}</span>
            </Hint>
            <DetailsValue>
              {login}{' '}
              <DetailsCopy
                iconSize="small"
                copiedText={login}
                onCopied={(e) => e.stopPropagation()}
              />
            </DetailsValue>
          </DetailsRow>
          <DetailsRow>
            <Hint
              textContent={t(
                'A password for your trading account on the MetaTrader platform.',
              )}
            >
              <span className="font-normal">{t('Password')}</span>
            </Hint>
            <DetailsValue>
              <GeneratePassword password={password} accountID={accountID} />
            </DetailsValue>
          </DetailsRow>
          <DetailsRow>
            <Hint
              textContent={t(
                'A ratio at which Headway multiplies account funds for trading. Available leverage depends on the account’s equity and can increase potential profit as well as risk.',
              )}
            >
              <span className="font-normal">{t('Leverage')}</span>
            </Hint>
            <LeverageDialog accountID={accountID} />
          </DetailsRow>
          <DetailsRow>
            <Hint
              textContent={t('The name of Headway’s server in MetaTrader.')}
            >
              <span className="font-normal">{t('Server')}</span>
            </Hint>
            <DetailsValue>
              <DetailsValueText>{mtServerInfo.name}</DetailsValueText>
              <DetailsCopy
                iconSize="small"
                copiedText={mtServerInfo.name}
                onCopied={(e) => e.stopPropagation()}
              />
            </DetailsValue>
          </DetailsRow>

          <DetailsFooter onClick={(e) => e.stopPropagation()}>
            {type === 'PAAT_DEMO' ? (
              <DemoAddBalance
                id={accountID}
                onSetNewBalance={onUpdateAccList}
              />
            ) : (
              <>
                <Button
                  size="small"
                  name="details-deposit"
                  onClick={onClickTransaction}
                  design="primary"
                  prefix={<Icon2 name="arrowDownOnSquare" size="small" />}
                >
                  Deposit
                </Button>
                <Button
                  size="small"
                  name="details-withdrawal"
                  onClick={onClickWithdrawal}
                  design="secondary"
                  prefix={<Icon2 name="arrowDownOnSquare" size="small" />}
                >
                  Withdrawal
                </Button>
              </>
            )}
            <Button
              size="small"
              name="details-trade"
              onClick={() => setOpenModal(true)}
              design="secondary"
              prefix={<Icon2 name="arrowTrendingUp" size="small" />}
            >
              Trade
            </Button>
            <Button
              size="small"
              name="deposit-details"
              onClick={() => {
                toPath(path.accounts.details(accountID))
              }}
              design="secondary"
            >
              See Details
            </Button>
          </DetailsFooter>
        </DetailsContent>
      )}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        okLabel="Back"
        title="Trade"
        width={400}
      >
        <TradeModal details={details} />
      </Modal>
    </DetailsContainer>
  )
}

export const LeverageDialog: React.FC<any> = ({ accountID }) => {
  const {
    leverage,
    newLeverage,
    leveragePatchLoading,
    accountID: currentAccountID,
    msg,
  } = useAccauntLeverage()

  const [modalVisible, setModalVisible] = React.useState(false)

  React.useEffect(() => {
    if (msg && accountID === currentAccountID) {
      setModalVisible(true)
    }
  }, [msg, accountID, currentAccountID])

  return (
    <>
      <Dialog
        positionX="right"
        direction="column"
        name="leverage-select"
        confirm={{
          onConfirm: () => sumbmitPatchLeverage({ accountID }),
          design: 'primary',
          text: 'Confirm',
          visibility: true,
        }}
        cancel={{
          onCancel: () => setNewLeverage(leverage.current),
          text: 'Cancel',
          design: 'tertiary',
          visibility: true,
        }}
        isLoading={leveragePatchLoading}
        isDisabled={leveragePatchLoading || !newLeverage}
        onClick={(e) => e.stopPropagation()}
        content={
          <Leverage>
            <LeverageHeader level={3}>Select Leverage</LeverageHeader>
            <Text className="text-center">
              You can change leverage settings once every 6 hours.
            </Text>
            <LeverageContent>
              <RadioList
                name={`account-${accountID}-leverages`}
                direction="column"
                initial={leverage.current}
                items={leverage.list.map(
                  ({ leverage: lev, unavailableReason, isUnavailable }) => {
                    const label = `${
                      lev === '210000000' ? 'Unlimited' : `1:${lev}`
                    }`

                    return {
                      label,
                      value: lev,
                      tip: unavailableReason,
                      disabled: isUnavailable,
                      onSelect: setNewLeverage,
                      checked: lev === newLeverage,
                    }
                  },
                )}
              />
            </LeverageContent>
          </Leverage>
        }
      >
        <DetailsValue>
          <DetailsLavarageTrigger>
            {leverage.current === '210000000'
              ? 'Unlimited'
              : `1:${leverage.current}`}
          </DetailsLavarageTrigger>
          <DetailsLavarageShevron name="chevronDown" size="small" />
        </DetailsValue>
      </Dialog>
      <Modal
        open={modalVisible}
        title="Leverage change"
        okLabel="Get it"
        onClose={() => {
          setModalVisible(false)
          resetMSG()
        }}
      >
        <Text className="!text-gray.600 text-left" withTranslate={false}>
          {msg}
        </Text>
      </Modal>
    </>
  )
}

const DetailsContainer = styled.div`
  border: 1px solid var(--color-gray-300);
  justify-content: space-between;
  border-radius: 8px;
  margin-bottom: 8px;
  font-weight: 500;
  transition: var(--transition);

  &:hover {
    border: 1px solid #eaeaea;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

const DetailsHeader = styled.div<{ interactive: boolean }>`
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'initial')};
  display: flex;
  justify-content: space-between;
  padding: 16px;

  @media (max-width: 1150px) {
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: 767px) {
    gap: 8px;
  }
`

const RestoreWrapper = styled.div`
  flex-shrink: 0;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1150px) {
    width: 100%;
  }
`

const RestoreButton = styled(Button)`
  min-width: 100px;

  @media (max-width: 767px) {
    min-width: auto;
  }
`

const EditIcon = styled(Icon2)`
  opacity: 0;

  &[data-size='medium'] {
    min-width: 15px;
    width: 15px;
    height: 15px;
    margin-inline-start: 6px;
  }
`

const DetailsTitleHeader = styled.div`
  display: flex;
  position: relative;

  &:hover {
    ${EditIcon} {
      opacity: 1;
    }
  }
`

const DetailsBalanceBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
const DetailsControlBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-inline-start: 6.25rem;
  gap: 6.25rem;

  @media (max-width: 1150px) {
    margin-inline-start: 0;
    gap: 0;
  }

  @media (max-width: 767px) {
    min-height: 34px;
  }
`

const DetailsColumn = styled.div<{ isGrid?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ isGrid }) =>
    isGrid &&
    css`
      justify-content: space-between;
      align-items: flex-end;

      ${DetailsActionLink} {
        font-size: var(--body-font-size-regular);

        @media (max-width: 470px) {
          font-size: var(--body-font-size-medium);
        }
      }
    `}
`

const ActionBlock = styled(DetailsColumn)`
  min-width: 100px;
  text-align: center;

  @media (max-width: 1150px) {
    text-align: start;
  }
`

const DetailsTitle = styled(Title)`
  line-height: 0;
`

const DetailsTags = styled.div`
  display: flex;
  margin-top: 10px;
  text-transform: capitalize;

  @media (max-width: 550px) {
    flex-wrap: wrap;
    gap: 8px;
  }
`

const DetailsBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const DetailsExpand = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DetailsContent = styled.div`
  cursor: initial;
  transition: var(--transition);
  margin: 0 12px;
  border-top: 1px solid var(--color-gray-300);

  &[data-visible='false'] {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &[data-visible='true'] {
    height: auto;
    padding: 12px 0 12px 0;
    opacity: 1;
  }
`

const DetailsRow = styled.div`
  padding: 12px 0 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 570px) {
    padding: 12px 0;
  }
`

const DetailsLavarageTrigger = styled.div`
  text-decoration: underline;
`

const DetailsValue = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;

  &:hover {
    ${DetailsLavarageTrigger} {
      text-decoration: none;
    }
  }
`

export const DetailsValueText = styled.div`
  text-align: end;
`

export const DetailsCopy = styled(CopyIcon)`
  margin-inline-start: 8px;
`

const DetailsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 12px 0 12px 8px;
  color: var(--color-gray-700);

  @media (max-width: 570px) {
    flex-direction: column;
    padding: 12px 0;
  }
`

const DetailsLavarageShevron = styled(Icon2)`
  margin-inline-start: 8px;
  transform: translateY(1px);
`

const Leverage = styled.div``

const LeverageHeader = styled(Title)`
  margin-bottom: 12px;
  text-align: center;
`

const LeverageContent = styled.div`
  margin-top: 16px;
  height: 324px;
  overflow-y: scroll;
  margin-bottom: 24px;
  /* -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  } - как будто бы без скролла не очевидно, что он там есть */
`

const DetailsActionLink = styled.span`
  cursor: pointer;
  font-size: var(--body-font-size-medium);
  color: var(--color-bronze-500);
  line-height: 1;
  font-weight: 500;
`

const CreateButton = styled(Button)`
  &[data-size='small'] {
  }
`
