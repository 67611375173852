import { path } from 'libs/path'
import { setPublishedAccount } from 'pages/copytrade/model'
import { accountChoosed } from 'pages/money/depositChoice/model'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import {
  Block,
  Button,
  Hint,
  Icon2,
  Money,
  Tag,
  Title,
} from 'ui/atoms'
import { BonusBanner, MetaTraders, TradeModal } from 'ui/molecules'
import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon'
import { Modal } from 'ui/molecules/modal_draft'

import { accountPlatformName, accountTypesName } from '../list'
import { LeverageDialog } from '../list/page'
import { onChangeAccount } from '../model'
import { AccountRename } from '../rename'
import { SwapFree } from '../swapFree'
import { Account } from '../types'
import { DemoAddBalance } from './DemoAddBalance'
import { useAccountDetails } from './hooks'
import { MetaTraderSettings } from './MetaTraderSettings'

export const AccountDetails = () => {
  const [t] = useTranslation()
  const { accountID, account } = useAccountDetails()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)

  const {
    name,
    type,
    isFixRate,
    tariffName,
    platform,
    balance,
    currency,
    isSwapFree,
    login,
    password,
    mtServerInfo,
    availableActions,
  } = account

  const onClickDeposit = () => {
    accountChoosed(account.accountID)
    navigate(path.money.entry())
  }

  const onClickWithdrawal = () => {
    accountChoosed(account.accountID)
    navigate(path.money.withdrawalTab())
  }

  const onSetNewBalance = (id: string, newAccountData: Account) => {
    onChangeAccount(newAccountData)
  }

  const isPro = tariffName.toLowerCase().includes('pro')

  return (
    <AccountsContainer>
      <AccountsContent>
        <Block>
          <DetailsBlock scheme="ghost">
            <DetailsHeader>
              <DetailsTitleContainer>
                <AccountRename accountID={accountID}>
                  <DetailsTitle level={2}>{name}</DetailsTitle>
                </AccountRename>
              </DetailsTitleContainer>
              <DetailsTags>
                {isFixRate && <Tag design="eucalyptus">Fixed rate</Tag>}
                <Tag design={type === 'PAAT_DEMO' ? 'azure' : 'sunray'}>
                  {accountTypesName[type]}
                </Tag>
                <Tag design="gray">{accountPlatformName[platform]}</Tag>
                {tariffName && (
                  <Tag design="ghost">{tariffName.replace('demo', '')}</Tag>
                )}
              </DetailsTags>
            </DetailsHeader>
            <DetailsContent>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'Total cash available for trading, withdrawals, and internal transfers.',
                  )}
                >
                  {t('Balance')}
                </Hint>
                <DetailsValue>
                  <Money value={balance} currency={currency} />
                </DetailsValue>
              </DetailsRow>
            </DetailsContent>
            <DetailsFooter>
              {type === 'PAAT_DEMO' && (
                <DemoAddBalance
                  id={account.accountID}
                  onSetNewBalance={onSetNewBalance}
                />
              )}
              {type !== 'PAAT_DEMO' && (
                <Button
                  size="small"
                  name="details-deposit"
                  onClick={onClickDeposit}
                  design="primary"
                  prefix={<Icon2 name="arrowDownOnSquare" size="small" />}
                >
                  Deposit
                </Button>
              )}
              {type !== 'PAAT_DEMO' && (
                <Button
                  size="small"
                  name="details-withdrawal"
                  onClick={onClickWithdrawal}
                  design="secondary"
                  prefix={<Icon2 name="arrowDownOnSquare" size="small" />}
                >
                  Withdrawal
                </Button>
              )}
              <Button
                size="small"
                name="details-trade"
                onClick={() => setOpenModal(true)}
                design="secondary"
                prefix={<Icon2 name="arrowTrendingUp" size="small" />}
              >
                Trade
              </Button>
            </DetailsFooter>
          </DetailsBlock>
          {/* // */}
          <BlockContainer>
            <Title level={3}>Account settings</Title>
            <DetailsBlock scheme="ghost">
              <DetailsRow>
                <Hint
                  textContent={t(
                    'A ratio at which Headway multiplies account funds for trading. Available leverage depends on the account’s equity and can increase potential profit as well as risk.',
                  )}
                >
                  {t('Leverage')}
                </Hint>
                <DetailsValue>
                  <LeverageDialog accountID={accountID} />
                </DetailsValue>
              </DetailsRow>
              {availableActions.indexOf('ACTION_CHANGE_SWAP_FREE') !== -1 ? (
                <DetailsRow>
                  <Hint
                    textContent={t(
                      'If your account is Swap-Free (also referred to as Islamic), you don’t pay overnight swaps and interest for your open positions.',
                    )}
                  >
                    {t('Swap Free')}
                  </Hint>
                  <DetailsValue>
                    <SwapFree accountID={accountID} isSwapFree={isSwapFree} />
                  </DetailsValue>
                </DetailsRow>
              ) : null}
            </DetailsBlock>
          </BlockContainer>
          {/* // */}
          <BlockContainer>
            <Title level={3}>MetaTrader settings</Title>
            <MetaTraderSettings
              login={login}
              mtServerInfo={mtServerInfo}
              password={password}
              accountID={accountID}
            />
          </BlockContainer>
          {/* // */}
          {type !== 'PAAT_DEMO' && (
            <BlockContainer>
              <Title level={3}>Copytrade settings</Title>
              <DetailsBlock scheme="ghost">
                <DetailsRow>
                  Publish this account as a strategy
                  <button
                    type="button"
                    className="font-medium text-bronze.500"
                    onClick={() => {
                      setPublishedAccount(account.accountID)

                      navigate(path.copytrade.createStrategy())
                    }}
                  >
                    Publish
                  </button>
                </DetailsRow>
                <DetailsRow>
                  Copy other traders on this account
                  <Link
                    to={path.copytrade.investorEntry()}
                    className="font-medium text-bronze.500 whitespace-nowrap"
                  >
                    See strategies
                  </Link>
                </DetailsRow>
              </DetailsBlock>
            </BlockContainer>
          )}
          {/* // */}
          <BlockContainer>
            <Title level={3}>Account Information</Title>
            <DetailsBlock scheme="ghost">
              <DetailsRow>
                <Hint
                  textContent={t(
                    'Fix Rate allows you to fund your account at the rate of 1 USD = 10,000 IDR. If you enable the Fix Rate, you can’t undo it later.',
                  )}
                >
                  Fix rate
                </Hint>
                <DetailsValue>{isFixRate ? 'Yes' : 'No'}</DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'A fee paid to a broker for buying or selling assets.',
                  )}
                >
                  {t('Commission')}
                </Hint>
                <DetailsValue>
                  {isPro ? 'Up to 1.5$ each side per lot' : 'No'}
                </DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'A gap between the bid (buying) and the ask (selling) price of an asset.',
                  )}
                >
                  {t('Spread')}
                </Hint>
                <DetailsValue>
                  Floating from {isPro ? '0.0' : '0.3'}
                </DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'The total quantity of lots, shares, or contracts traded for an asset.',
                  )}
                >
                  {t('Order volume')}
                </Hint>
                <DetailsValue>
                  {isPro
                    ? 'From 0.1 to 1000 lots'
                    : 'From 0.01 to 1000 cent lots'}
                </DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'The total available number of open and scheduled positions in MetaTrader.',
                  )}
                >
                  {t('Maximum open positions and pending orders')}
                </Hint>
                <DetailsValue>
                  {isPro
                    ? t('Unlimited')
                    : account.tariffName === 'cent'
                    ? 300
                    : 200}
                </DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <Hint
                  textContent={t(
                    'The time needed to open an order at the available price, depending on market depth, without re-quotes.',
                  )}
                >
                  {t('Market execution time')}
                </Hint>
                <DetailsValue>From 0.16 sec</DetailsValue>
              </DetailsRow>
            </DetailsBlock>
          </BlockContainer>
        </Block>
      </AccountsContent>
      <AccountsAside>
        <MetaTraders aside />
        <BonusBanner />
      </AccountsAside>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        okLabel="Back"
        title="Trade"
        width={400}
      >
        <TradeModal details={account} />
      </Modal>
    </AccountsContainer>
  )
}

const AccountsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const AccountsContent = styled.div`
  width: 70%;

  @media (max-width: 900px) {
    width: 100%;
  }
`

const AccountsAside = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 20px;

  @media (max-width: 900px) {
    width: 100%;
  }
`

const BlockContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const DetailsTitle = styled(Title)`
  line-height: 0;
`

const DetailsTitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const DetailsTags = styled.div`
  display: flex;
  text-transform: capitalize;
`

const DetailsHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 570px) {
    flex-wrap: wrap;
  }
`

const DetailsContent = styled.div`
  padding-top: 24px;
  transition: var(--transition);
`

export const DetailsRow = styled.div`
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
`

export const DetailsValue = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  text-align: end;
  flex-wrap: wrap;
  justify-content: flex-end;
`

export const DetailsBlock = styled(Block)`
  ${DetailsRow} {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
`

const DetailsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding-top: 24px;
  color: var(--color-gray-700);

  @media (max-width: 470px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const DetailsCopy = styled(CopyIcon)`
  margin-inline-start: 8px;
`
