import formatFn from 'date-fns/format'
import fromUnixTime from 'date-fns/fromUnixTime'
import { arSA, enUS, es, fr, id, ja, ms, ptBR, th, vi } from 'date-fns/locale'

export const localeMap: Record<string, Locale> = {
  ar: arSA,
  en: enUS,
  es,
  fr,
  id,
  ja,
  ms,
  pt: ptBR,
  th,
  vi,
}

export const getDateFromUnix = (dateUnix: number, format = 'PPpp') => {
  const dateObj = fromUnixTime(dateUnix)

  return formatFn(dateObj, format)
}

export const getDateObjFromUnix = (dateUnix: number) => {
  const dateObj = fromUnixTime(dateUnix)

  return dateObj
}

export const toUnixTimeString = (date: Date): string => {
  if (!date) return ''

  return Math.ceil(date.getTime() / 1000).toString()
}

export const fromDateToIso = (time: Date) => {
  if (!time) return ''
  return formatFn(time, 'dd/MM/yyyy')
}
