import { Link as UiLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import background from './images/background.jpg'
import backgroundWeb from './images/background.webp'
import close from './images/close-icon.svg'
import featureBackground from './images/featureBackground.webp'

export const Wrapper = styled.div`
  position: relative;
  flex-shrink: 0;
`

export const Link = styled(UiLink)<{ isFeature?: boolean }>`
  cursor: pointer;
  padding: 12px 21px 12px 12px;
  width: 128px;
  height: 128px;
  display: block;
  transition: var(--transition);
  border-radius: 8px;
  background-color: var(--color-gray-100);
  box-shadow: var(--box-shadow-4);
  background-image: url(${background});
  background-image: ${({ isFeature }) =>
    isFeature
      ? `url(${featureBackground})`
      : `image-set(url(${backgroundWeb}), url(${background}))`};
  background-size: contain;
  background-position: bottom right;

  &:hover {
    box-shadow: var(--box-shadow-6);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid var(--color-gray-700);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    opacity: 0.2;
  }
`

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 0;
  inset-inline-end: 0;
  width: 24px;
  height: 24px;
  background-image: url(${close});
  background-repeat: no-repeat;
  background-size: 16px auto;
  background-position: center center;
`
