import styled, { css } from 'styled-components/macro'
import { Button } from 'ui/atoms'

export const InputWrapper = styled.div<{ hasError: boolean }>`
  margin-top: 24px;

  .vi {
    &__container {
      width: 100%;
      height: 60px;
      gap: 0;
      justify-content: space-between;

      @media (max-width: 599px) {
        height: 70px;
      }
    }

    &__character {
      flex-grow: initial;
      flex-basis: initial;
      border-color: var(--color-gray-400);
      display: grid;
      place-items: center;
      width: 60px;
      height: 60px;
      font-size: 32px;
      line-height: 40px;
      font-weight: 500;
      color: var(--color-gray-400);
      border-radius: 8px;
      background-color: transparent;
      transition-property: background-color, color, border-color;
      transition-duration: var(--transition);

      @media (max-width: 599px) {
        width: 50px;
        height: 70px;
      }

      &--selected {
        border-color: var(--color-sunray-500);
        outline: none;
      }

      &--filled {
        border-color: transparent;
        color: var(--color-gray-700);
        background-color: var(--color-sunray-100);
      }
    }
  }

  ${({ hasError }) =>
    hasError &&
    css`
      .vi {
        &__character {
          border-color: var(--color-opal-500);
          background-color: transparent;
        }
      }
    `}
`

export const ResendButton = styled(Button)`
  margin-top: 24px;
  width: 100%;
`
