import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next'
import { Money } from 'ui/atoms'

import * as Styled from './styled'

const Cell = ({ name, value }: { name: string; value: ReactNode }) => {
  const [t] = useTranslation()

  return (
    <div>
      {t(name)}
      <Styled.Value level={3} withTranslate={false}>
        {value}
      </Styled.Value>
    </div>
  )
}

export const TradingInfo = ({
  platform,
  instruments,
  leverage,
  initialBalance,
}: {
  platform: string
  instruments: string
  leverage: string
  initialBalance: string
}) => {
  return (
    <Styled.Wrapper>
      <Cell name="Platform" value={platform} />
      <Cell name="Trading instruments" value={instruments} />
      <Cell name="Leverage" value={leverage} />
      <Cell
        name="Initial balance"
        value={<Money value={+initialBalance} minimumFractionDigits={0} />}
      />
    </Styled.Wrapper>
  )
}