import { path } from 'libs/path'
import { useNavigate } from 'react-router-dom'
import { Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'

export const ThanksModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const navigate = useNavigate()

  const handleOk = () => {
    onClose()

    navigate(path.giftshop.history())
  }

  return (
    <Modal
      title="Thank you!"
      open={isOpen}
      onClose={onClose}
      onOk={handleOk}
      okLabel="Order history"
      cancelLabel="Close"
      icon="success"
    >
      <Text className="text-center !text-gray.600">
        We will update you on the status of your order via email.
      </Text>
    </Modal>
  )
}
