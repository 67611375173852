import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styled'

export const BottomSheet = (props: {
  title?: string;
  children: ReactNode;
  isOpen: boolean;
  contentClasses?: string;
  hideScroll?: boolean;
  onClose: () => void;
}) => {
  const [t] = useTranslation()

  useEffect(() => {
    document.body.style.overflow = props.isOpen ? 'hidden' : 'visible'
  }, [props.isOpen])

  const handleClose = () => {
    props.onClose()
  }

  return (
    <Styled.FullContainer isVisible={props.isOpen}>
      <Styled.Background onClick={handleClose} />
      <Styled.ContentWrapper className={props.contentClasses}>
        <Styled.CloseButton type="button" onClick={handleClose} />
        {props.title && (
          <Styled.ContentTitle level={3}>{t(props.title)}</Styled.ContentTitle>
        )}
        <Styled.ListWrap hideScroll={ props.hideScroll }>
          {props.children}
        </Styled.ListWrap>
      </Styled.ContentWrapper>
    </Styled.FullContainer>
  )
}
