import { useMutation } from '@tanstack/react-query'
import { axios } from 'services/axios'

const startCopying = async (params: {
  strategyID: string
  investorAccountID: string
  proportion: number
  isCopyOpenedPos: boolean
  commissionType: string
}) => {
  const res = await axios.post('/copytrade/v1/strategy/subscription', params)

  return res.data
}

export const useStartCopying = () => {
  return useMutation({
    mutationFn: startCopying,
  })
}
