import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { getEmailTip } from 'libs/emailChecker'
import { path } from 'libs/path'
import { yup } from 'libs/validators'
import { useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { $error, postResetPassword, resetError } from 'services/auth/model'
import styled from 'styled-components/macro'
import { Back, Button, Field, Form } from 'ui/atoms'

import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../../layout'
import { EmailHint } from '../../../register/email/styled'
import { submitForm } from './model'

export const PasswordRecoveryEntry = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const error = useStore($error)
  const { email: userEmail } = useStore($userProfile)
  const loading = useStore(postResetPassword.pending)

  const [email, setEmail] = useState(userEmail)

  const emailTip = getEmailTip(email.split('@')[1] || '')

  return (
    <AuthLayout type="reset">
      <AuthSectionTitle level={2}>Password recovery</AuthSectionTitle>
      <AuthSectionText>
        Please enter your registered email address.
      </AuthSectionText>
      <Form
        initial={{ email }}
        onSubmit={(values) => {
          resetError()
          submitForm(values)
        }}
        validation={yup.password.recovery}
        error={error?.form}
        // enableReinitialize={false} ??
      >
        <EmailInput
          name="email"
          placeholder="Type your Email"
          label="Email"
          validate
          autofocus
          ref={inputRef}
          value={email}
          error={error?.fields?.email}
          onChange={(event) => setEmail(event.target.value)}
        />
        {emailTip && (
          <EmailHint>
            <Trans
              i18nKey="Maybe it is @gmail.com"
              values={{ email: `@${emailTip}` }}
              components={[
                <span />,
                <span
                  className="text-bronze.500 ms-[4px] cursor-pointer"
                  onClick={() => {
                    setEmail(`${email.split('@')[0]}@${emailTip}`)

                    inputRef.current?.focus()
                    setTimeout(() => {
                      inputRef.current?.blur()
                    }, 0)
                  }}
                />,
              ]}
            />
          </EmailHint>
        )}
        <RegisterButton
          name="send-recovery-code-submit"
          size="large"
          design="primary"
          type="submit"
          disabled={loading}
          loading={loading}
        >
          Submit
        </RegisterButton>
      </Form>
      <Back to={path.login.email()} onClick={resetError} />
    </AuthLayout>
  )
}

const RegisterButton = styled(Button)`
  width: 100%;
  margin-top: 32px;
`

const EmailInput = styled(Field)`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px !important;
`
