import styled, { css } from 'styled-components/macro'
import { Text } from 'ui/atoms'

import { AlertSize, AlertType } from './Alerts'

interface AlertProps {
  type: AlertType
  size?: AlertSize
}

export const Alert = styled.div<AlertProps>`
  position: relative;
  padding: 20px;
  display: flex;
  gap: 12px;
  border-radius: 8px;

  @media (max-width: 767px) {
    padding: 16px 12px;
  }

  ${({ size }) => size === 'medium' && css`
    padding: 12px;
    gap: 8px;
  `}

  ${({ type }) => {
    switch (true) {
      case type === 'pending' || type === 'info':
        return css`
          background-color: var(--color-azure-100);
          border: 1px solid #004da133;

          svg {
            fill: var(--color-azure-500);
          }
        `

      case type === 'success':
        return css`
          background-color: var(--color-eucalyptus-100);
          border: 1px solid #31826733;

          svg {
            fill: var(--color-eucalyptus-500);
          }
        `

      case type === 'warning':
        return css`
          background-color: var(--color-orange-100);
          border: 1px solid #f5790233;

          svg {
            fill: var(--color-orange-500);
          }
        `
    }
  }}
`

export const Title = styled(Text)`
  margin-bottom: 4px;
  font-weight: 500;
`

export const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 10px auto;
  background-position: center center;
  background-image: url('data:image/svg+xml,<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.28033 0.21967C0.987437 -0.0732233 0.512563 -0.0732233 0.21967 0.21967C-0.0732233 0.512563 -0.0732233 0.987437 0.21967 1.28033L3.93934 5L0.21967 8.71967C-0.0732233 9.01256 -0.0732233 9.48744 0.21967 9.78033C0.512563 10.0732 0.987437 10.0732 1.28033 9.78033L5 6.06066L8.71967 9.78033C9.01256 10.0732 9.48744 10.0732 9.78033 9.78033C10.0732 9.48744 10.0732 9.01256 9.78033 8.71967L6.06066 5L9.78033 1.28033C10.0732 0.987437 10.0732 0.512563 9.78033 0.21967C9.48744 -0.0732233 9.01256 -0.0732233 8.71967 0.21967L5 3.93934L1.28033 0.21967Z" fill="%23262119"/></svg>');
`
