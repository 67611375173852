import styled, { css } from 'styled-components/macro';
import { Icon2 } from 'ui/atoms';

export const allScrollSidesClassName = 'allScrollSides'

export const TableWrapper = styled.div<{hasScroll: boolean}>`
  overflow-x: auto;
  overflow-y: visible !important;
  &::-webkit-scrollbar {
    display: none;
  }
`;


export const Icon = styled(Icon2)<{ isActive?: boolean }>`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: ${(p) => p.isActive ? 'var(--color-bronze-500)' : 'var(--color-gray-800)'};
`;

export const IconSort = styled(Icon2)<{ isActive?: boolean }>`
  width: 16px !important;
  min-width: 16px !important;
  height: 16px !important;
  margin-left: 8px;
  visibility: ${(p) => p?.isActive ? 'visible' : 'invisible'};

  svg.path {
    width: 11px !important;
    height: 13px !important;
  }
`;

export const OptionContainer = styled.div`
  position: absolute;
  /* top: 8px; */
  /* left: 0; */
  top: 150px;
  transition: var(--transition);

  &[data-visible='false'] {
    opacity: 0;
    z-index: -1;
    visibility: hidden
  }

  &[data-visible='true'] {
    opacity: 1;
    z-index: 100;
    visibility: visible;
  }
`

export const OptionList = styled.div`
  padding: 8px 0;
  background-color: #fff;
  box-shadow: var(--box-shadow-6);

  border-radius: 8px;
  min-height: 52px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  max-width: 200px;
  width: fit-content;
  min-width: 110px;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Option = styled.div`
  padding: 9px 16px;
  transition: var(--transition);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: var(--color-gray-200);
  }
`