import { path } from 'libs/path'
import { useTranslation } from 'react-i18next'
import { useHref } from 'react-router-dom'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import { useGetPartnerPage } from 'services/ib/get/useGetPartnerPage'
import { Hint, Icon2, Text } from 'ui/atoms'
import { CopyIcon } from 'ui/molecules/CopyButton/CopyIcon'
import { Modal } from 'ui/molecules/modal_draft'

import * as Styled from './styled'

export const ShareModal = ({
  isOpen,
  onClose,
  strategyID,
}: {
  isOpen: boolean
  onClose: () => void
  strategyID: string
}) => {
  const [t] = useTranslation()
  const { data: partnerId } = useGetPartnerPage({
    select: (data) => data?.widgetInfo?.partnerKey,
  })

  const link = `${window.location.origin}${useHref(
    `${path.copytrade.strategyForInvestor(strategyID)}${
      partnerId ? `?hwp=${partnerId}` : ''
    }`,
    { relative: 'path' },
  )}`

  return (
    <Modal
      title="Share your strategy"
      open={isOpen}
      okLabel="Close"
      onClose={onClose}
      width={580}
    >
      <Hint textContent={t('ib.partners.active')}>
        <div className="flex">
          {partnerId ? (
            <Icon2
              name="checkBadge"
              className="mr-[6px] !text-eucalyptus.500"
              size="small"
            />
          ) : (
            <Icon2
              name="informationCircleSolid"
              className="mr-[6px] !text-azure.500"
              size="small"
            />
          )}
          <Text withTranslate={false} level={3}>
            {t('Partner comission', {
              status: partnerId ? t('Active') : t('Inactive'),
            })}
          </Text>
        </div>
      </Hint>
      <Styled.Input
        value={link}
        // @ts-expect-error
        prefix={<Icon2 name="link" />}
        suffix={
          <CopyIcon
            tooltipText="Copied"
            copiedText={link}
            className="text-bronze.500"
          />
        }
      />
      <div className="mt-[20px] flex gap-[16px] justify-center">
        <FacebookShareButton url={link}>
          <FacebookIcon round size={40} />
        </FacebookShareButton>
        <TelegramShareButton url={link}>
          <TelegramIcon round size={40} />
        </TelegramShareButton>
        <WhatsappShareButton url={link}>
          <WhatsappIcon round size={40} />
        </WhatsappShareButton>
        <LinkedinShareButton url={link}>
          <LinkedinIcon round size={40} />
        </LinkedinShareButton>
      </div>
    </Modal>
  )
}
