import styled, { css } from 'styled-components/macro'
import { Icon2 } from 'ui/atoms'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  &[data-disabled='true'] {
    cursor: no-drop;
    opacity: 0.75;
  }
`

export const UploadIcon = styled(Icon2)`
  width: 14px;
  height: 14px;
  color: var(--color-sunray-500);
`

export const LabelIcon = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: none;
  background-color: var(--color-sunray-100);
  transition: var(--transition);
`

export const LabelImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  pointer-events: none;
  padding: 0 10px;
  transition: var(--transition);
`

export const Files = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
`

export const LocalFile = styled.div<{ image: string | null }>`
  width: 140px;
  height: 140px;
  display: block;
  position: relative;
  transition: var(--transition);

  &:before {
    content: '';
    background-image: url(${(p) => p.image});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
    width: 140px;
    height: 140px;
    position: absolute;
    z-index: 3;
    top: 0;
    inset-inline-start: 0;
    border-radius: 8px;
  }

  embed {
    width: 140px;
    height: 140px;
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    border-radius: 8px;
  }

  &[data-loading='true'] {
    opacity: 0.75;
  }

  &[data-loading='false'] {
    opacity: 1;
  }

  &[data-error='true'] {
    margin-bottom: 24px;
  }

  &[data-disabled='true'] {
    pointer-events: none;
  }
`

export const FileError = styled.div`
  position: absolute;
  bottom: 0;
  inset-inline-start: 0;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-opal-500);
  transform: translateY(calc(100% + 6px));
`

export const Delete = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  inset-inline-end: -7px;
  top: -7px;
  background-color: rgba(38, 33, 25, 0.8);
  color: var(--color-gray-400);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    background-color: rgba(38, 33, 25, 0.6);
  }
`

export const Add = styled.div<{isBorder?: boolean; isLoading?: boolean}>`
  width: 140px;
  height: 140px;
  position: relative;
  border: ${(p) => p.isBorder ? '1.5px dashed var(--color-bronze-500)': ''};
  border-radius: 8px;

  & > span {
    width: 140px;
    height: 140px;
    display: block;
    cursor: pointer;
  }

  &:hover {
    ${LabelIcon} {
      ${(p) => p.isLoading ? '' : css`background-color: var(--color-sunray-200)` }
    }
  }
  &[data-disabled='true'] {
    pointer-events: none;
  }
`

export const MAdd = styled.div<{isBorder?: boolean; isLoading?: boolean}>`
  position: relative;

  & > span {
    height: 100%;
    width: 100%;
    position: absolute;
    display: block;
    cursor: pointer;
  }

  &:hover {
    ${LabelIcon} {
      ${(p) => p.isLoading ? '' : css`background-color: var(--color-sunray-200)` }
    }
  }
  &[data-disabled='true'] {
    pointer-events: none;
  }
`

export const Label = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  inset-inline-start: 0;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const LabelText = styled.div`
  margin-top: 12px;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.8rem;
  text-align: center;
  padding: 0 5px;
  display: flex;
`

export const FilesError = styled.div`
  margin-top: 18px;
  display: flex;
  align-items: center;
  font-size: var(--body-font-size-small);
  line-height: var(--body-line-height-small);
  color: var(--color-opal-500);
`

export const FilesErrorIcon = styled(Icon2)`
  color: var(--color-opal-500);
  margin-inline-end: 6px;
  margin-bottom: -1px;
`
