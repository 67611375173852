import React, { useMemo } from 'react';

import { Text } from '../text';
import * as S from './styled';


export type StatusVariant = 'green' | 'blue' | 'yellow' | 'red' | 'gray';

type Props = {
  text: string;
  withoutTranslation?: boolean;
  variant: StatusVariant;
};

export const Status: React.FC<Props> = ({ text, withoutTranslation, variant }) => {
  return (
    <S.StatusContainer variant={variant}>
      <S.StatusText
        withTranslate={!withoutTranslation}
        level={2}
        weight='medium'
        variant={variant}
      >
        { text }
      </S.StatusText>
    </S.StatusContainer>

  )
}
