// todo: распилить на маленькие компоненты

import { addDays } from 'date-fns'
import { toUnixTimeString, fromDateToIso } from 'libs/dateUtils'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, DatePicker, Select } from 'ui/atoms'
import { AccountLine, WalletLine } from 'ui/molecules'
import { useWindowSize } from 'usehooks-ts'

import { useTransactions } from '../../hooks'
import * as Styled from './styled'

const datePeriods = [
  { label: 'All time', value: 'all' },
  { label: 'Last week', value: '7' },
  { label: 'Last month', value: '30' },
  { label: 'Last 3 months', value: '90' },
];

export const Filters = () => {
  const {
    accounts,
    wallets,
    setTransactionsType,
    setTimeline,
    setAccounts,
    setWallets,
    setStatus,
  } = useTransactions()

  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobileMode = width < 600

  const [accountsSelectValue, setAccountsSelectValue] = React.useState('')
  const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>([])
  const [selectedWallets, setSelectedWallets] = React.useState<string[]>([])

  const [dateSelectValue, setDateSelectValue] = React.useState('')

  const [startDate, setStartDate] = React.useState<Date | null>(null)
  const [endDate, setEndDate] = React.useState<Date | null>(null)

  const changeAccounts = () => {
    setAccounts(selectedAccounts)
    setWallets(selectedWallets)

    setAccountsSelectValue('filled')
  }

  const resetAccounts = () => {
    setSelectedAccounts([]);
    setSelectedWallets([]);

    setAccounts([])
    setWallets([])

    setAccountsSelectValue('')
  }

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates

    setStartDate(start)
    setEndDate(end)
  }

  const changeDates = () => {
    if (startDate && endDate) {
      changeTimeLine('custom')
    }
  }

  const resetDates = () => {
    setStartDate(null)
    setEndDate(null)
    changeTimeLine('all');
  }

  const dateSelectOptions = React.useMemo(() => {
    if (dateSelectValue !== 'custom') return [...datePeriods, { label: 'Custom date', value: 'custom', hidden: true }];

    return [...datePeriods, { label: dateSelectValue, value: 'custom', hidden: true }];
  }, [dateSelectValue]);


  const changeTimeLine = (newDateSelect: string) => {
    // todo: перенести в хук @_@
    if (newDateSelect) {
      
      if (newDateSelect === 'custom') {
        setDateSelectValue(`${fromDateToIso(startDate!)}-${fromDateToIso(endDate!)}`);
      }

      if (newDateSelect !== 'custom') {
        setStartDate(null)
        setEndDate(null)
        setDateSelectValue(newDateSelect);
      }

      setTimeline({
        from:
        newDateSelect === 'all'
            ? '0'
            : newDateSelect === 'custom'
            ? toUnixTimeString(startDate as Date)
            : toUnixTimeString(addDays(new Date(), -newDateSelect)),
        to:
        newDateSelect === 'custom'
            ? toUnixTimeString(endDate as Date)
            : toUnixTimeString(new Date()),
      })
    }
  }

  return (
    <Styled.Wrapper>
      <Select
        placeholder="Transaction Type"
        autoComplete="off"
        options={[
          { label: 'All types', value: 'all' },
          { label: 'Deposit', value: 'deposit' },
          { label: 'Withdrawal', value: 'withdrawal' },
          { label: 'Internal transfer', value: 'internal_transfer' },
        ]}
        inputMode='none'
        readOnly
        isHaveSearch={false}
        onSelect={setTransactionsType}
        
      />
      <Select
        value={dateSelectValue}
        placeholder="Timeframe"
        autoComplete="off"
        options={dateSelectOptions}
        onSelect={changeTimeLine}
        optionsWidth={isMobileMode ? undefined : 375}
        maxHeight={690}
        customContent={
          <Styled.DatePickerWrapper>
            <DatePicker
              selected={startDate}
              // @ts-expect-error
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              // @ts-expect-error
              selectsRange
            />
            {startDate && endDate && (
              <Styled.PickerButtons>
                <Button
                  name="cancel"
                  size="medium"
                  design="tertiary"
                  onClick={resetDates}
                >
                  Cancel
                </Button>
                <Button name="cancel" size="medium" onClick={changeDates}>
                  Select
                </Button>
              </Styled.PickerButtons>
            )}
          </Styled.DatePickerWrapper>
        }
      />
      <Select
        value={accountsSelectValue}
        placeholder="Account / Wallet"
        autoComplete="off"
        readOnly
        optionsWidth={isMobileMode ? undefined : 343}
        maxHeight={2000}
        options={[
          {
            value: 'filled',
            label: `Account / Wallet (${
              selectedAccounts.length + selectedWallets.length
            })`,
            hidden: true,
          },
        ]}
        customContentComp={({ onCloseSelectMenu }) =>
          <Styled.AccountsWrapper>
            {wallets && wallets.length > 0 && (
              <div>
                <Styled.ContentTitle>{t('Wallets')}</Styled.ContentTitle>
                <div>
                  <Styled.SelectOption>
                    <Checkbox
                      design="brand"
                      checked={selectedWallets.length === wallets.length}
                      onChange={(checked) =>
                        checked
                          ? setSelectedWallets(
                              wallets.map((wallet) => wallet.walletID),
                            )
                          : setSelectedWallets([])
                      }
                    />
                    {t('All Wallets')}
                  </Styled.SelectOption>
                  {wallets?.map((wallet) => (
                    <Styled.SelectOption key={wallet.walletID}>
                      <Checkbox
                        design="brand"
                        checked={selectedWallets.indexOf(wallet.walletID) > -1}
                        onChange={(checked) =>
                          setSelectedWallets(
                            checked
                              ? [...selectedWallets, wallet.walletID]
                              : selectedWallets.filter(
                                  (id) => id !== wallet.walletID,
                                ),
                          )
                        }
                      />
                      <WalletLine {...wallet} />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
            {accounts && accounts.length > 0 && (
              <div>
                <Styled.ContentTitle>{t('Accounts')}</Styled.ContentTitle>
                <div>
                  <Styled.SelectOption>
                    <Checkbox
                      design="brand"
                      checked={selectedAccounts.length === accounts.length}
                      onChange={(checked) =>
                        checked
                          ? setSelectedAccounts(
                              accounts.map((account) => account.accountID),
                            )
                          : setSelectedAccounts([])
                      }
                    />
                    {t('All Accounts')}
                  </Styled.SelectOption>
                  {accounts.map((account) => (
                    <Styled.SelectOption key={account.accountID}>
                      <Checkbox
                        design="brand"
                        checked={
                          selectedAccounts.indexOf(account.accountID) > -1
                        }
                        onChange={(checked) =>
                          setSelectedAccounts(
                            checked
                              ? [...selectedAccounts, account.accountID]
                              : selectedAccounts.filter(
                                  (id) => id !== account.accountID,
                                ),
                          )
                        }
                      />
                      <AccountLine {...account} />
                    </Styled.SelectOption>
                  ))}
                </div>
              </div>
            )}
            <Styled.Buttons>
              <Button
                name="cancel"
                size="medium"
                design="tertiary"
                onClick={() => {
                  resetAccounts();
                  onCloseSelectMenu();
                }}
              >
                Cancel
              </Button>
              <Button name="cancel" size="medium" onClick={() => {
                changeAccounts();
                onCloseSelectMenu();
              }}
              >
                Select
              </Button>
            </Styled.Buttons>
          </Styled.AccountsWrapper>
        }
      />
      <Select
        placeholder="Status"
        autoComplete="off"
        inputMode='none'
        isHaveSearch={false}
        onSelect={setStatus}
        readOnly
        inputClasses='lg:max-w-[200px]'
        options={[
          { label: 'Any status', value: 'all' },
          { label: 'Pending', value: 'OS_PENDING' },
          { label: 'Missed information', value: 'OS_MISSING_INFORMATION' },
          { label: 'Declined', value: 'OS_REJECTED' },
          { label: 'Approved', value: 'OS_SUCCESS' },
        ]}
      />
    </Styled.Wrapper>
  )
}
