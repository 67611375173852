import { useWindowSize } from '@uidotdev/usehooks'
import { CountryName } from 'pages/contest/molecules/countryName'
import { useTranslation } from 'react-i18next'
import { Contest, LeaderboardRow } from 'services/contests/types'
import { Icon2, Money } from 'ui/atoms'

import { icons } from '../../images/icons'
import * as Styled from './styled'

const TableRow = (
  props: LeaderboardRow & { isMobile: boolean; isShort: boolean },
) => {
  const [t] = useTranslation()

  return (
    <Styled.TableRow selected={props.isYou} isShort={props.isShort}>
      <Styled.PlaceWrapper>
        {props.place > 10 ? (
          props.place
        ) : (
          <img
            src={icons[`smallMedal_${props.place}`]}
            alt={`${props.place}`}
          />
        )}
      </Styled.PlaceWrapper>
      <Styled.NameWrapper>
        {props.isYou ? t('You') : props.name}
        <CountryName code={props.country} />
      </Styled.NameWrapper>
      {props.team && (
        <Styled.TeamWrapper>
          {props.isMobile && <Styled.CellTitle>{t('Team')}</Styled.CellTitle>}
          <div className="flex items-center gap-[8px]">
            <img src={props.team.icon.web} alt={props.team.name} />
            {props.team.name}
          </div>
        </Styled.TeamWrapper>
      )}
      <Styled.RegularCell>
        {props.isMobile && <Styled.CellTitle>{t('Equity')}</Styled.CellTitle>}
        <Money value={+props.equity} />
      </Styled.RegularCell>
      <Styled.RegularCell>
        {+props.prize ? (
          <>
            {props.isMobile && (
              <Styled.CellTitle>{t('Prize')}</Styled.CellTitle>
            )}
            <Money value={+props.prize} minimumFractionDigits={0} />
          </>
        ) : null}
      </Styled.RegularCell>
    </Styled.TableRow>
  )
}

export const RatingTable = (
  props: Pick<Contest, 'leaderboard'> & { isFull: boolean; isEvent: boolean },
) => {
  const [t] = useTranslation()

  const { width } = useWindowSize()
  const isMobileMode = width && width < 800

  if (!props.leaderboard) {
    return null
  }

  return (
    <Styled.Container>
      <Styled.Title level={2}>Leaderboard</Styled.Title>
      <div>
        {!isMobileMode && (
          <Styled.TableHead isShort={!props.isEvent}>
            <div>{t('Place')}</div>
            <div className="ps-[16px] text-left">{t('Participant')}</div>
            {props.isEvent && <div>{t('Team')}</div>}
            <div>{t('Equity')}</div>
            <div>{t('Prize')}</div>
          </Styled.TableHead>
        )}
        {props.leaderboard?.items.map((leader) => (
          <TableRow
            {...leader}
            key={leader.place}
            isMobile={!!isMobileMode}
            isShort={!props.isEvent}
          />
        ))}
        {props.isFull && props.leaderboard.current?.place > 10 && (
          <>
            <Styled.TopTraders>
              <Icon2 name="arrowLongUpIcon" size="small" />
              {t('Top traders')}
              <Icon2 name="arrowLongUpIcon" size="small" />
            </Styled.TopTraders>
            <div>
              <TableRow
                {...props.leaderboard.current}
                isMobile={!!isMobileMode}
                isShort={!props.isEvent}
              />
            </div>
          </>
        )}
      </div>
    </Styled.Container>
  )
}
