import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';


export type ChipVariant = 'gray';

interface Props extends PropsWithChildren {
  variant?: ChipVariant;
  className?: string;
  onClick?: () => void;
}

export const Chip: React.FC<Props> = ({ variant = 'gray', children, onClick, className }) => {
  return (
    <ChipUi
      onClick={onClick}
      variant={variant}
      className={className}
    >
      { children }
    </ChipUi>
  )
}


const ChipUi = styled.div<{ variant: ChipVariant}>`
  height: 36px;
  min-width: 56px;
  padding: 10px 12px;
  border-radius: 4px;
  background-color: var(--color-gray-300);
  cursor: pointer;
  /* width: calc((100% - 16px) / 2 ); */
`;