import React from 'react';
import { useStore } from 'effector-react';

import { getDateFromUnix } from 'libs/dateUtils';
import { $tableSortStore, changeSortEvent, changeFtdEvent, changeVerifyEvent, $tableFilterStore } from './model';

import { Text } from 'ui/atoms';
import { THead, Td, TdHead, TableWrapper, SortSelect } from 'ui/molecules';
import { PartnerClientsList, PartnerClientsItem } from 'services/ib/get/types/partnerClientsTypes';
import { Icons } from 'ui/atoms/icon2';
import { changeFtdModal, changeVerificationModal } from './modals/modalState';
import { useIsMobile } from 'libs/hooks/useIsMobile';


interface Props {
  items?: PartnerClientsItem[];
  totals?: PartnerClientsList['totals'];
}

const subHead = [
  { title: 'Name' }, 
  { title: 'Joining date', name: 'FIELD_JOINING_DATE', sortable: true }, 
  { title: 'Lots', name: 'FIELD_LOTS', sortable: true }, 
  { 
    title: 'Verification', 
    selectIcon: 'funnel', 
    name: 'verification',
    sortable: false,
    onChangeSort: changeVerifyEvent,
    selectOption: [
      { label: 'All status', value: 'VERIFICATION_ALL' },
      { label: 'Not verified', value: 'VERIFICATION_NOT_VERIFIED' },
      { label: 'Verified', value: 'VERIFICATION_VERIFIED' }
    ]
  }, 
  { 
    title: 'FTD',
    selectIcon: 'funnel',
    name: 'ftd',
    sortable: false,
    onChangeSort: changeFtdEvent,
    selectOption: [
      { label: 'All', value: 'FTD_ALL' },
      { label: 'Yes', value: 'FTD_YES' },
      { label: 'No', value: 'FTD_NO' }
    ]
  }, 
  { title: 'Comission', name: 'FIELD_COMMISSION', sortable: true }, 
  { title: 'Cashback paid', name: 'FIELD_CASHBACK', sortable: true }, 
  { title: 'Deposit', name: 'FIELD_DEPOSIT', sortable: true },
  { title: 'Withdrawal', name: 'FIELD_WITHDRAWAL', sortable: true},
];

export const ClientsTable: React.FC<Props> = ({ items = [], totals }) => {
  const sort = useStore($tableSortStore);
  const filter = useStore($tableFilterStore);

  const isMobile = useIsMobile();

  const getIsActiveSort = (fieldName: string) => {
    return fieldName === 'ftd' ? !!filter.ftd : !!filter.verification;
  }

  const onClickSortCell = (fieldName: string) => {
    if (fieldName === 'ftd') {
      return () => changeFtdModal(true);
    }

    return () => changeVerificationModal(true);
  }

  
  if (!items && !totals) return null;

  return (
    <TableWrapper className='w-full'>
      <table className='w-full min-h-[140px]' border={1}>
        { totals && 
          <THead
            trClasses='border-b-[0.75px] border-gray.300 border-solid bg-gray.300'
            heads={[
              { label: totals.clients || '0' },
              { label: '' },
              { label: totals.lots || 0 },
              { label: '' },
              { label: '' },
              { label: totals.commission || '0'
                ? `$${totals.commission}`
                : '0'
              },
              { label: totals.cashback
                ? `$${totals.cashback}`
                : '0'
                },
              { label: totals.deposit ? `$${totals.deposit}` : '0' },
              { label: totals.withdrawal ? `$${totals.withdrawal}` : '0' },
            ]}
          />
        }
        <tbody>
          <tr className='border-b-[0.75px] border-gray.300 border-solid'>
            {subHead.map((sub, index) => (
              <TdHead
                key={index}
                sortable={ sub.sortable }
                name={sub.name}
                isEmptySort={ !sort }
                sortDirection={ sort?.name === sub.name ? sort?.direction : 'asc'}
                isSortActive={ sort?.name === sub.name }
                onChangeSort={changeSortEvent}
                className={'relative whitespace-nowrap'}
              >
                <Text className={'!font-medium'} level={2}>{sub.title}</Text>
                {sub.selectIcon && 
                  <SortSelect
                    iconName={sub.selectIcon as Icons}
                    options={sub.selectOption}
                    isActive={getIsActiveSort(sub.name)}
                    onChangeSort={sub?.onChangeSort}
                    value={sub.name === 'ftd' ? filter.ftd : filter.verification }
                    onClickSortCell={onClickSortCell(sub.name)}
                  />
                }
              </TdHead>))
            }
          </tr>
          {items?.map((item, index) => (
            <tr 
              key={`${item.joiningTs}_${index}`} 
              className={`${index === (items.length -1) ? '' : 'border-b-[0.75px]'}  border-gray.300 border-solid`}
            >
              <Td>
                <Text level={2}>{item.name}</Text>
              </Td>
              <Td>
                <Text level={2}>{getDateFromUnix(+item?.joiningTs, 'dd.MM.yyyy')}</Text>
              </Td>
              <Td>
                <Text level={2}>{item.lots}</Text>
              </Td>

              <Td>
                <Text level={2}>{item.isVerified ? 'Yes' : 'No'}</Text>
              </Td>
              <Td>
                <Text level={2}>{item.isFTD ? 'Yes' : 'No'}</Text>
              </Td>

              <Td>
                <Text level={2}>{item.commission && `$${item.commission}`}</Text>
              </Td>
              <Td>
                <Text level={2}>{item.cashback && `$${item.cashback}`}</Text>
              </Td>

              <Td>
                <Text level={2}>{item.deposit && `$${item.deposit}`}</Text>
              </Td>

              <Td>
                <Text level={2}>{item.withdrawal && `$${item.withdrawal}`}</Text>
              </Td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableWrapper>
  )
}
