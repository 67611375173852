import React, { useMemo } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ServerFile, UploadFiles } from 'ui/molecules';
import { WithdrawalCardItem } from 'services/payments/get/types';
import { isEmpty } from 'libs/isEmpty';
import { DefaultUploadPlace } from 'ui/molecules/UploadFiles/upload/component';

import CardFront from 'pages/money/img/cardFront.svg';
import CardFrontWebp from 'pages/money/img/cardFront.webp';
import CardBack from 'pages/money/img/cardBack.svg';
import CardBackWebp from 'pages/money/img/cardBack.webp';
import { Hint, Title } from 'ui/atoms';


export const UploadCardFiles = () => {
  const chooseCard: WithdrawalCardItem | undefined = useWatch({name: 'cardItem'});
  const [t] = useTranslation();

  const { fields, append, remove } = useFieldArray({
    name: 'fileNames',
  });

  const onChangeFiles = (files: ServerFile) => {
    append(files);
  }

  const onDeleteFile = (index: number) => {
    remove(index)
  }

  const defaultUploadPlace = useMemo((): DefaultUploadPlace[] => ([
    { 
      title: t('Upload Front') as string, 
      titleIcon: 'cloudArrowUp' as const,
      img: {
        main: CardFront,
        webp: CardFrontWebp
      },
      id: '1_card'
    }, 
    { 
      title: t('Upload Back') as string, 
      titleIcon: 'cloudArrowUp' as const,
      img: {
        main: CardBack,
        webp: CardBackWebp,
      },
      id: '2_card',
    }
  ]), [t]);
  
  if (isEmpty(chooseCard)) return null;
  if (chooseCard?.isVerified) return null;

  return (
    <div className='mt-[3rem]'>
        <UploadFiles
          uploadUrl='payments-files/v1/card-verification'
          title={
            <div className='flex justify-between items-center'>
            <Title level={3}>Upload photos</Title>
            <Hint
              iconClassName='!stroke-bronze.500'
              textContent={t(
                "To make a withdrawal, verify the bank card which you used for a deposit. It is required by your bank to ensure the operations' security."
              )}
            >
            </Hint>
          </div>
          }
          
          onChangeFiles={onChangeFiles}
          onDeleteFile={onDeleteFile}
          maxSize='10'
          files={fields as any}
          isHideAddButton
          defaultUploadPlace={defaultUploadPlace}
          subTitle={'Upload front and back photos of your bank card. Please hide the first 12 digits of the card number and CVV.'}
        />
    </div>
  )
}
