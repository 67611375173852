import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Location } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Icon } from '../icon'
import { Size } from '../types'

export type ButtonDesign =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'tertiary-brand'
  | 'dangerous'

export type Props = {
  name?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children?: React.ReactNode
  className?: string
  type?: 'submit' | 'button' | 'reset'
  design?: ButtonDesign
  size?: Size
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  loading?: boolean
  disabled?: boolean
  to?: string
  href?: string
  target?: string
  state?: Location['state']
  download?: boolean | string
}

export const Button = forwardRef(
  (
    {
      className,
      onClick,
      children,
      type = 'button',
      size = 'large',
      prefix,
      suffix,
      design = 'primary',
      loading = false,
      disabled,
      name,
      href,
      to,
      download,
      target,
      state,
    }: Props,
    ref,
  ) => {
    let text = children

    const [t] = useTranslation()

    if (typeof children === 'string') text = t(children)

    return (
      <ButtonWrap
        as={href ? 'a' : to ? Link : 'button'}
        id={`${name}-${type}`}
        onClick={loading ? undefined : onClick}
        name={name}
        className={className}
        type={type}
        ref={ref as any}
        data-size={size}
        data-design={design}
        disabled={disabled}
        href={href}
        to={to}
        download={download}
        state={state}
        target={target}
      >
        {loading && (
          <ButtonSpinner
            name="spinner"
            // refctor        data-visible={loading}
            data-visible={loading}
            disabled={disabled}
          />
        )}
        <ButtonText data-visible={!loading}>
          {prefix && <ButtonPrefix hasText={text}>{prefix}</ButtonPrefix>}
          {text}
          {suffix && <ButtonSuffix hasText={text}>{suffix}</ButtonSuffix>}
        </ButtonText>
      </ButtonWrap>
    )
  },
)

const ButtonWrap = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color var(--transition);
  white-space: nowrap;
  cursor: pointer;

  &[data-size='large'] {
    padding: 14px 24px;
    font-size: 1.6rem;
    line-height: 1.8rem;
    height: 48px;
  }

  &[data-size='medium'] {
    padding: 12px 20px;
    font-size: 1.6rem;
    line-height: 1.8rem;
    height: 44px;
  }

  &[data-size='small'] {
    padding: 8px 12px;
    height: 36px;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  &[data-design='primary'] {
    background-color: var(--color-sunray-500);

    &:hover {
      background-color: var(--color-sunray-600);
    }

    &:active {
      background-color: var(--color-sunray-700);
    }

    &:disabled {
      background-color: var(--color-sunray-200);
      cursor: no-drop;
    }
  }

  &[data-design='secondary'] {
    background-color: var(--color-gray-300);

    &:hover {
      background-color: var(--color-gray-200);
    }

    &:disabled {
      background-color: var(--color-gray-300);
      cursor: no-drop;
    }
  }

  &[data-design='tertiary'] {
    background-color: var(--color-gray-100);

    &:hover {
      background-color: var(--color-gray-200);
    }

    &:disabled {
      background-color: var(--color-gray-100);
      color: var(--color-gray-500);
      cursor: no-drop;
    }
  }

  &[data-design='tertiary-brand'] {
    color: var(--color-bronze-500);
    background-color: var(--color-gray-100);

    &:hover {
      background-color: var(--color-gray-200);
    }

    &:active {
      color: var(--color-bronze-600);
      background-color: var(--color-gray-300);
    }

    &:disabled {
      color: var(--color-bronze-300);
      background-color: var(--color-gray-100);
      cursor: no-drop;
    }
  }

  &[data-design='dangerous'] {
    color: var(--color-gray-700);
    background-color: var(--color-opal-300);

    &:hover {
      background-color: var(--color-opal-400);
    }

    &:active {
      background-color: var(--color-opal-500);
    }

    &:disabled {
      background-color: var(--color-gray-300);
      cursor: no-drop;
    }
  }
`

const ButtonPrefix = styled.span<{ hasText: React.ReactNode }>`
  padding-inline-end: ${(p) => (p.hasText ? '8px' : '0')};
`

const ButtonSuffix = styled.span<{ hasText: React.ReactNode }>`
  padding-inline-start: ${(p) => (p.hasText ? '8px' : '0')};
`

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color var(--transition);
  opacity: 1;

  &[data-visible='false'] {
    opacity: 0;
  }
`

const ButtonSpinner = styled(Icon)`
  width: 26px;
  height: 26px;
  animation: spin 0.3s linear infinite;
  animation-delay: 0.1s;
  position: absolute;
  transition: var(--transition);
  opacity: 1;
`
