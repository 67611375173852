import { Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'

import { useTarget } from '../../hooks'

export const ChangeTargetModal = ({
  giftId,
  isOpen,
  onClose,
}: {
  giftId?: number
  isOpen: boolean
  onClose: () => void
}) => {
  const { mutateAsync, isLoading } = useTarget()

  const handleOk = async () => {
    await mutateAsync(giftId as number)

    onClose()
  }

  return (
    <Modal
      title="Change the target gift"
      open={isOpen}
      onClose={onClose}
      onOk={handleOk}
      okLabel="Yes"
      cancelLabel="No"
      okButtonProps={{ disabled: isLoading, loading: isLoading }}
    >
      <Text className="!text-gray.600">
        Are you sure you want to add a new target?
      </Text>
    </Modal>
  )
}
