import { useQuery } from '@tanstack/react-query'
import { axios } from 'services/axios'

import type { Contest } from '../types'

export const GET_CONTEST_DETAILS = ['contest-details']

const getСontestDetails = async (contestName: string) => {
  const res = await axios.get<Contest>('/demo-contests/v1/info', {
    params: { contestName },
  })

  return res.data
}

export const useGetContestDetails = (contestName: string) => {
  return useQuery({
    queryKey: GET_CONTEST_DETAILS,
    queryFn: () => getСontestDetails(contestName),
    keepPreviousData: true
  })
}
