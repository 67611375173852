import React, { useEffect } from 'react'

import { Operation } from 'services/transaction-history/types'
import { Pagination } from 'ui/atoms'

import { useIsMobile } from 'libs/hooks/useIsMobile'
import { useTransactions } from './hooks'
import { Filters } from './molecules/filters'
import { NotFound } from './molecules/notFound'
import { Transaction } from './molecules/transaction'
import * as Styled from './styled'


export const TransactionHistory = () => {
  const {
    transactions,
    page,
    perPage,
    total,
    handlePaginationChange,
    resetFilters,
  } = useTransactions();

  const isMobile = useIsMobile();

  useEffect(() => {
    return () => resetFilters()
  }, []);
  
  return (
    <Styled.OuterWrapper>
      <Filters />
      {transactions.length === 0 ? (
        <NotFound hasFilters />
      ) : (
        transactions.map(([date, items], index) => (
          <div key={index}>
            <Styled.Date>{date}</Styled.Date>
            <Styled.InnerWrapper>
              {items.map((item: Operation) => (
                <Transaction key={item.operationID} data={item} />
              ))}
            </Styled.InnerWrapper>
          </div>
        ))
      )}
      {total > perPage && (
        <Pagination
          pageSize={perPage}
          current={page}
          total={total}
          onChange={handlePaginationChange}
          showLessItems={ isMobile }
          showSizeChanger={ !isMobile }
          
        />
      )}
    </Styled.OuterWrapper>
  )
}
