import styled from 'styled-components/macro'
import { Text } from 'ui/atoms';
import { Link } from 'react-router-dom';

import { TraderPreview as TraderPreviewMolecule } from '../traderPreview'

export const ListItem = styled(Link)`
  display: flex;
  height: 140px;
  padding: 16px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--color-gray-300);
  background: var(--color-gray-100);
  position: relative;
  cursor: pointer;
`;

export const TraderPreview = styled(TraderPreviewMolecule)`
  margin-inline-end: auto;
`;

export const GainValue = styled(Text)<{ colorOwnProp: string }>`
  color: ${({ colorOwnProp }) => colorOwnProp};
`;
