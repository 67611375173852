import { toPath } from 'features/router'
import { path } from 'libs/path'

export enum EActiviesIcons {
  profileIsNotVerified = 'checkBadge',
  promoBonus111 = 'currencyDollar',
  firstDeposit = 'arrowDownOnSquare',
  becomePartner = 'userPlus',
  firstLot = 'circleStack',
  exploreMetaTrader = 'metatrader',
}

export enum EActiviesNames {
  promoBonus111 = 'Get the bonus $111',
  firstDeposit = 'Make your first deposit',
  becomePartner = 'Invite friends',
  firstLot = 'Make your first lot',
  exploreMetaTrader = 'Explore MetaTrader',
}

export enum EActivitiesVerificationNames {
  EXPIRATION_DATE_COMING_UP = 'Your document expires on',
  NOT_VERIFIED = 'Verify your account',
  IN_PROGRESS = 'Your verification is in process',
  VERIFIED = 'You are verified',
  EXPIRED = 'Your document has expired',
  REJECTED = 'Verification request was rejected',
}

export enum EActivitiesVerificationIcons {
  EXPIRATION_DATE_COMING_UP = 'exclaimationCircle',
  NOT_VERIFIED = 'checkBadgeBronze',
  IN_PROGRESS = 'verificationClock',
  VERIFIED = 'checkBadge',
  EXPIRED = 'exclaimationCircle',
  REJECTED = 'noSymbol',
}

export const EActiviesOnClick = {
  firstDeposit: () => {
    toPath(path.deposit.entry())
  },
  becomePartner: () => {
    toPath(path.ib.entry())
  },
  exploreMetaTrader: () => {
    window.location.href = '/../platforms'
  },
  promoBonus111: () => {
    document.querySelector('#bonus-111')?.scrollIntoView()
  },
}
