import axios, { AxiosError } from 'axios'
import { ResponseError } from 'libs/request'
import { useEffect, useState } from 'react'
import { useEditProportion } from 'services/copytrade'
import { Modal } from 'ui/molecules/modal_draft'

import { ProportionForm } from '../proportionForm'

export const ProportionModal = ({
  subscriptionID,
  initialValue,
  onSuccess,
  onCancel,
}: {
  subscriptionID: string
  initialValue: number
  onSuccess: () => void
  onCancel: () => void
}) => {
  const [value, setValue] = useState<number>(initialValue)
  const [error, setError] = useState<string>()

  const { isLoading, mutateAsync, isSuccess } = useEditProportion()

  const handleSubmit = async () => {
    try {
      await mutateAsync({
        subscriptionID,
        proportion: value,
      })
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const knownError = error as AxiosError<ResponseError>

        setError(
          knownError.response?.data?.details?.validationErrors.items.find(
            (error) => error.field === 'proportion',
          )?.msg,
        )
      }
    }
  }

  const handleCancel = () => {
    setError(undefined)

    setValue(initialValue)

    onCancel()
  }

  useEffect(() => {
    if (isSuccess) {
      setError(undefined)

      onSuccess()
    }
  }, [isSuccess])

  return (
    <Modal
      title="Proportion value"
      open={true}
      onOk={handleSubmit}
      okLabel="Confirm"
      onClose={handleCancel}
      cancelLabel="Cancel"
      okButtonProps={{ loading: isLoading, disabled: isLoading }}
    >
      <ProportionForm
        initialValue={initialValue}
        error={error}
        onChange={setValue}
      />
    </Modal>
  )
}
