import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { axios } from 'services/axios';
import { retryFn } from 'services/helpers/retryFn';
import { WalletList } from './types';

export const GET_WALLETS_KEY = ['wallets'];

const placeholderData: WalletList = {
  items: [],
};

const getWallet = async () => {
  const res = await axios.get<WalletList>(`/wallet/v1/wallets`);

  return res.data;
};

type Options = {
  enabled?: boolean;
}

export const useGetWallets = ({ enabled }: Options): UseQueryResult<WalletList> => {
  return useQuery({
    queryKey: GET_WALLETS_KEY,
    queryFn: getWallet,
    retry: retryFn,
    staleTime: 300,
    placeholderData,
    enabled,
  })
};
