import { createEvent, createStore, restore, sample } from 'effector'
import { toPath } from 'features/router'
import { ClientError, mapResponseError, path } from 'libs'
import { ChangePasswordProps, service } from 'services'

// set passwords
export const setUserPassword = createEvent<string>()
export const $userPassword = restore(setUserPassword, '')

export const $oldPassword = createStore<string>('')

sample({
  clock: service.auth.getCheckResetPasswordCode.doneData,
  fn: (doneData) => doneData.body.identificationResult.generatedPassword,
  target: [$oldPassword],
})

export const submitForm = createEvent()

// if password changed by user

sample({
  source: [$userPassword, $oldPassword],
  clock: submitForm,
  fn: ([userPassword]) => {
    const action = 'RESET' as const
    return {
      body: {
        action,
        newPassword: userPassword.trim(),
      },
    }
  },
  target: service.auth.postChangePassword,
})

sample({
  clock: service.auth.postChangePassword,
  filter: ({ body }) => body?.action === 'RESET',
  target: toPath.prepend(path.register.password.recovery.success),
})

// handle errors

export const $error = createStore<ClientError<ChangePasswordProps> | null>(null)

$error
  .on(service.auth.postChangePassword.failData, (_, failData) =>
    mapResponseError<ChangePasswordProps>(failData.body),
  )
  .reset(submitForm)
