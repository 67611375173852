import { path } from 'libs/path'
import { useTranslation } from 'react-i18next'
import { Strategy } from 'services/copytrade/types'
import { useWindowSize } from 'usehooks-ts'

import { formatNumber, getNumberColor } from '../../utils'
import { CopyingLabel } from '../copyingLabel'
import { InteractButtons } from '../interactButtons'
import { RiskLabel } from '../riskLabel'
import { WinRateProgress } from '../winRateProgress'
import * as Styled from './styled'

export const TraderCard = (props: Strategy) => {
  const [t] = useTranslation()

  return (
    <Styled.Container to={path.copytrade.strategyForInvestor(props.strategyID)}>
      {props.isCopying ? <CopyingLabel /> : null}
      <Styled.TopRow>
        <Styled.TraderPreview
          name={props.strategyTitle}
          imageUrl={props.strategyImageLink}
          achievementTitle={props.achievement?.title}
        />
        <RiskLabel risk={props.risk} size="medium" />
        <InteractButtons strategyData={props} />
      </Styled.TopRow>
      <Styled.MiddleRow>
        <div>
          <div className="font-medium text-body.medium text-gray.600 leading-body.small">
            {t('Gain')}
          </div>
          <Styled.GainValue
            level={2}
            color={getNumberColor(props.gain)}
            withTranslate={false}
          >
            {+props.gain > 0 ? '+' : ''}
            {formatNumber(props.gain)}%
          </Styled.GainValue>
        </div>
        <div className="grid place-items-end">
          <div className="py-[2px] flex gap-[4px] text-body.small leading-body.small text-gray.600 font-medium">
            {t('Investors')}
            <span className="text-gray.700">
              {formatNumber(props.numInvestors)}
            </span>
          </div>
          <div className="py-[2px] flex gap-[4px] text-body.small leading-body.small text-gray.600 font-medium">
            {t('Commission')}
            <span className="flex gap-[4px]">
              {+props.percentCommission && +props.commission ? (
                <>
                  <span className="text-gray.700">
                    ${formatNumber(props.commission)}
                  </span>
                  <span>{t('or')}</span>
                  <span className="text-gray.700">
                    {formatNumber(props.percentCommission)}%
                  </span>
                </>
              ) : +props.percentCommission ? (
                <span className="text-gray.700">
                  {formatNumber(props.percentCommission)}%
                </span>
              ) : (
                <span className="text-gray.700">
                  ${formatNumber(props.commission)}
                </span>
              )}
            </span>
          </div>
        </div>
      </Styled.MiddleRow>
      <Styled.BottomRow>
        <div className="mb-[8px] flex gap-[5px] text-body.small leading-body.small text-gray.600 font-medium">
          {t('Win rate')}
          <span className="text-gray.700">{props.winRate}%</span>
        </div>
        <WinRateProgress
          profitProgress={+props.winRate}
          profit={props.profit}
          loss={props.loss}
          profitableOrders={props.numProfitableOrders}
          lossOrders={props.numLossOrders}
          valuesPosition="bottom"
        />
      </Styled.BottomRow>
    </Styled.Container>
  )
}
