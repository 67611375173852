import Tippy from '@tippyjs/react'
import { useWindowSize } from '@uidotdev/usehooks'
import i18next from 'i18next'
import { ReactNode, useState } from 'react'

import * as Styled from './styled'

export const Hint = ({
  children,
  title,
  iconClassName,
  textContent,
}: {
  children: ReactNode
  title?: string
  iconClassName?: string;
  textContent: string
}) => {
  const userLanguage =
    i18next.language.slice(0, 2) || localStorage.i18nextLng.slice(0, 2) || 'en'

  const { width } = useWindowSize()
  const isMobile = width && width < 600

  const [isVisible, setIsVisible] = useState(false)

  return (
    <Styled.Wrapper
      className="flex items-center cursor-pointer"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      <Tippy
        content={textContent}
        placement={isMobile ? 'top' : userLanguage === 'ar' ? 'left' : 'right'}
        popperOptions={{
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: isMobile
                  ? ['bottom']
                  : ['bottom', 'left', 'right'],
              },
            },
          ],
        }}
        interactive
        visible={isVisible}
        maxWidth={256}
        offset={[0, 10]}
      >
        <Styled.Icon
          width="20"
          height="20"
          viewBox="0 0 20 20"
          // className={iconClassName}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.23242 6.2657C9.20873 5.41143 10.7916 5.41143 11.768 6.2657C12.7443 7.11998 12.7443 8.50502 11.768 9.3593C11.598 9.50798 11.4097 9.63079 11.2094 9.72772C10.588 10.0285 10.0002 10.5596 10.0002 11.25V11.875M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10ZM10 14.375H10.0063V14.3813H10V14.375Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={iconClassName}
          />
        </Styled.Icon>
      </Tippy>
    </Styled.Wrapper>
  )
}
