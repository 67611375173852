import { yupResolver } from '@hookform/resolvers/yup'
import { useUnit } from 'effector-react'
import { useIsMobile } from 'libs/hooks/useIsMobile'
import { isEmpty } from 'libs/isEmpty'
import { path } from 'libs/path'
import { yup } from 'libs/validators'
import {
  BenefitsImage,
  BenefitsImages,
  Footer,
  Mondatory,
  MondatoryHeader,
  Person,
} from 'pages/dashboard/verification/pages/request/styled'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { usePutUpdateDocuments } from 'services/verification/mutations/usePutUpdateDocuments'
import { Banner, Button, List, ModalHint, Text, Title } from 'ui/atoms'
import { FormInput, ServerFile } from 'ui/molecules'
import { $isLoadingFiles } from 'ui/molecules/UploadFiles/upload/models/loadingFileStore'

import {
  blurry,
  blurryWeb,
  clear,
  clearWeb,
  cropped,
  croppedWeb,
  reflective,
  reflectiveWeb,
} from '../request/images'
import { UploadDocs } from './UploadDocs'

type FormModel = {
  fileNames: ServerFile[]
  documentNumber: string
}

export const UpdateDocs = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const { mutateAsync, isError, isLoading } = usePutUpdateDocuments()
  const isLoadingFiles = useUnit($isLoadingFiles)

  const { formState, watch, handleSubmit, control, register } =
    useForm<FormModel>({
      defaultValues: {
        documentNumber: '',
        fileNames: [],
      },
      mode: 'onTouched',
      resolver: yupResolver(yup.updateDocs as any),
      shouldUnregister: false,
    })

  const errors = formState.errors

  const filesWatch = watch('fileNames')

  const isDisabled = false
  const isButtonDisabled =
    filesWatch?.length < 1 || !isEmpty(errors) || isLoadingFiles

  const onSbmForm = async (fields: FormModel) => {
    if (!fields.documentNumber) return

    try {
      const fileNames = fields.fileNames?.map((file) => file.fileName)
      await mutateAsync({
        documentNumber: fields.documentNumber as string,
        fileNames,
      })

      navigate(path.dashboard.profile.entry())
    } catch (error) {}
  }

  return (
    <>
      <Banner
        design="sunray-dark"
        title={'Your document is expired'}
        icon="exclamationTriangleSolid"
        textClasses={isMobile ? '!text-[14px]' : undefined}
        titleClaases={isMobile ? '!text-[14px]' : undefined}
      >
        Your verification request was declined because your document is expired.
        Please, upload a valid document.
      </Banner>

      <form className="flex gap-[32px] flex-col">
        <Person>
          <FormInput
            label="ID number"
            autoComplete="off"
            className="!w-[36rem]"
            placeholder="ID number"
            errorMessage={errors.documentNumber?.message}
            disabled={isDisabled}
            {...register('documentNumber')}
          />
        </Person>

        {<UploadDocs control={control} />}

        <Mondatory>
          <MondatoryHeader className={'lg:items-start items-center'}>
            <Title
              level={isMobile ? 4 : 3}
              className={isMobile ? '!text-[14px] !leading-[21px]' : undefined}
            >
              Mandatory requirements for all documents
            </Title>
            <ModalHint title="Upload only high-quality images">
              <BenefitsImages>
                {[
                  [clear, clearWeb],
                  [blurry, blurryWeb],
                  [reflective, reflectiveWeb],
                  [cropped, croppedWeb],
                ].map((src, key) => (
                  <BenefitsImage src={src[0]} srcSet={src[1]} key={key} />
                ))}
              </BenefitsImages>
            </ModalHint>
          </MondatoryHeader>
          <List
            itemClasses={isMobile ? '!text-[12px] !leading-[18px]' : undefined}
            items={[
              'The validity period does not exceed the current date.',
              'The photo has not been modified in a graphic editor.',
              'The document is clearly visible, the photo is not blurry.',
            ]}
          />
        </Mondatory>
        <Footer>
          <div>
            <Button
              name="verification-request"
              type="submit"
              loading={isLoading}
              disabled={isButtonDisabled || isLoading}
              onClick={handleSubmit(onSbmForm)}
            >
              Submit request
            </Button>
            {isError && (
              <Text level={3} className="!text-opal.500 !font-medium">
                Tech error. Try again later
              </Text>
            )}
          </div>
        </Footer>
      </form>
    </>
  )
}
