import styled from 'styled-components/macro'
import { Button } from 'ui/atoms'

import close from '../images/close-icon.svg'

export const ContentContainer = styled.div`
  position: relative;
  margin-top: 24px;
`

export const TopWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AddButton = styled(Button)`
  @media (max-width: 599px) {
    position: absolute;
    right: 0;
    bottom: calc(100% + 20px);
  }
`

export const SliderWrapper = styled.div`
  max-width: 1100px;
  display: grid;
  grid-template-columns: 150px minmax(0, 1fr);

  @media (max-width: 1440px) {
    max-width: calc(100vw - 340px);
  }

  @media (max-width: 1050px) {
    max-width: calc(100vw - 80px);
  }

  @media (max-width: 599px) {
    max-width: calc(100vw - 40px);
    grid-template-columns: 91px minmax(0, 1fr);
  }

  .swiper {
    margin-inline-start: 0;
    width: 100%;
  }
`

export const LeftColumn = styled.div`
  flex-shrink: 0;
  width: 150px;

  @media (max-width: 599px) {
    width: 91px;
  }
`

export const RowTitle = styled.div<{
  height?: number | null
  isLarge?: boolean
}>`
  flex-shrink: 0;
  border-bottom: 1px solid var(--color-gray-300);
  padding-inline-start: 8px;
  min-height: 44px;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  display: grid;
  align-items: center;
  font-size: ${({ isLarge }) =>
    isLarge ? 'var(--body-font-size-medium)' : 'var(--body-font-size-small)'};
  line-height: var(--body-line-height-small);
  font-weight: 500;
  color: var(--color-gray-600);

  @media (max-width: 599px) {
    min-height: 32px;
    font-size: var(--body-font-size-tiny);
    line-height: var(--body-line-height-tiny);
  }

  &:last-child {
    border: none;
  }
`

export const TopInfo = styled.div`
  position: relative;
  border-bottom: 1px solid var(--color-gray-300);
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 599px) {
    padding: 15px 7px;
  }
`

export const StrategyImageWrapper = styled.div`
  position: relative;
  width: 56px;
  height: 56px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`

export const StrategyTitle = styled.div`
  margin-top: 6px;
  margin-bottom: 12px;
  font-weight: 500;
  line-height: 18.4px;
  text-align: center;
  word-break: break-word;

  @media (max-width: 599px) {
    font-size: var(--body-font-size-small);
    line-height: var(--body-line-height-small);
  }
`

export const CopyingButton = styled(Button)`
  margin-top: auto;
  width: 145px;

  @media (max-width: 599px) {
    width: 100%;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 4px;
  width: 20px;
  height: 20px;
  background-image: url(${close});
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: center center;
`

export const RowValue = styled.div<{ color?: string }>`
  border-bottom: 1px solid var(--color-gray-300);
  padding-inline-start: 24px;
  padding-right: 8px;
  height: 44px;
  display: grid;
  align-items: center;
  justify-items: start;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-small);
  font-weight: 500;
  color: ${({ color }) => color};

  @media (max-width: 599px) {
    height: 32px;
    font-size: var(--body-font-size-tiny);
    line-height: var(--body-line-height-tiny);
  }

  &:last-child {
    border: none;
  }
`
