import { useWindowSize } from '@uidotdev/usehooks'
import { useStore } from 'effector-react'
import { $userProfile } from 'features/user'
import { path } from 'libs/path'
import { yup } from 'libs/validators'
import { useTranslation } from 'react-i18next'
import { ZendeskAPI } from 'react-zendesk'
import styled from 'styled-components/macro'
import { Back, Button, Field, Form } from 'ui/atoms'

import { AuthLayout, AuthSectionText, AuthSectionTitle } from '../../../layout'
import { $error, resendCode, submitPasswordRecoveryRepeatForm } from './model'

export const PasswordRecoveryConfirmRepeat = () => {
  const { width } = useWindowSize()
  const isMobile = width && width < 600

  const [t] = useTranslation()

  const { email: userEmail } = useStore($userProfile)
  const error = useStore($error)
  const loading = useStore(resendCode.pending)

  return (
    <AuthLayout type="login">
      <AuthSectionTitle level={isMobile ? 3 : 2}>
        Didn’t receive the Code?
      </AuthSectionTitle>
      <AuthSectionText>
        Check your spam folder and make sure you have entered a valid email
        address. You can also submit a new one.
      </AuthSectionText>
      <Form
        initial={{
          email: userEmail,
        }}
        onSubmit={submitPasswordRecoveryRepeatForm}
        validation={yup.email.verify}
        error={error?.form || ''}
      >
        <EmailConfirmRepeatField
          name="email"
          validate
          autofocus
          error={error?.fields?.email || ''}
        />
        <EmailConfirmRepeatButton
          name="repeat-code"
          size={isMobile ? 'medium' : 'large'}
          design="primary"
          type="submit"
          loading={loading}
        >
          Resend code
        </EmailConfirmRepeatButton>
      </Form>
      <BackButton
        to={path.register.password.recovery.confirm()}
        size={isMobile ? 'medium' : 'large'}
      />
      <HintsContainer>
        <span
          className="cursor-pointer text-bronze.500"
          onClick={() => ZendeskAPI('messenger', 'open')}
        >
          {t('Contact us in LiveChat')}
        </span>
      </HintsContainer>
    </AuthLayout>
  )
}

const EmailConfirmRepeatButton = styled(Button)`
  background-color: var(--color-gray-200);
  width: 100%;
  margin-top: 24px;
`

const EmailConfirmRepeatField = styled(Field)`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
`

const BackButton = styled(Back)`
  width: 100%;
`

const HintsContainer = styled.div`
  margin-top: 24px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  font-weight: 500;
  text-align: center;

  @media (max-width: 600px) {
    padding-top: 11px;
    display: grid;
  }
`
