import { path } from 'libs/path'
import { useTranslation } from 'react-i18next'
import { Text } from 'ui/atoms'

import * as Styled from './styled'

export const Activity = ({ onClose }: { onClose?: () => void }) => {
  return (
    <Styled.Wrapper>
      {onClose ? <Styled.CloseButton onClick={onClose} /> : null}
      <Styled.Link to={path.copytrade.investorEntry()}>
        <Text level={3} weight="medium">
          Earn more in Copytrade
        </Text>
      </Styled.Link>
    </Styled.Wrapper>
  )
}

export const FeatureActivity = ({ onClose }: { onClose?: () => void }) => {
  const [t] = useTranslation()

  const [firstWord, ...rest] = t('NEW: % of profit is available').split(' ')

  return (
    <Styled.Wrapper>
      {onClose ? <Styled.CloseButton onClick={onClose} /> : null}
      <Styled.Link to={path.copytrade.investorEntry()} isFeature>
        <Text level={3} weight="medium" withTranslate={false}>
          {firstWord}
          <br />
          {rest.join(' ')}
        </Text>
      </Styled.Link>
    </Styled.Wrapper>
  )
}
