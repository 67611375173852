import styled from 'styled-components/macro';
import { StatusVariant } from './Status';

import { Text } from '../text';


export const StatusContainer = styled.div<{variant: StatusVariant}>`
  /* rounded-[4px] bg-eucalyptus.100 px-[4px] w-fit */
  border-radius: 4px;
  padding: 0 4px;
  width: fit-content;
  background-color: ${(p) => {
    if (p.variant === 'green') return 'var(--color-eucalyptus-100)';
    if (p.variant === 'red') return 'var(--color-opal-100)';
    if (p.variant === 'blue') return 'var(--color-azure-100)';
    if (p.variant === 'yellow') return 'var(--color-sunray-100)';
    if (p.variant === 'gray') return 'var(--color-gray-300)';
  }};
`;

export const StatusText = styled(Text)<{variant: StatusVariant}>`
  color: ${(p) => {
    if (p.variant === 'green') return 'var(--color-eucalyptus-800)';
    if (p.variant === 'blue') return 'var(--color-azure-800)';
    if (p.variant === 'yellow') return 'var(--color-sunray-800)';
    if (p.variant === 'red') return 'var(--color-opal-700)';
    if (p.variant === 'gray') return 'var(--color-gray-700)';
  }};
`;