import { yupResolver } from '@hookform/resolvers/yup'
import { updateUser } from 'features/user/model'
import { schema } from 'pages/dashboard/profile/utils/changeNameValidator'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePutChangeUserName } from 'services/user/mutations/usePutName'
import { FormInput } from 'ui/molecules'
import { Modal } from 'ui/molecules/modal_draft'

import * as S from './styles'

interface Props {
  firstName: string
  lastName: string
}

interface FormModel {
  firstName: string
  lastName: string
}

export const ChangeNameDialog: React.FC<Props> = ({ lastName, firstName }) => {
  const [t] = useTranslation()

  const { mutate, isLoading, isSuccess } = usePutChangeUserName({
    onSuccessMutation: updateUser,
  })

  const [isModalVisisble, setIsModalVisible] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<FormModel>({
    defaultValues: {
      firstName,
      lastName,
    },
    mode: 'onTouched',
    // без any почему то форма берет типы из резолвера
    // даже явное определение типа форма через дженерик не помогает
    resolver: yupResolver(schema as any),
  })

  const onSbm = (fields: FormModel) => {
    try {
      mutate(fields)
    } catch (error) {}
  }

  useEffect(() => {
    if (isSuccess) {
      setIsModalVisible(false)
    }
  }, [isSuccess])

  return (
    <>
      <Modal
        open={isModalVisisble}
        title="Change name"
        onOk={handleSubmit(onSbm)}
        okButtonProps={{ disabled: isLoading, loading: isLoading }}
        okLabel="Confirm"
        onClose={() => {
          setIsModalVisible(false)

          reset({
            lastName,
            firstName,
          })
        }}
      >
        <S.Content>
          <FormInput
            label={'First name'}
            state={'hint'}
            errorMessage={errors.firstName?.message}
            isTouchedForm={dirtyFields?.firstName}
            maxLength={32}
            {...register('firstName')}
          />
          <FormInput
            label={'Last name'}
            className="mt-[1.6rem]"
            errorMessage={errors.lastName?.message}
            isTouchedForm={dirtyFields?.lastName}
            maxLength={32}
            {...register('lastName')}
          />
          <S.Tip>{t('Limit first and last name to 30 characters')}</S.Tip>
        </S.Content>
      </Modal>

      <S.EditIcon
        size="base"
        name="pencilSolid"
        onClick={() => setIsModalVisible(true)}
        className="hover:opacity-75 transition-opacity"
      />
    </>
  )
}
