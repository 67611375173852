import { useEffect } from 'react'
import { useRemoveStrategy } from 'services/copytrade'
import { Text } from 'ui/atoms'
import { Modal } from 'ui/molecules/modal_draft'

export const UnpublishModal = ({
  isOpen,
  strategyID,
  onSuccess,
  onCancel,
}: {
  isOpen: boolean
  strategyID: string
  onSuccess: () => void
  onCancel: () => void
}) => {
  const { isLoading, mutateAsync, isSuccess } = useRemoveStrategy()

  useEffect(() => {
    if (isSuccess) {
      onSuccess()
    }
  }, [isSuccess])

  return (
    <Modal
      title="Stop publishing?"
      open={isOpen}
      onOk={() => mutateAsync(strategyID)}
      okLabel="Stop"
      onClose={onCancel}
      cancelLabel="Cancel"
      okButtonProps={{
        disabled: isLoading,
        loading: isLoading,
        design: 'dangerous',
      }}
    >
      <Text className="!text-gray.600">
        When you stop publishing a strategy, all statistics are lost.
      </Text>
    </Modal>
  )
}
