import { useStore } from 'effector-react'
import { useIsMobile } from 'libs/hooks/useIsMobile'
import { path } from 'libs/path'
import { Container, Header } from 'pages/dashboard/verification/pages/styled'
import React, { useEffect, useRef } from 'react'
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { Block, List, ModalHint, Title } from 'ui/atoms'

import {
  $verification,
  mounted,
  onSetVerificationEditMod,
  unmounted,
} from '../model'

// Массив исключений, запрещающий редирект при заходе на страницу
const redirectUrlsExclude = [
  path.dashboard.verification.updateDocs(),
  path.dashboard.verification.progress(),
]

export const VerificationLayout = () => {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const location = useLocation()

  useEffect(() => {
    onSetVerificationEditMod(
      !!searchParams.get('edit') ||
        redirectUrlsExclude.includes(location.pathname),
    )
    mounted()
    return () => {
      unmounted()
    }
  }, [])
  const isFirstDataLoad = useRef(true)

  const verification = useStore($verification)

  // Куда редиректим в зависимости от статуса
  // до конца вынести логику из эффектора
  useEffect(() => {
    if (!verification) return
    if (!isFirstDataLoad.current) return

    let startPath = path.dashboard.verification.entry()

    const isEditVerificationMode = searchParams.get('edit')

    // Если флаг модификации, то без редиректов, и так идем по урлу нужному
    // Или путь входить в массив исключений
    if (
      isEditVerificationMode ||
      redirectUrlsExclude.includes(location.pathname)
    ) {
      return
    }

    if (verification.status === 'PAVRS_ACCEPTED') {
      startPath = path.dashboard.verification.verified()
    }

    if (verification.status === 'PAVRS_BANNED') {
      startPath = path.dashboard.verification.entry()
    }

    if (verification.status === 'PAVRS_NEW') {
      startPath = path.dashboard.verification.progress()
    }

    isFirstDataLoad.current = false
    navigate(startPath, {
      replace: true,
    })
  }, [verification])

  return (
    <Container>
      <Block>
        <Header>
          <Title level={isMobile ? 3 : 2}>Verification Request</Title>
          <ModalHint title="With verification, you can use our service to the fullest:">
            <List
              items={[
                'Deposit with bank cards',
                'Withdraw via all payment methods',
                'Benefit from the partnership program',
              ]}
            />
          </ModalHint>
        </Header>
        <Outlet />
      </Block>
    </Container>
  )
}
