import 'tippy.js/dist/tippy.css'

import './styles/fonts.css'
import './styles/normalize.css'
import './styles/default.css'
import './styles/animations.css'
import './styles/typography.css'
import './libs/i18n'

import * as Sentry from '@sentry/react'
import { env } from 'libs/constants'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { App } from './App'

if (!env.isDev) {
  Sentry.init({
    dsn: 'https://8995e56d9e1f5cb4373c0329e0a6dd71@o4508006773227520.ingest.de.sentry.io/4508174969864272',
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.05,
  })
}

const root = createRoot(document.getElementById('root')!)

root.render(<App />)
