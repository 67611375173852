import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'usehooks-ts';

import { Icon2, Icons } from 'ui/atoms/icon2';

import * as ST from 'ui/molecules/tables/styled';
import { useIsMobile } from 'libs/hooks/useIsMobile';
import { BottomSheet } from 'ui/molecules';

export interface Props {
  options: {label: string, value: string}[];
  isActive: boolean;
  value?: string;
  title?: string;
  onChangeSort: (val: string) => void;
  onChangeActive: (val: boolean) => void;
}

export const TableSheetModal: React.FC<Props> = ({ onChangeSort, onChangeActive, value, options, title, isActive }) => {
  const showOptions = true;
  const ref = React.useRef(null);

  const isMobile = useIsMobile();

  const [t] = useTranslation();

  useOnClickOutside(ref, () => onChangeActive(false));
  
  const optionsContent = (
    <div>
      {
        options.map(({ value: optValue, label }, key) => (
        <ST.Option
          key={key}
          onClick={(e: React.MouseEvent) => {
            onChangeActive(false);
            onChangeSort?.(optValue);
            e.stopPropagation();
          }}
          className={`${value === optValue ? '!text-bronze.500' : ''}`}
        >
          {t(label)}
          {value === optValue && <Icon2 name='check' size='base' className='ml-[12px] !text-bronze.500' />}
        </ST.Option>
        ))
      }
    </div>
  )
  

  return (
    <div ref={ref}>
      <BottomSheet
        isOpen={isActive}
        onClose={() => onChangeActive(false)}
        title={title}
      >
        { optionsContent }
      </BottomSheet>
    </div>
  )
}
