import styled, { css } from 'styled-components'
import { Title as UiTitle } from 'ui/atoms'

export const Container = styled.div`
  margin-bottom: 56px;
  padding: 48px 32px;
  display: grid;
  place-items: center;
  text-align: center;
  background-color: var(--color-gray-300);
  border-radius: 8px;

  &:not(:first-child) {
    margin-top: -8px;
    padding-top: 56px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  @media (max-width: 599px) {
    padding: 32px 24px;
    margin-bottom: 40px;

    &:not(:first-child) {
      padding-top: 40px;
    }
  }
`

export const Title = styled(UiTitle)<{ background?: string }>`
  padding-top: 136px;
  margin-bottom: 16px;
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 120px auto;
`

export const Text = styled.div`
  font-size: var(--body-font-size-regular);
  line-height: var(--body-line-height-regular);
`

export const PrizeWrapper = styled.div`
  margin-top: 40px;
  padding: 24px;
  width: 100%;
  display: grid;
  gap: 32px;
  justify-content: center;
  text-align: center;
  background-color: var(--color-gray-100);
  border-radius: 8px;

  @media (max-width: 799px) {
    gap: 24px;
  }
`

export const PrizeTitle = styled.div`
  margin-bottom: 8px;
  font-size: var(--body-font-size-medium);
  line-height: var(--body-line-height-medium);
  color: var(--color-gray-600);
  font-weight: 500;
`

export const PrizeTopRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(160px, 1fr));
  gap: 24px;
  justify-items: center;

  @media (max-width: 799px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 77px);
  }
`

export const PrizeBottomRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 24px;
  justify-content: center;
`

export const PrizeValue = styled(UiTitle)<{ highlighted?: boolean }>`
  line-height: 48px !important;

  ${({ highlighted }) =>
    highlighted &&
    css`
      padding: 0 16px;
      min-width: 90px;
      background-color: var(--color-eucalyptus-300);
      border-radius: 68px;
    `}
`

export const PlaceMedal = styled.img`
  position: absolute;
  bottom: -8px;
  left: 50%;
  width: 64px;
  height: auto;
  transform: translateX(-50%);
`
