import { createEvent, createStore } from 'effector';
import { number } from 'yup';

export type Statuses = 'OS_PENDING' | 'OS_MISSING_INFORMATION' | 'OS_REJECTED' | 'OS_SUCCESS' | 'all';
export type TransactionType = 'all' | 'deposit' | 'withdrawal' | 'internal_transfer';

export type MoneyHistoryFilterStore = {
  statuses?: Statuses;
  transactionType: TransactionType;
  timeline: {
    from: string,
    to: string,
  },
  exchangerWalletIDs?: string[];
  accountIDs?: string[],
  walletIDs?: string[],
  isDefaultFilter: boolean;
}

export type MoneyHistoryPagination = {
  page: number;
  perPage: number;
}

const default_store_filter: MoneyHistoryFilterStore = {
  statuses: 'all',
  transactionType: 'all',
  exchangerWalletIDs: undefined,
  timeline: {
    from: '0',
    to: new Date().getTime().toString(),
  },
  isDefaultFilter: true,
}

const default_store_pagination: MoneyHistoryPagination = {
  page: 1,
  perPage: 20,
}

export const resetMoneyHistoryEvent = createEvent();
export const dropFilterMoneyHistoryEvent = createEvent();

export const $moneyHistoryFilter = createStore(default_store_filter).reset([resetMoneyHistoryEvent, dropFilterMoneyHistoryEvent]);
export const $moneyHistoryPagination = createStore(default_store_pagination).reset(resetMoneyHistoryEvent);

// Пагинация
export const setPageEvent = createEvent<number>();
$moneyHistoryPagination.on(setPageEvent, (state, page) => ({...state, page}));

export const setPerPageEvent = createEvent<number>();
$moneyHistoryPagination.on(setPerPageEvent, (state, perPage) => ({...state, perPage}));
// Пагинация

// Фильтрация по статусу
export const setStatusesEvent = createEvent<Statuses | 'all'>();
$moneyHistoryFilter.on(setStatusesEvent, (state, status) => ({ ...state, statuses: status, isDefaultFilter: false }));

// Фильтрация по exchanger кошелькам
export const setExchangerWalletsEvent = createEvent<string[] | undefined>();
$moneyHistoryFilter.on(setExchangerWalletsEvent, (state, exchangerWalletIDs) => ({
  ...state,
  exchangerWalletIDs
}));

// Фильтрация по времени
export const setTimelineEvent = createEvent<{ to: string; from: string}>();
$moneyHistoryFilter.on(setTimelineEvent, (state, timeline) => ({ ...state, timeline, isDefaultFilter: false }));

// Фильтрация по типу транзакции
export const setTransactionsTypeEvent = createEvent<TransactionType>();
$moneyHistoryFilter.on(setTransactionsTypeEvent, (state, transactionType) => ({...state, transactionType, isDefaultFilter: false }));

// Фильтрация по кошелькам/аккаунтам
export const setWalletIdsEvent = createEvent<string[]>();
$moneyHistoryFilter.on(setWalletIdsEvent, (state, walletIDs) => ({
  ...state, walletIDs, isDefaultFilter: !walletIDs?.length 
}));

export const setAccountIdsEvent = createEvent<string[]>();
$moneyHistoryFilter.on(setAccountIdsEvent, (state, accountIDs) => ({...state, accountIDs, isDefaultFilter: !accountIDs?.length  }));
